.current-assignment {
    border-radius: 2px;

    &.bg-title {
        --tw-bg-opacity: 0.05;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    &:hover {
        --tw-bg-opacity: 0.05;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));

    }

    .assignment-content {
        border-top: unset;
    }
}

@media screen and (max-width: 500px) {
    .current-assignment {
        .infor-side {
            display: none;
        }
    }
}