.banner {
    min-height: 200px;
    .banner-context {
        z-index: 1;
    }

    .hover\:opacity-1:hover {
        opacity: 1;
    }

    @media screen and (max-width: 640px) {
        height: auto;
        min-height: 130px;
        padding-top: 15px;
        padding-bottom: 15px;
        text-overflow: ellipsis;

        .banner-image {
            width: 40px;
            height: 40px;
        }

        .banner-context {
            padding-left: 15px;
            font-size: 12px;

            .title {
                font-size: 20px;
            }

            .context {
                display: block;
            }
        }

        .avatar {
            width: 90px;
            height: 90px;
        }
    }
}