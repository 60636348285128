.modal-add-user {
    >div {
        height: unset !important;
    }

    input {
        height: 48px;
    }

    .modal-body {
        overflow: unset !important;
    }

    .date-style {
        >div>div {
            >div {
                right: 10px !important;
                left: unset !important;

                svg {
                    color: rgb(132, 116, 106);
                }
            }

            input {
                padding-left: 0.625rem;
                color: rgb(48, 20, 0);
                outline: none
            }
        }
    }

    .placeholder-date {
        >div>div {
            >div {
                right: 10px !important;
                left: unset !important;

                svg {
                    color: rgb(132, 116, 106);
                }
            }

            input {
                padding-left: 0.625rem;
                color: #6b7280 !important;
            }

            input:after {
                content: 'Enter your number';
                position: absolute;
                left: 5px;
                top: 0;
                color: #bbb;
            }
        }
    }
}

@media screen and (max-height: 550px) {
    .modal-add-user {
        >div {
            overflow: auto;
            height: 100% !important;
        }

        ::-webkit-scrollbar {
            display: none;
        }
    }
}