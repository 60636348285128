@import '../../../assets/scss/variable';

.patient-assignments {
    .tab-button {
        .tab-button {

            padding-left: 25px !important;

            button {
                box-shadow: unset !important;
                color: $primary_text;
            }

            button[aria-selected="true"] {
                border-color: $second_text;
                color: $second_text;
            }
        }

        >div:nth-child(2) {
            height: 100%;

            >div {
                height: 100%;

                &:focus-visible {
                    box-shadow: unset;
                }
            }

            >div:nth-child(3) {
                height: 100%;
            }
        }
    }

    td {
        >div {

            >button {
                box-shadow: unset;
                border-radius: 2px;
                padding: 1rem;

                &:focus {
                    box-shadow: unset;
                    border-radius: 2px;
                    --tw-bg-opacity: 0.05;
                    background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
                }

                &:hover {
                    border-radius: 2px;
                    --tw-bg-opacity: 0.05;
                    background-color: rgb(132, 116, 106, var(--tw-bg-opacity));

                }

                // --tw-bg-opacity: 0.05;
                // background-color: unset;


                >h2 {
                    flex-grow: 1;
                    margin-right: 15px;
                }

                &.bg-gray-100 {
                    border-radius: 2px;
                    --tw-bg-opacity: 0.05;
                    background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
                }
            }
        }
    }

}