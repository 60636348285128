@import '~flowbite';
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  height: 100%;
  background: #F9FAFB;
}
.overflow-y {
  overflow-y: scroll;
}
.wrapper-content {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.p-50{
  padding: 50px;
}
.mt-50{
  margin-top: 50px;
}
.mb-50{
  margin-bottom: 50px;
}
.mb-20{
  margin-bottom: 20px;
}
.h-full-important{
  height: 100% !important;
}

.h-34 {
  height: 34px
}
.h-70 {
  height: 70px;
}

input {
  border-radius: 2px !important;
  /* border-color: rgba(132, 116, 106, 0.2) !important; */
}
.h-32 {
  height: 8rem;
}
.pt-14 {
  padding-top: 3.5rem;
}

.hover\:block:hover {
  display: block;
}
.w-40{
  width: 10rem;
}
.gap-x-32 {
  column-gap: 8rem;
}
.h-30 {
  height: 30px;
}

.w-30 {
  width: 30px;
}
.min-w-30 {
  min-width: 30px;
}

.min-w-9 {
  min-width: 2.25rem;
}

.min-w-3 {
    min-width: 0.75rem;
}
.min-w-15 {
  min-width: 3.75rem;
}
@media (min-width: 1024px) {
  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
.w-15 {
  width: 3.75rem;
}
.h-15 {
  height: 3.75rem;
}

.list-table {
  height: calc(100vh - 190px);
}

@media screen and (max-width: 640px) {
  .list-table {
      height: calc(100vh - 270px);
  }
}