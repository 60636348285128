.onboarding {
    color: rgba(10, 10, 10, 1);
    .body {
        width: 100%;
        max-width: 800px;
        border-radius: 20px;
        overflow: hidden;
    }
    .banner {
        padding: 30px;
        min-height: 220px;
        background: rgba(238, 238, 238, 1);
    }
    .body-left{
        width: 100%;
        max-width: 590px;
        padding-right: 30px;
    }
}