 .organization-clinicians-container {
     .clinician-id {
         max-width: 9rem;
     }

 }

 @media screen and (max-width: 640px) {
     .organization-clinicians-container {
         .clinician-id {
             width: 5rem;
         }
     }
 }

 @media screen and (max-width: 769px) {
     .organization-clinicians-container {
         .hidden-patients-mobile {
             display: none;
         }
     }
 }

 @media screen and (max-width:550px) {
     .organization-clinicians-container {
         .external-id {
             max-width: 100px;
             padding-right: 5px;
             padding-left: 10px;
         }

         .full-name {
             .name-badge {
                 flex-direction: column;
                 align-items: unset;
             }
         }
     }
 }

 @media screen and (max-width: 450px) {
     .organization-clinicians-container {
         .external-id {
             max-width: 100px;
             padding-right: 5px;
             padding-left: 5px;
         }

         .clinician-externalId {
             width: 100px;

             .patient-id {
                 width: 80px;
             }
         }

         .full-name {
             max-width: 170px;
             padding-left: 10px;
             padding-right: 5px;

             .name-badge {
                 flex-direction: column;
                 align-items: unset;
             }
         }
     }
 }