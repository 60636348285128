@media screen and (max-width: 1500px) {
    .recording {
        .empty-div {
            margin-left: auto;
        }

        .prompt-image {
            margin-right: auto;
        }
    }
}

@media screen and (max-width: 800px) {
    .recording {
        flex-direction: column;

        .prompt-image {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
}