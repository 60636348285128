.datepicker {
  input {
    height: 48px;
    padding-left: 0.625rem;
    color: #301400 !important;
    background-color: white;
    border-color: rgba(132, 116, 106, 0.2);
  }

  .pointer-events-none {
    right: 10px;
    left: unset;

    svg {
      color: rgba(132, 116, 106, 1);
    }
  }

  .holder {
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    color: #84746A;
    left: 5px;
    top: 24px;
    transform: translateY(-50%);
    transition: all 0.25s ease;
    pointer-events: none;
    background: none;
    padding: 0 5px;
    z-index: 1;
  }

  .label {
    background: white;
    border-radius: 6px;
    left: 10px;
    top: 0;
    font-size: 12px;
  }
  span, button {
    color: #2B1700;
  }
}