.user-management {
    tr {
        &:hover {
            .action-three-dots {
                background-color: #E1EFFE !important;
            }
        }

        .copy-icon-name {
            display: none;
            padding-left: 0px;
        }

        .copy-icon-email {
            visibility: hidden;
        }

        .hover-name {
            &:hover {
                .copy-icon-name {
                    display: block;
                    padding-left: 0px;
                }
            }
        }

        .hover-email {
            padding-top: 0px;

            &:hover {
                .copy-icon-email {
                    visibility: visible;
                    padding-left: 0px;
                }
            }
        }
    }


}