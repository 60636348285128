   .organization-container {

       .icon-sort {
           visibility: hidden;
       }

       th {
           &:hover {
               .icon-sort {
                   visibility: visible;
               }
           }
       }

       .organization-name {
           max-width: 65%;

           >div {
               width: unset;
           }

       }
   }

   @media screen and (max-width: 640px) {
       .organization-container {
           overflow: auto;

           .organization-name {

               >div {
                   width: unset;
               }

           }
       }
   }

   @media screen and (max-width: 550px) {
       .organization-container {
           overflow: auto;

           .organization-name {
               max-width: 180px;

               >div {
                   width: unset;
               }

           }
       }
   }