//primary
.fill-primary,
.hover\:fill-primary:hover,
.focus\:fill-primary:focus,
.checked\:fill-primary:checked {
    --tw-text-opacity: 1;
    fill: rgb(148, 74, 1, var(--tw-text-opacity));
}

.text-primary,
.hover\:text-primary:hover,
.focus\:text-primary:focus,
.checked\:text-primary:checked {
    --tw-text-opacity: 1;
    color: rgb(148, 74, 1, var(--tw-text-opacity));
}

.\!text-primary,
.\!hover\:text-primary:hover,
.\!focus\:text-primary:focus,
.\!checked\:text-primary:checked {
    --tw-text-opacity: 1;
    color: rgb(148, 74, 1, var(--tw-text-opacity)) !important;
}

.bg-primary,
.hover\:bg-primary:hover,
.focus\:bg-primary:focus,
.checked\:bg-primary:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(148, 74, 1, var(--tw-bg-opacity));
}

.\!bg-primary,
.\!hover\:bg-primary:hover,
.\!focus\:bg-primary:focus,
.\!checked\:bg-primary:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(148, 74, 1, var(--tw-bg-opacity)) !important;
}

.border-primary,
.hover\:border-primary:hover,
.focus\:border-primary:focus,
.checked\:border-primary:checked {
    --tw-border-opacity: 1;
    border-color: rgb(148, 74, 1, var(--tw-border-opacity));
}

.\!border-primary,
.\!hover\:border-primary:hover,
.\!focus\:border-primary:focus,
.\!checked\:border-primary:checked {
    --tw-border-opacity: 1;
    border-color: rgb(148, 74, 1, var(--tw-border-opacity)) !important;
}

.bg-active {
    background-color: rgba(0, 103, 131, 1);
}

.bg-hover,
.\hover\:bg-hover:hover,
.\focus\:bg-hover:focus {
    background-color: rgba(132, 116, 106, 0.05)
}

.bg-text-id {
    background-color: rgba(132, 116, 106, 0.2);
}

.bg-text-id-outline {
    background-color: rgba(132, 116, 106, 0.1);
}

.border-outline {
    --tw-border-opacity: 1;
    border-color: rgba(132, 116, 106, 1);
}

.completed-status {
    background-color: rgba(14, 159, 110, 1);
}

.completed-status-text {
    color: rgba(14, 159, 110, 1);
}

.ready-for-review-status {
    background-color: rgba(148, 74, 1, 1);
}

.ready-for-review-status-text {
    color: rgba(148, 74, 1, 1);
}

.not-started-status {
    background-color: rgba(132, 116, 106, 1)
}

.not-started-status-text {
    color: rgba(132, 116, 106, 1)
}

.bg-blue {
    background-color: rgba(188, 233, 255, 0.2);
}

.link-color {
    color: rgba(0, 103, 131, 1)
}

.border-circle-primary {
    border-color: rgba(48, 20, 0, 1)
}

.bg-badge-secondary {
    background-color: rgba(255, 221, 185, 1);
}

.bg-recording {
    background-color: rgba(186, 26, 26, 1);
}

.text-recording {
    color: rgba(186, 26, 26, 1);
}

.time-color {
    color: rgba(255, 183, 133, 1)
}

.border-color-recording {
    border-color: rgba(186, 26, 26, 1);
}

.ring-primary,
.hover\:ring-primary:hover,
.focus\:ring-primary:focus,
.checked\:ring-primary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(148, 74, 1, var(--tw-ring-opacity));
}

.\!ring-primary,
.\!hover\:ring-primary:hover,
.\!focus\:ring-primary:focus,
.\!checked\:ring-primary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(148, 74, 1, var(--tw-ring-opacity)) !important;
}

.text-primary-name {
    color: rgba(43, 23, 0, 1)
}

@media (min-width: 640px) {

    .sm\:text-primary,
    .sm\:hover\:text-primary:hover,
    .sm\:focus\:text-primary:focus,
    .sm\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(148, 74, 1, var(--tw-text-opacity));
    }

    .\!sm\:text-primary,
    .\!sm\:hover\:text-primary:hover,
    .\!sm\:focus\:text-primary:focus,
    .\!sm\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(148, 74, 1, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-primary,
    .sm\:hover\:bg-primary:hover,
    .sm\:focus\:bg-primary:focus,
    .sm\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(148, 74, 1, var(--tw-bg-opacity));
    }

    .\!sm\:bg-primary,
    .\!sm\:hover\:bg-primary:hover,
    .\!sm\:focus\:bg-primary:focus,
    .\!sm\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(148, 74, 1, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-primary,
    .sm\:hover\:border-primary:hover,
    .sm\:focus\:border-primary:focus,
    .sm\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(148, 74, 1, var(--tw-border-opacity));
    }

    .\!sm\:border-primary,
    .\!sm\:hover\:border-primary:hover,
    .\!sm\:focus\:border-primary:focus,
    .\!sm\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(148, 74, 1, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-primary,
    .sm\:hover\:ring-primary:hover,
    .sm\:focus\:ring-primary:focus,
    .sm\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(148, 74, 1, var(--tw-ring-opacity));
    }

    .\!sm\:ring-primary,
    .\!sm\:hover\:ring-primary:hover,
    .\!sm\:focus\:ring-primary:focus,
    .\!sm\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(148, 74, 1, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-primary,
    .md\:hover\:text-primary:hover,
    .md\:focus\:text-primary:focus,
    .md\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(148, 74, 1, var(--tw-text-opacity));
    }

    .\!md\:text-primary,
    .\!md\:hover\:text-primary:hover,
    .\!md\:focus\:text-primary:focus,
    .\!md\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(148, 74, 1, var(--tw-text-opacity)) !important;
    }

    .md\:bg-primary,
    .md\:hover\:bg-primary:hover,
    .md\:focus\:bg-primary:focus,
    .md\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(148, 74, 1, var(--tw-bg-opacity));
    }

    .\!md\:bg-primary,
    .\!md\:hover\:bg-primary:hover,
    .\!md\:focus\:bg-primary:focus,
    .\!md\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(148, 74, 1, var(--tw-bg-opacity)) !important;
    }

    .md\:border-primary,
    .md\:hover\:border-primary:hover,
    .md\:focus\:border-primary:focus,
    .md\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(148, 74, 1, var(--tw-border-opacity));
    }

    .\!md\:border-primary,
    .\!md\:hover\:border-primary:hover,
    .\!md\:focus\:border-primary:focus,
    .\!md\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(148, 74, 1, var(--tw-border-opacity)) !important;
    }

    .md\:ring-primary,
    .md\:hover\:ring-primary:hover,
    .md\:focus\:ring-primary:focus,
    .md\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(148, 74, 1, var(--tw-ring-opacity));
    }

    .\!md\:ring-primary,
    .\!md\:hover\:ring-primary:hover,
    .\!md\:focus\:ring-primary:focus,
    .\!md\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(148, 74, 1, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-primary,
    .lg\:hover\:text-primary:hover,
    .lg\:focus\:text-primary:focus,
    .lg\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(148, 74, 1, var(--tw-text-opacity));
    }

    .\!lg\:text-primary,
    .\!lg\:hover\:text-primary:hover,
    .\!lg\:focus\:text-primary:focus,
    .\!lg\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(148, 74, 1, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-primary,
    .lg\:hover\:bg-primary:hover,
    .lg\:focus\:bg-primary:focus,
    .lg\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(148, 74, 1, var(--tw-bg-opacity));
    }

    .\!lg\:bg-primary,
    .\!lg\:hover\:bg-primary:hover,
    .\!lg\:focus\:bg-primary:focus,
    .\!lg\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(148, 74, 1, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-primary,
    .lg\:hover\:border-primary:hover,
    .lg\:focus\:border-primary:focus,
    .lg\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(148, 74, 1, var(--tw-border-opacity));
    }

    .\!lg\:border-primary,
    .\!lg\:hover\:border-primary:hover,
    .\!lg\:focus\:border-primary:focus,
    .\!lg\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(148, 74, 1, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-primary,
    .lg\:hover\:ring-primary:hover,
    .lg\:focus\:ring-primary:focus,
    .lg\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(148, 74, 1, var(--tw-ring-opacity));
    }

    .\!lg\:ring-primary,
    .\!lg\:hover\:ring-primary:hover,
    .\!lg\:focus\:ring-primary:focus,
    .\!lg\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(148, 74, 1, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-primary,
    .xl\:hover\:text-primary:hover,
    .xl\:focus\:text-primary:focus,
    .xl\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(148, 74, 1, var(--tw-text-opacity));
    }

    .\!xl\:text-primary,
    .\!xl\:hover\:text-primary:hover,
    .\!xl\:focus\:text-primary:focus,
    .\!xl\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(148, 74, 1, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-primary,
    .xl\:hover\:bg-primary:hover,
    .xl\:focus\:bg-primary:focus,
    .xl\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(148, 74, 1, var(--tw-bg-opacity));
    }

    .\!xl\:bg-primary,
    .\!xl\:hover\:bg-primary:hover,
    .\!xl\:focus\:bg-primary:focus,
    .\!xl\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(148, 74, 1, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-primary,
    .xl\:hover\:border-primary:hover,
    .xl\:focus\:border-primary:focus,
    .xl\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(148, 74, 1, var(--tw-border-opacity));
    }

    .\!xl\:border-primary,
    .\!xl\:hover\:border-primary:hover,
    .\!xl\:focus\:border-primary:focus,
    .\!xl\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(148, 74, 1, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-primary,
    .xl\:hover\:ring-primary:hover,
    .xl\:focus\:ring-primary:focus,
    .xl\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(148, 74, 1, var(--tw-ring-opacity));
    }

    .\!xl\:ring-primary,
    .\!xl\:hover\:ring-primary:hover,
    .\!xl\:focus\:ring-primary:focus,
    .\!xl\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(148, 74, 1, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-primary,
    .\2xl\:hover\:text-primary:hover,
    .\2xl\:focus\:text-primary:focus,
    .\2xl\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(148, 74, 1, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-primary,
    .\!2xlsm\:hover\:text-primary:hover,
    .\!2xlsm\:focus\:text-primary:focus,
    .\!2xlsm\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(148, 74, 1, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-primary,
    .\2xl\:hover\:bg-primary:hover,
    .\2xl\:focus\:bg-primary:focus,
    .\2xl\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(148, 74, 1, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-primary,
    .\!2xlsm\:hover\:bg-primary:hover,
    .\!2xlsm\:focus\:bg-primary:focus,
    .\!2xlsm\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(148, 74, 1, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-primary,
    .\2xl\:hover\:border-primary:hover,
    .\2xl\:focus\:border-primary:focus,
    .\2xl\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(148, 74, 1, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-primary,
    .\!2xlsm\:hover\:border-primary:hover,
    .\!2xlsm\:focus\:border-primary:focus,
    .\!2xlsm\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(148, 74, 1, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-primary,
    .\2xl\:hover\:ring-primary:hover,
    .\2xl\:focus\:ring-primary:focus,
    .\2xl\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(148, 74, 1, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-primary,
    .\!2xl\:hover\:ring-primary:hover,
    .\!2xl\:focus\:ring-primary:focus,
    .\!2xl\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(148, 74, 1, var(--tw-ring-opacity)) !important;
    }
}

//on-primary-container
.text-on-primary-container,
.hover\:text-on-primary-container:hover,
.focus\:text-on-primary-container:focus,
.checked\:text-on-primary-container:checked {
    --tw-text-opacity: 1;
    color: rgb(48, 20, 0, var(--tw-text-opacity));
}

.\!text-on-primary-container,
.\!hover\:text-on-primary-container:hover,
.\!focus\:text-on-primary-container:focus,
.\!checked\:text-on-primary-container:checked {
    --tw-text-opacity: 1;
    color: rgb(48, 20, 0, var(--tw-text-opacity)) !important;
}

.bg-on-primary-container,
.hover\:bg-on-primary-container:hover,
.focus\:bg-on-primary-container:focus,
.checked\:bg-on-primary-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(48, 20, 0, var(--tw-bg-opacity));
}

.\!bg-on-primary-container,
.\!hover\:bg-on-primary-container:hover,
.\!focus\:bg-on-primary-container:focus,
.\!checked\:bg-on-primary-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(48, 20, 0, var(--tw-bg-opacity)) !important;
}

.border-on-primary-container,
.hover\:border-on-primary-container:hover,
.focus\:border-on-primary-container:focus,
.checked\:border-on-primary-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(48, 20, 0, var(--tw-border-opacity));
}

.\!border-on-primary-container,
.\!hover\:border-on-primary-container:hover,
.\!focus\:border-on-primary-container:focus,
.\!checked\:border-on-primary-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(48, 20, 0, var(--tw-border-opacity)) !important;
}

.ring-on-primary-container,
.hover\:ring-on-primary-container:hover,
.focus\:ring-on-primary-container:focus,
.checked\:ring-on-primary-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(48, 20, 0, var(--tw-ring-opacity));
}

.\!ring-on-primary-container,
.\!hover\:ring-on-primary-container:hover,
.\!focus\:ring-on-primary-container:focus,
.\!checked\:ring-on-primary-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(48, 20, 0, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-on-primary-container,
    .sm\:hover\:text-on-primary-container:hover,
    .sm\:focus\:text-on-primary-container:focus,
    .sm\:checked\:text-on-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(48, 20, 0, var(--tw-text-opacity));
    }

    .\!sm\:text-on-primary-container,
    .\!sm\:hover\:text-on-primary-container:hover,
    .\!sm\:focus\:text-on-primary-container:focus,
    .\!sm\:checked\:text-on-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(48, 20, 0, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-on-primary-container,
    .sm\:hover\:bg-on-primary-container:hover,
    .sm\:focus\:bg-on-primary-container:focus,
    .sm\:checked\:bg-on-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(48, 20, 0, var(--tw-bg-opacity));
    }

    .\!sm\:bg-on-primary-container,
    .\!sm\:hover\:bg-on-primary-container:hover,
    .\!sm\:focus\:bg-on-primary-container:focus,
    .\!sm\:checked\:bg-on-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(48, 20, 0, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-on-primary-container,
    .sm\:hover\:border-on-primary-container:hover,
    .sm\:focus\:border-on-primary-container:focus,
    .sm\:checked\:border-on-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(48, 20, 0, var(--tw-border-opacity));
    }

    .\!sm\:border-on-primary-container,
    .\!sm\:hover\:border-on-primary-container:hover,
    .\!sm\:focus\:border-on-primary-container:focus,
    .\!sm\:checked\:border-on-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(48, 20, 0, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-on-primary-container,
    .sm\:hover\:ring-on-primary-container:hover,
    .sm\:focus\:ring-on-primary-container:focus,
    .sm\:checked\:ring-on-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(48, 20, 0, var(--tw-ring-opacity));
    }

    .\!sm\:ring-on-primary-container,
    .\!sm\:hover\:ring-on-primary-container:hover,
    .\!sm\:focus\:ring-on-primary-container:focus,
    .\!sm\:checked\:ring-on-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(48, 20, 0, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-on-primary-container,
    .md\:hover\:text-on-primary-container:hover,
    .md\:focus\:text-on-primary-container:focus,
    .md\:checked\:text-on-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(48, 20, 0, var(--tw-text-opacity));
    }

    .\!md\:text-on-primary-container,
    .\!md\:hover\:text-on-primary-container:hover,
    .\!md\:focus\:text-on-primary-container:focus,
    .\!md\:checked\:text-on-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(48, 20, 0, var(--tw-text-opacity)) !important;
    }

    .md\:bg-on-primary-container,
    .md\:hover\:bg-on-primary-container:hover,
    .md\:focus\:bg-on-primary-container:focus,
    .md\:checked\:bg-on-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(48, 20, 0, var(--tw-bg-opacity));
    }

    .\!md\:bg-on-primary-container,
    .\!md\:hover\:bg-on-primary-container:hover,
    .\!md\:focus\:bg-on-primary-container:focus,
    .\!md\:checked\:bg-on-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(48, 20, 0, var(--tw-bg-opacity)) !important;
    }

    .md\:border-on-primary-container,
    .md\:hover\:border-on-primary-container:hover,
    .md\:focus\:border-on-primary-container:focus,
    .md\:checked\:border-on-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(48, 20, 0, var(--tw-border-opacity));
    }

    .\!md\:border-on-primary-container,
    .\!md\:hover\:border-on-primary-container:hover,
    .\!md\:focus\:border-on-primary-container:focus,
    .\!md\:checked\:border-on-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(48, 20, 0, var(--tw-border-opacity)) !important;
    }

    .md\:ring-on-primary-container,
    .md\:hover\:ring-on-primary-container:hover,
    .md\:focus\:ring-on-primary-container:focus,
    .md\:checked\:ring-on-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(48, 20, 0, var(--tw-ring-opacity));
    }

    .\!md\:ring-on-primary-container,
    .\!md\:hover\:ring-on-primary-container:hover,
    .\!md\:focus\:ring-on-primary-container:focus,
    .\!md\:checked\:ring-on-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(48, 20, 0, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-on-primary-container,
    .lg\:hover\:text-on-primary-container:hover,
    .lg\:focus\:text-on-primary-container:focus,
    .lg\:checked\:text-on-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(48, 20, 0, var(--tw-text-opacity));
    }

    .\!lg\:text-on-primary-container,
    .\!lg\:hover\:text-on-primary-container:hover,
    .\!lg\:focus\:text-on-primary-container:focus,
    .\!lg\:checked\:text-on-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(48, 20, 0, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-on-primary-container,
    .lg\:hover\:bg-on-primary-container:hover,
    .lg\:focus\:bg-on-primary-container:focus,
    .lg\:checked\:bg-on-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(48, 20, 0, var(--tw-bg-opacity));
    }

    .\!lg\:bg-on-primary-container,
    .\!lg\:hover\:bg-on-primary-container:hover,
    .\!lg\:focus\:bg-on-primary-container:focus,
    .\!lg\:checked\:bg-on-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(48, 20, 0, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-on-primary-container,
    .lg\:hover\:border-on-primary-container:hover,
    .lg\:focus\:border-on-primary-container:focus,
    .lg\:checked\:border-on-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(48, 20, 0, var(--tw-border-opacity));
    }

    .\!lg\:border-on-primary-container,
    .\!lg\:hover\:border-on-primary-container:hover,
    .\!lg\:focus\:border-on-primary-container:focus,
    .\!lg\:checked\:border-on-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(48, 20, 0, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-on-primary-container,
    .lg\:hover\:ring-on-primary-container:hover,
    .lg\:focus\:ring-on-primary-container:focus,
    .lg\:checked\:ring-on-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(48, 20, 0, var(--tw-ring-opacity));
    }

    .\!lg\:ring-on-primary-container,
    .\!lg\:hover\:ring-on-primary-container:hover,
    .\!lg\:focus\:ring-on-primary-container:focus,
    .\!lg\:checked\:ring-on-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(48, 20, 0, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-on-primary-container,
    .xl\:hover\:text-on-primary-container:hover,
    .xl\:focus\:text-on-primary-container:focus,
    .xl\:checked\:text-on-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(48, 20, 0, var(--tw-text-opacity));
    }

    .\!xl\:text-on-primary-container,
    .\!xl\:hover\:text-on-primary-container:hover,
    .\!xl\:focus\:text-on-primary-container:focus,
    .\!xl\:checked\:text-on-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(48, 20, 0, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-on-primary-container,
    .xl\:hover\:bg-on-primary-container:hover,
    .xl\:focus\:bg-on-primary-container:focus,
    .xl\:checked\:bg-on-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(48, 20, 0, var(--tw-bg-opacity));
    }

    .\!xl\:bg-on-primary-container,
    .\!xl\:hover\:bg-on-primary-container:hover,
    .\!xl\:focus\:bg-on-primary-container:focus,
    .\!xl\:checked\:bg-on-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(48, 20, 0, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-on-primary-container,
    .xl\:hover\:border-on-primary-container:hover,
    .xl\:focus\:border-on-primary-container:focus,
    .xl\:checked\:border-on-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(48, 20, 0, var(--tw-border-opacity));
    }

    .\!xl\:border-on-primary-container,
    .\!xl\:hover\:border-on-primary-container:hover,
    .\!xl\:focus\:border-on-primary-container:focus,
    .\!xl\:checked\:border-on-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(48, 20, 0, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-on-primary-container,
    .xl\:hover\:ring-on-primary-container:hover,
    .xl\:focus\:ring-on-primary-container:focus,
    .xl\:checked\:ring-on-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(48, 20, 0, var(--tw-ring-opacity));
    }

    .\!xl\:ring-on-primary-container,
    .\!xl\:hover\:ring-on-primary-container:hover,
    .\!xl\:focus\:ring-on-primary-container:focus,
    .\!xl\:checked\:ring-on-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(48, 20, 0, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-on-primary-container,
    .\2xl\:hover\:text-on-primary-container:hover,
    .\2xl\:focus\:text-on-primary-container:focus,
    .\2xl\:checked\:text-on-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(48, 20, 0, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-on-primary-container,
    .\!2xlsm\:hover\:text-on-primary-container:hover,
    .\!2xlsm\:focus\:text-on-primary-container:focus,
    .\!2xlsm\:checked\:text-on-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(48, 20, 0, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-on-primary-container,
    .\2xl\:hover\:bg-on-primary-container:hover,
    .\2xl\:focus\:bg-on-primary-container:focus,
    .\2xl\:checked\:bg-on-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(48, 20, 0, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-on-primary-container,
    .\!2xlsm\:hover\:bg-on-primary-container:hover,
    .\!2xlsm\:focus\:bg-on-primary-container:focus,
    .\!2xlsm\:checked\:bg-on-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(48, 20, 0, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-on-primary-container,
    .\2xl\:hover\:border-on-primary-container:hover,
    .\2xl\:focus\:border-on-primary-container:focus,
    .\2xl\:checked\:border-on-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(48, 20, 0, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-on-primary-container,
    .\!2xlsm\:hover\:border-on-primary-container:hover,
    .\!2xlsm\:focus\:border-on-primary-container:focus,
    .\!2xlsm\:checked\:border-on-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(48, 20, 0, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-on-primary-container,
    .\2xl\:hover\:ring-on-primary-container:hover,
    .\2xl\:focus\:ring-on-primary-container:focus,
    .\2xl\:checked\:ring-on-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(48, 20, 0, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-on-primary-container,
    .\!2xl\:hover\:ring-on-primary-container:hover,
    .\!2xl\:focus\:ring-on-primary-container:focus,
    .\!2xl\:checked\:ring-on-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(48, 20, 0, var(--tw-ring-opacity)) !important;
    }
}

//secondary
.text-secondary,
.hover\:text-secondary:hover,
.focus\:text-secondary:focus,
.checked\:text-secondary:checked {
    --tw-text-opacity: 1;
    color: rgb(134, 83, 0, var(--tw-text-opacity));
}

.\!text-secondary,
.\!hover\:text-secondary:hover,
.\!focus\:text-secondary:focus,
.\!checked\:text-secondary:checked {
    --tw-text-opacity: 1;
    color: rgb(134, 83, 0, var(--tw-text-opacity)) !important;
}

.bg-secondary,
.hover\:bg-secondary:hover,
.focus\:bg-secondary:focus,
.checked\:bg-secondary:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(134, 83, 0, var(--tw-bg-opacity));
}

.\!bg-secondary,
.\!hover\:bg-secondary:hover,
.\!focus\:bg-secondary:focus,
.\!checked\:bg-secondary:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(134, 83, 0, var(--tw-bg-opacity)) !important;
}

.border-secondary,
.hover\:border-secondary:hover,
.focus\:border-secondary:focus,
.checked\:border-secondary:checked {
    --tw-border-opacity: 1;
    border-color: rgb(134, 83, 0, var(--tw-border-opacity));
}

.\!border-secondary,
.\!hover\:border-secondary:hover,
.\!focus\:border-secondary:focus,
.\!checked\:border-secondary:checked {
    --tw-border-opacity: 1;
    border-color: rgb(134, 83, 0, var(--tw-border-opacity)) !important;
}

.ring-secondary,
.hover\:ring-secondary:hover,
.focus\:ring-secondary:focus,
.checked\:ring-secondary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134, 83, 0, var(--tw-ring-opacity));
}

.\!ring-secondary,
.\!hover\:ring-secondary:hover,
.\!focus\:ring-secondary:focus,
.\!checked\:ring-secondary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134, 83, 0, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-secondary,
    .sm\:hover\:text-secondary:hover,
    .sm\:focus\:text-secondary:focus,
    .sm\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgb(134, 83, 0, var(--tw-text-opacity));
    }

    .\!sm\:text-secondary,
    .\!sm\:hover\:text-secondary:hover,
    .\!sm\:focus\:text-secondary:focus,
    .\!sm\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgb(134, 83, 0, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-secondary,
    .sm\:hover\:bg-secondary:hover,
    .sm\:focus\:bg-secondary:focus,
    .sm\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(134, 83, 0, var(--tw-bg-opacity));
    }

    .\!sm\:bg-secondary,
    .\!sm\:hover\:bg-secondary:hover,
    .\!sm\:focus\:bg-secondary:focus,
    .\!sm\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(134, 83, 0, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-secondary,
    .sm\:hover\:border-secondary:hover,
    .sm\:focus\:border-secondary:focus,
    .sm\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(134, 83, 0, var(--tw-border-opacity));
    }

    .\!sm\:border-secondary,
    .\!sm\:hover\:border-secondary:hover,
    .\!sm\:focus\:border-secondary:focus,
    .\!sm\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(134, 83, 0, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-secondary,
    .sm\:hover\:ring-secondary:hover,
    .sm\:focus\:ring-secondary:focus,
    .sm\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(134, 83, 0, var(--tw-ring-opacity));
    }

    .\!sm\:ring-secondary,
    .\!sm\:hover\:ring-secondary:hover,
    .\!sm\:focus\:ring-secondary:focus,
    .\!sm\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(134, 83, 0, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-secondary,
    .md\:hover\:text-secondary:hover,
    .md\:focus\:text-secondary:focus,
    .md\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgb(134, 83, 0, var(--tw-text-opacity));
    }

    .\!md\:text-secondary,
    .\!md\:hover\:text-secondary:hover,
    .\!md\:focus\:text-secondary:focus,
    .\!md\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgb(134, 83, 0, var(--tw-text-opacity)) !important;
    }

    .md\:bg-secondary,
    .md\:hover\:bg-secondary:hover,
    .md\:focus\:bg-secondary:focus,
    .md\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(134, 83, 0, var(--tw-bg-opacity));
    }

    .\!md\:bg-secondary,
    .\!md\:hover\:bg-secondary:hover,
    .\!md\:focus\:bg-secondary:focus,
    .\!md\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(134, 83, 0, var(--tw-bg-opacity)) !important;
    }

    .md\:border-secondary,
    .md\:hover\:border-secondary:hover,
    .md\:focus\:border-secondary:focus,
    .md\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(134, 83, 0, var(--tw-border-opacity));
    }

    .\!md\:border-secondary,
    .\!md\:hover\:border-secondary:hover,
    .\!md\:focus\:border-secondary:focus,
    .\!md\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(134, 83, 0, var(--tw-border-opacity)) !important;
    }

    .md\:ring-secondary,
    .md\:hover\:ring-secondary:hover,
    .md\:focus\:ring-secondary:focus,
    .md\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(134, 83, 0, var(--tw-ring-opacity));
    }

    .\!md\:ring-secondary,
    .\!md\:hover\:ring-secondary:hover,
    .\!md\:focus\:ring-secondary:focus,
    .\!md\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(134, 83, 0, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-secondary,
    .lg\:hover\:text-secondary:hover,
    .lg\:focus\:text-secondary:focus,
    .lg\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgb(134, 83, 0, var(--tw-text-opacity));
    }

    .\!lg\:text-secondary,
    .\!lg\:hover\:text-secondary:hover,
    .\!lg\:focus\:text-secondary:focus,
    .\!lg\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgb(134, 83, 0, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-secondary,
    .lg\:hover\:bg-secondary:hover,
    .lg\:focus\:bg-secondary:focus,
    .lg\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(134, 83, 0, var(--tw-bg-opacity));
    }

    .\!lg\:bg-secondary,
    .\!lg\:hover\:bg-secondary:hover,
    .\!lg\:focus\:bg-secondary:focus,
    .\!lg\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(134, 83, 0, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-secondary,
    .lg\:hover\:border-secondary:hover,
    .lg\:focus\:border-secondary:focus,
    .lg\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(134, 83, 0, var(--tw-border-opacity));
    }

    .\!lg\:border-secondary,
    .\!lg\:hover\:border-secondary:hover,
    .\!lg\:focus\:border-secondary:focus,
    .\!lg\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(134, 83, 0, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-secondary,
    .lg\:hover\:ring-secondary:hover,
    .lg\:focus\:ring-secondary:focus,
    .lg\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(134, 83, 0, var(--tw-ring-opacity));
    }

    .\!lg\:ring-secondary,
    .\!lg\:hover\:ring-secondary:hover,
    .\!lg\:focus\:ring-secondary:focus,
    .\!lg\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(134, 83, 0, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-secondary,
    .xl\:hover\:text-secondary:hover,
    .xl\:focus\:text-secondary:focus,
    .xl\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgb(134, 83, 0, var(--tw-text-opacity));
    }

    .\!xl\:text-secondary,
    .\!xl\:hover\:text-secondary:hover,
    .\!xl\:focus\:text-secondary:focus,
    .\!xl\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgb(134, 83, 0, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-secondary,
    .xl\:hover\:bg-secondary:hover,
    .xl\:focus\:bg-secondary:focus,
    .xl\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(134, 83, 0, var(--tw-bg-opacity));
    }

    .\!xl\:bg-secondary,
    .\!xl\:hover\:bg-secondary:hover,
    .\!xl\:focus\:bg-secondary:focus,
    .\!xl\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(134, 83, 0, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-secondary,
    .xl\:hover\:border-secondary:hover,
    .xl\:focus\:border-secondary:focus,
    .xl\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(134, 83, 0, var(--tw-border-opacity));
    }

    .\!xl\:border-secondary,
    .\!xl\:hover\:border-secondary:hover,
    .\!xl\:focus\:border-secondary:focus,
    .\!xl\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(134, 83, 0, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-secondary,
    .xl\:hover\:ring-secondary:hover,
    .xl\:focus\:ring-secondary:focus,
    .xl\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(134, 83, 0, var(--tw-ring-opacity));
    }

    .\!xl\:ring-secondary,
    .\!xl\:hover\:ring-secondary:hover,
    .\!xl\:focus\:ring-secondary:focus,
    .\!xl\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(134, 83, 0, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-secondary,
    .\2xl\:hover\:text-secondary:hover,
    .\2xl\:focus\:text-secondary:focus,
    .\2xl\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgb(134, 83, 0, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-secondary,
    .\!2xlsm\:hover\:text-secondary:hover,
    .\!2xlsm\:focus\:text-secondary:focus,
    .\!2xlsm\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgb(134, 83, 0, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-secondary,
    .\2xl\:hover\:bg-secondary:hover,
    .\2xl\:focus\:bg-secondary:focus,
    .\2xl\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(134, 83, 0, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-secondary,
    .\!2xlsm\:hover\:bg-secondary:hover,
    .\!2xlsm\:focus\:bg-secondary:focus,
    .\!2xlsm\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(134, 83, 0, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-secondary,
    .\2xl\:hover\:border-secondary:hover,
    .\2xl\:focus\:border-secondary:focus,
    .\2xl\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(134, 83, 0, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-secondary,
    .\!2xlsm\:hover\:border-secondary:hover,
    .\!2xlsm\:focus\:border-secondary:focus,
    .\!2xlsm\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(134, 83, 0, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-secondary,
    .\2xl\:hover\:ring-secondary:hover,
    .\2xl\:focus\:ring-secondary:focus,
    .\2xl\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(134, 83, 0, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-secondary,
    .\!2xl\:hover\:ring-secondary:hover,
    .\!2xl\:focus\:ring-secondary:focus,
    .\!2xl\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(134, 83, 0, var(--tw-ring-opacity)) !important;
    }
}

//on-secondary-container
.text-on-secondary-container,
.hover\:text-on-secondary-container:hover,
.focus\:text-on-secondary-container:focus,
.checked\:text-on-secondary-container:checked {
    --tw-text-opacity: 1;
    color: rgb(43, 23, 0, var(--tw-text-opacity));
}

.\!text-on-secondary-container,
.\!hover\:text-on-secondary-container:hover,
.\!focus\:text-on-secondary-container:focus,
.\!checked\:text-on-secondary-container:checked {
    --tw-text-opacity: 1;
    color: rgb(43, 23, 0, var(--tw-text-opacity)) !important;
}

.bg-on-secondary-container,
.hover\:bg-on-secondary-container:hover,
.focus\:bg-on-secondary-container:focus,
.checked\:bg-on-secondary-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(43, 23, 0, var(--tw-bg-opacity));
}

.\!bg-on-secondary-container,
.\!hover\:bg-on-secondary-container:hover,
.\!focus\:bg-on-secondary-container:focus,
.\!checked\:bg-on-secondary-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(43, 23, 0, var(--tw-bg-opacity)) !important;
}

.border-on-secondary-container,
.hover\:border-on-secondary-container:hover,
.focus\:border-on-secondary-container:focus,
.checked\:border-on-secondary-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(43, 23, 0, var(--tw-border-opacity));
}

.\!border-on-secondary-container,
.\!hover\:border-on-secondary-container:hover,
.\!focus\:border-on-secondary-container:focus,
.\!checked\:border-on-secondary-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(43, 23, 0, var(--tw-border-opacity)) !important;
}

.ring-on-secondary-container,
.hover\:ring-on-secondary-container:hover,
.focus\:ring-on-secondary-container:focus,
.checked\:ring-on-secondary-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(43, 23, 0, var(--tw-ring-opacity));
}

.\!ring-on-secondary-container,
.\!hover\:ring-on-secondary-container:hover,
.\!focus\:ring-on-secondary-container:focus,
.\!checked\:ring-on-secondary-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(43, 23, 0, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-on-secondary-container,
    .sm\:hover\:text-on-secondary-container:hover,
    .sm\:focus\:text-on-secondary-container:focus,
    .sm\:checked\:text-on-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(43, 23, 0, var(--tw-text-opacity));
    }

    .\!sm\:text-on-secondary-container,
    .\!sm\:hover\:text-on-secondary-container:hover,
    .\!sm\:focus\:text-on-secondary-container:focus,
    .\!sm\:checked\:text-on-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(43, 23, 0, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-on-secondary-container,
    .sm\:hover\:bg-on-secondary-container:hover,
    .sm\:focus\:bg-on-secondary-container:focus,
    .sm\:checked\:bg-on-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(43, 23, 0, var(--tw-bg-opacity));
    }

    .\!sm\:bg-on-secondary-container,
    .\!sm\:hover\:bg-on-secondary-container:hover,
    .\!sm\:focus\:bg-on-secondary-container:focus,
    .\!sm\:checked\:bg-on-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(43, 23, 0, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-on-secondary-container,
    .sm\:hover\:border-on-secondary-container:hover,
    .sm\:focus\:border-on-secondary-container:focus,
    .sm\:checked\:border-on-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(43, 23, 0, var(--tw-border-opacity));
    }

    .\!sm\:border-on-secondary-container,
    .\!sm\:hover\:border-on-secondary-container:hover,
    .\!sm\:focus\:border-on-secondary-container:focus,
    .\!sm\:checked\:border-on-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(43, 23, 0, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-on-secondary-container,
    .sm\:hover\:ring-on-secondary-container:hover,
    .sm\:focus\:ring-on-secondary-container:focus,
    .sm\:checked\:ring-on-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(43, 23, 0, var(--tw-ring-opacity));
    }

    .\!sm\:ring-on-secondary-container,
    .\!sm\:hover\:ring-on-secondary-container:hover,
    .\!sm\:focus\:ring-on-secondary-container:focus,
    .\!sm\:checked\:ring-on-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(43, 23, 0, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-on-secondary-container,
    .md\:hover\:text-on-secondary-container:hover,
    .md\:focus\:text-on-secondary-container:focus,
    .md\:checked\:text-on-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(43, 23, 0, var(--tw-text-opacity));
    }

    .\!md\:text-on-secondary-container,
    .\!md\:hover\:text-on-secondary-container:hover,
    .\!md\:focus\:text-on-secondary-container:focus,
    .\!md\:checked\:text-on-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(43, 23, 0, var(--tw-text-opacity)) !important;
    }

    .md\:bg-on-secondary-container,
    .md\:hover\:bg-on-secondary-container:hover,
    .md\:focus\:bg-on-secondary-container:focus,
    .md\:checked\:bg-on-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(43, 23, 0, var(--tw-bg-opacity));
    }

    .\!md\:bg-on-secondary-container,
    .\!md\:hover\:bg-on-secondary-container:hover,
    .\!md\:focus\:bg-on-secondary-container:focus,
    .\!md\:checked\:bg-on-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(43, 23, 0, var(--tw-bg-opacity)) !important;
    }

    .md\:border-on-secondary-container,
    .md\:hover\:border-on-secondary-container:hover,
    .md\:focus\:border-on-secondary-container:focus,
    .md\:checked\:border-on-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(43, 23, 0, var(--tw-border-opacity));
    }

    .\!md\:border-on-secondary-container,
    .\!md\:hover\:border-on-secondary-container:hover,
    .\!md\:focus\:border-on-secondary-container:focus,
    .\!md\:checked\:border-on-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(43, 23, 0, var(--tw-border-opacity)) !important;
    }

    .md\:ring-on-secondary-container,
    .md\:hover\:ring-on-secondary-container:hover,
    .md\:focus\:ring-on-secondary-container:focus,
    .md\:checked\:ring-on-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(43, 23, 0, var(--tw-ring-opacity));
    }

    .\!md\:ring-on-secondary-container,
    .\!md\:hover\:ring-on-secondary-container:hover,
    .\!md\:focus\:ring-on-secondary-container:focus,
    .\!md\:checked\:ring-on-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(43, 23, 0, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-on-secondary-container,
    .lg\:hover\:text-on-secondary-container:hover,
    .lg\:focus\:text-on-secondary-container:focus,
    .lg\:checked\:text-on-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(43, 23, 0, var(--tw-text-opacity));
    }

    .\!lg\:text-on-secondary-container,
    .\!lg\:hover\:text-on-secondary-container:hover,
    .\!lg\:focus\:text-on-secondary-container:focus,
    .\!lg\:checked\:text-on-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(43, 23, 0, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-on-secondary-container,
    .lg\:hover\:bg-on-secondary-container:hover,
    .lg\:focus\:bg-on-secondary-container:focus,
    .lg\:checked\:bg-on-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(43, 23, 0, var(--tw-bg-opacity));
    }

    .\!lg\:bg-on-secondary-container,
    .\!lg\:hover\:bg-on-secondary-container:hover,
    .\!lg\:focus\:bg-on-secondary-container:focus,
    .\!lg\:checked\:bg-on-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(43, 23, 0, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-on-secondary-container,
    .lg\:hover\:border-on-secondary-container:hover,
    .lg\:focus\:border-on-secondary-container:focus,
    .lg\:checked\:border-on-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(43, 23, 0, var(--tw-border-opacity));
    }

    .\!lg\:border-on-secondary-container,
    .\!lg\:hover\:border-on-secondary-container:hover,
    .\!lg\:focus\:border-on-secondary-container:focus,
    .\!lg\:checked\:border-on-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(43, 23, 0, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-on-secondary-container,
    .lg\:hover\:ring-on-secondary-container:hover,
    .lg\:focus\:ring-on-secondary-container:focus,
    .lg\:checked\:ring-on-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(43, 23, 0, var(--tw-ring-opacity));
    }

    .\!lg\:ring-on-secondary-container,
    .\!lg\:hover\:ring-on-secondary-container:hover,
    .\!lg\:focus\:ring-on-secondary-container:focus,
    .\!lg\:checked\:ring-on-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(43, 23, 0, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-on-secondary-container,
    .xl\:hover\:text-on-secondary-container:hover,
    .xl\:focus\:text-on-secondary-container:focus,
    .xl\:checked\:text-on-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(43, 23, 0, var(--tw-text-opacity));
    }

    .\!xl\:text-on-secondary-container,
    .\!xl\:hover\:text-on-secondary-container:hover,
    .\!xl\:focus\:text-on-secondary-container:focus,
    .\!xl\:checked\:text-on-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(43, 23, 0, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-on-secondary-container,
    .xl\:hover\:bg-on-secondary-container:hover,
    .xl\:focus\:bg-on-secondary-container:focus,
    .xl\:checked\:bg-on-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(43, 23, 0, var(--tw-bg-opacity));
    }

    .\!xl\:bg-on-secondary-container,
    .\!xl\:hover\:bg-on-secondary-container:hover,
    .\!xl\:focus\:bg-on-secondary-container:focus,
    .\!xl\:checked\:bg-on-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(43, 23, 0, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-on-secondary-container,
    .xl\:hover\:border-on-secondary-container:hover,
    .xl\:focus\:border-on-secondary-container:focus,
    .xl\:checked\:border-on-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(43, 23, 0, var(--tw-border-opacity));
    }

    .\!xl\:border-on-secondary-container,
    .\!xl\:hover\:border-on-secondary-container:hover,
    .\!xl\:focus\:border-on-secondary-container:focus,
    .\!xl\:checked\:border-on-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(43, 23, 0, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-on-secondary-container,
    .xl\:hover\:ring-on-secondary-container:hover,
    .xl\:focus\:ring-on-secondary-container:focus,
    .xl\:checked\:ring-on-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(43, 23, 0, var(--tw-ring-opacity));
    }

    .\!xl\:ring-on-secondary-container,
    .\!xl\:hover\:ring-on-secondary-container:hover,
    .\!xl\:focus\:ring-on-secondary-container:focus,
    .\!xl\:checked\:ring-on-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(43, 23, 0, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-on-secondary-container,
    .\2xl\:hover\:text-on-secondary-container:hover,
    .\2xl\:focus\:text-on-secondary-container:focus,
    .\2xl\:checked\:text-on-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(43, 23, 0, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-on-secondary-container,
    .\!2xlsm\:hover\:text-on-secondary-container:hover,
    .\!2xlsm\:focus\:text-on-secondary-container:focus,
    .\!2xlsm\:checked\:text-on-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(43, 23, 0, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-on-secondary-container,
    .\2xl\:hover\:bg-on-secondary-container:hover,
    .\2xl\:focus\:bg-on-secondary-container:focus,
    .\2xl\:checked\:bg-on-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(43, 23, 0, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-on-secondary-container,
    .\!2xlsm\:hover\:bg-on-secondary-container:hover,
    .\!2xlsm\:focus\:bg-on-secondary-container:focus,
    .\!2xlsm\:checked\:bg-on-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(43, 23, 0, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-on-secondary-container,
    .\2xl\:hover\:border-on-secondary-container:hover,
    .\2xl\:focus\:border-on-secondary-container:focus,
    .\2xl\:checked\:border-on-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(43, 23, 0, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-on-secondary-container,
    .\!2xlsm\:hover\:border-on-secondary-container:hover,
    .\!2xlsm\:focus\:border-on-secondary-container:focus,
    .\!2xlsm\:checked\:border-on-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(43, 23, 0, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-on-secondary-container,
    .\2xl\:hover\:ring-on-secondary-container:hover,
    .\2xl\:focus\:ring-on-secondary-container:focus,
    .\2xl\:checked\:ring-on-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(43, 23, 0, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-on-secondary-container,
    .\!2xl\:hover\:ring-on-secondary-container:hover,
    .\!2xl\:focus\:ring-on-secondary-container:focus,
    .\!2xl\:checked\:ring-on-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(43, 23, 0, var(--tw-ring-opacity)) !important;
    }
}

//tertiary
.text-tertiary,
.hover\:text-tertiary:hover,
.focus\:text-tertiary:focus,
.checked\:text-tertiary:checked {
    --tw-text-opacity: 1;
    color: rgb(0, 103, 131, var(--tw-text-opacity));
}

.\!text-tertiary,
.\!hover\:text-tertiary:hover,
.\!focus\:text-tertiary:focus,
.\!checked\:text-tertiary:checked {
    --tw-text-opacity: 1;
    color: rgb(0, 103, 131, var(--tw-text-opacity)) !important;
}

.bg-tertiary,
.hover\:bg-tertiary:hover,
.focus\:bg-tertiary:focus,
.checked\:bg-tertiary:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(0, 103, 131, var(--tw-bg-opacity));
}

.\!bg-tertiary,
.\!hover\:bg-tertiary:hover,
.\!focus\:bg-tertiary:focus,
.\!checked\:bg-tertiary:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(0, 103, 131, var(--tw-bg-opacity)) !important;
}

.border-tertiary,
.hover\:border-tertiary:hover,
.focus\:border-tertiary:focus,
.checked\:border-tertiary:checked {
    --tw-border-opacity: 1;
    border-color: rgb(0, 103, 131, var(--tw-border-opacity));
}

.\!border-tertiary,
.\!hover\:border-tertiary:hover,
.\!focus\:border-tertiary:focus,
.\!checked\:border-tertiary:checked {
    --tw-border-opacity: 1;
    border-color: rgb(0, 103, 131, var(--tw-border-opacity)) !important;
}

.ring-tertiary,
.hover\:ring-tertiary:hover,
.focus\:ring-tertiary:focus,
.checked\:ring-tertiary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(0, 103, 131, var(--tw-ring-opacity));
}

.\!ring-tertiary,
.\!hover\:ring-tertiary:hover,
.\!focus\:ring-tertiary:focus,
.\!checked\:ring-tertiary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(0, 103, 131, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-tertiary,
    .sm\:hover\:text-tertiary:hover,
    .sm\:focus\:text-tertiary:focus,
    .sm\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 103, 131, var(--tw-text-opacity));
    }

    .\!sm\:text-tertiary,
    .\!sm\:hover\:text-tertiary:hover,
    .\!sm\:focus\:text-tertiary:focus,
    .\!sm\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 103, 131, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-tertiary,
    .sm\:hover\:bg-tertiary:hover,
    .sm\:focus\:bg-tertiary:focus,
    .sm\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 103, 131, var(--tw-bg-opacity));
    }

    .\!sm\:bg-tertiary,
    .\!sm\:hover\:bg-tertiary:hover,
    .\!sm\:focus\:bg-tertiary:focus,
    .\!sm\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 103, 131, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-tertiary,
    .sm\:hover\:border-tertiary:hover,
    .sm\:focus\:border-tertiary:focus,
    .sm\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 103, 131, var(--tw-border-opacity));
    }

    .\!sm\:border-tertiary,
    .\!sm\:hover\:border-tertiary:hover,
    .\!sm\:focus\:border-tertiary:focus,
    .\!sm\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 103, 131, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-tertiary,
    .sm\:hover\:ring-tertiary:hover,
    .sm\:focus\:ring-tertiary:focus,
    .sm\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 103, 131, var(--tw-ring-opacity));
    }

    .\!sm\:ring-tertiary,
    .\!sm\:hover\:ring-tertiary:hover,
    .\!sm\:focus\:ring-tertiary:focus,
    .\!sm\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 103, 131, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-tertiary,
    .md\:hover\:text-tertiary:hover,
    .md\:focus\:text-tertiary:focus,
    .md\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 103, 131, var(--tw-text-opacity));
    }

    .\!md\:text-tertiary,
    .\!md\:hover\:text-tertiary:hover,
    .\!md\:focus\:text-tertiary:focus,
    .\!md\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 103, 131, var(--tw-text-opacity)) !important;
    }

    .md\:bg-tertiary,
    .md\:hover\:bg-tertiary:hover,
    .md\:focus\:bg-tertiary:focus,
    .md\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 103, 131, var(--tw-bg-opacity));
    }

    .\!md\:bg-tertiary,
    .\!md\:hover\:bg-tertiary:hover,
    .\!md\:focus\:bg-tertiary:focus,
    .\!md\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 103, 131, var(--tw-bg-opacity)) !important;
    }

    .md\:border-tertiary,
    .md\:hover\:border-tertiary:hover,
    .md\:focus\:border-tertiary:focus,
    .md\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 103, 131, var(--tw-border-opacity));
    }

    .\!md\:border-tertiary,
    .\!md\:hover\:border-tertiary:hover,
    .\!md\:focus\:border-tertiary:focus,
    .\!md\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 103, 131, var(--tw-border-opacity)) !important;
    }

    .md\:ring-tertiary,
    .md\:hover\:ring-tertiary:hover,
    .md\:focus\:ring-tertiary:focus,
    .md\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 103, 131, var(--tw-ring-opacity));
    }

    .\!md\:ring-tertiary,
    .\!md\:hover\:ring-tertiary:hover,
    .\!md\:focus\:ring-tertiary:focus,
    .\!md\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 103, 131, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-tertiary,
    .lg\:hover\:text-tertiary:hover,
    .lg\:focus\:text-tertiary:focus,
    .lg\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 103, 131, var(--tw-text-opacity));
    }

    .\!lg\:text-tertiary,
    .\!lg\:hover\:text-tertiary:hover,
    .\!lg\:focus\:text-tertiary:focus,
    .\!lg\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 103, 131, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-tertiary,
    .lg\:hover\:bg-tertiary:hover,
    .lg\:focus\:bg-tertiary:focus,
    .lg\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 103, 131, var(--tw-bg-opacity));
    }

    .\!lg\:bg-tertiary,
    .\!lg\:hover\:bg-tertiary:hover,
    .\!lg\:focus\:bg-tertiary:focus,
    .\!lg\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 103, 131, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-tertiary,
    .lg\:hover\:border-tertiary:hover,
    .lg\:focus\:border-tertiary:focus,
    .lg\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 103, 131, var(--tw-border-opacity));
    }

    .\!lg\:border-tertiary,
    .\!lg\:hover\:border-tertiary:hover,
    .\!lg\:focus\:border-tertiary:focus,
    .\!lg\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 103, 131, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-tertiary,
    .lg\:hover\:ring-tertiary:hover,
    .lg\:focus\:ring-tertiary:focus,
    .lg\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 103, 131, var(--tw-ring-opacity));
    }

    .\!lg\:ring-tertiary,
    .\!lg\:hover\:ring-tertiary:hover,
    .\!lg\:focus\:ring-tertiary:focus,
    .\!lg\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 103, 131, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-tertiary,
    .xl\:hover\:text-tertiary:hover,
    .xl\:focus\:text-tertiary:focus,
    .xl\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 103, 131, var(--tw-text-opacity));
    }

    .\!xl\:text-tertiary,
    .\!xl\:hover\:text-tertiary:hover,
    .\!xl\:focus\:text-tertiary:focus,
    .\!xl\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 103, 131, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-tertiary,
    .xl\:hover\:bg-tertiary:hover,
    .xl\:focus\:bg-tertiary:focus,
    .xl\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 103, 131, var(--tw-bg-opacity));
    }

    .\!xl\:bg-tertiary,
    .\!xl\:hover\:bg-tertiary:hover,
    .\!xl\:focus\:bg-tertiary:focus,
    .\!xl\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 103, 131, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-tertiary,
    .xl\:hover\:border-tertiary:hover,
    .xl\:focus\:border-tertiary:focus,
    .xl\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 103, 131, var(--tw-border-opacity));
    }

    .\!xl\:border-tertiary,
    .\!xl\:hover\:border-tertiary:hover,
    .\!xl\:focus\:border-tertiary:focus,
    .\!xl\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 103, 131, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-tertiary,
    .xl\:hover\:ring-tertiary:hover,
    .xl\:focus\:ring-tertiary:focus,
    .xl\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 103, 131, var(--tw-ring-opacity));
    }

    .\!xl\:ring-tertiary,
    .\!xl\:hover\:ring-tertiary:hover,
    .\!xl\:focus\:ring-tertiary:focus,
    .\!xl\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 103, 131, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-tertiary,
    .\2xl\:hover\:text-tertiary:hover,
    .\2xl\:focus\:text-tertiary:focus,
    .\2xl\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 103, 131, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-tertiary,
    .\!2xlsm\:hover\:text-tertiary:hover,
    .\!2xlsm\:focus\:text-tertiary:focus,
    .\!2xlsm\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 103, 131, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-tertiary,
    .\2xl\:hover\:bg-tertiary:hover,
    .\2xl\:focus\:bg-tertiary:focus,
    .\2xl\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 103, 131, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-tertiary,
    .\!2xlsm\:hover\:bg-tertiary:hover,
    .\!2xlsm\:focus\:bg-tertiary:focus,
    .\!2xlsm\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 103, 131, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-tertiary,
    .\2xl\:hover\:border-tertiary:hover,
    .\2xl\:focus\:border-tertiary:focus,
    .\2xl\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 103, 131, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-tertiary,
    .\!2xlsm\:hover\:border-tertiary:hover,
    .\!2xlsm\:focus\:border-tertiary:focus,
    .\!2xlsm\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 103, 131, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-tertiary,
    .\2xl\:hover\:ring-tertiary:hover,
    .\2xl\:focus\:ring-tertiary:focus,
    .\2xl\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 103, 131, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-tertiary,
    .\!2xl\:hover\:ring-tertiary:hover,
    .\!2xl\:focus\:ring-tertiary:focus,
    .\!2xl\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 103, 131, var(--tw-ring-opacity)) !important;
    }
}

//on-tertiary-container
.text-on-tertiary-container,
.hover\:text-on-tertiary-container:hover,
.focus\:text-on-tertiary-container:focus,
.checked\:text-on-tertiary-container:checked {
    --tw-text-opacity: 1;
    color: rgb(0, 31, 42, var(--tw-text-opacity));
}

.\!text-on-tertiary-container,
.\!hover\:text-on-tertiary-container:hover,
.\!focus\:text-on-tertiary-container:focus,
.\!checked\:text-on-tertiary-container:checked {
    --tw-text-opacity: 1;
    color: rgb(0, 31, 42, var(--tw-text-opacity)) !important;
}

.bg-on-tertiary-container,
.hover\:bg-on-tertiary-container:hover,
.focus\:bg-on-tertiary-container:focus,
.checked\:bg-on-tertiary-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(0, 31, 42, var(--tw-bg-opacity));
}

.\!bg-on-tertiary-container,
.\!hover\:bg-on-tertiary-container:hover,
.\!focus\:bg-on-tertiary-container:focus,
.\!checked\:bg-on-tertiary-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(0, 31, 42, var(--tw-bg-opacity)) !important;
}

.border-on-tertiary-container,
.hover\:border-on-tertiary-container:hover,
.focus\:border-on-tertiary-container:focus,
.checked\:border-on-tertiary-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(0, 31, 42, var(--tw-border-opacity));
}

.\!border-on-tertiary-container,
.\!hover\:border-on-tertiary-container:hover,
.\!focus\:border-on-tertiary-container:focus,
.\!checked\:border-on-tertiary-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(0, 31, 42, var(--tw-border-opacity)) !important;
}

.ring-on-tertiary-container,
.hover\:ring-on-tertiary-container:hover,
.focus\:ring-on-tertiary-container:focus,
.checked\:ring-on-tertiary-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(0, 31, 42, var(--tw-ring-opacity));
}

.\!ring-on-tertiary-container,
.\!hover\:ring-on-tertiary-container:hover,
.\!focus\:ring-on-tertiary-container:focus,
.\!checked\:ring-on-tertiary-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(0, 31, 42, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-on-tertiary-container,
    .sm\:hover\:text-on-tertiary-container:hover,
    .sm\:focus\:text-on-tertiary-container:focus,
    .sm\:checked\:text-on-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 31, 42, var(--tw-text-opacity));
    }

    .\!sm\:text-on-tertiary-container,
    .\!sm\:hover\:text-on-tertiary-container:hover,
    .\!sm\:focus\:text-on-tertiary-container:focus,
    .\!sm\:checked\:text-on-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 31, 42, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-on-tertiary-container,
    .sm\:hover\:bg-on-tertiary-container:hover,
    .sm\:focus\:bg-on-tertiary-container:focus,
    .sm\:checked\:bg-on-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 31, 42, var(--tw-bg-opacity));
    }

    .\!sm\:bg-on-tertiary-container,
    .\!sm\:hover\:bg-on-tertiary-container:hover,
    .\!sm\:focus\:bg-on-tertiary-container:focus,
    .\!sm\:checked\:bg-on-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 31, 42, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-on-tertiary-container,
    .sm\:hover\:border-on-tertiary-container:hover,
    .sm\:focus\:border-on-tertiary-container:focus,
    .sm\:checked\:border-on-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 31, 42, var(--tw-border-opacity));
    }

    .\!sm\:border-on-tertiary-container,
    .\!sm\:hover\:border-on-tertiary-container:hover,
    .\!sm\:focus\:border-on-tertiary-container:focus,
    .\!sm\:checked\:border-on-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 31, 42, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-on-tertiary-container,
    .sm\:hover\:ring-on-tertiary-container:hover,
    .sm\:focus\:ring-on-tertiary-container:focus,
    .sm\:checked\:ring-on-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 31, 42, var(--tw-ring-opacity));
    }

    .\!sm\:ring-on-tertiary-container,
    .\!sm\:hover\:ring-on-tertiary-container:hover,
    .\!sm\:focus\:ring-on-tertiary-container:focus,
    .\!sm\:checked\:ring-on-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 31, 42, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-on-tertiary-container,
    .md\:hover\:text-on-tertiary-container:hover,
    .md\:focus\:text-on-tertiary-container:focus,
    .md\:checked\:text-on-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 31, 42, var(--tw-text-opacity));
    }

    .\!md\:text-on-tertiary-container,
    .\!md\:hover\:text-on-tertiary-container:hover,
    .\!md\:focus\:text-on-tertiary-container:focus,
    .\!md\:checked\:text-on-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 31, 42, var(--tw-text-opacity)) !important;
    }

    .md\:bg-on-tertiary-container,
    .md\:hover\:bg-on-tertiary-container:hover,
    .md\:focus\:bg-on-tertiary-container:focus,
    .md\:checked\:bg-on-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 31, 42, var(--tw-bg-opacity));
    }

    .\!md\:bg-on-tertiary-container,
    .\!md\:hover\:bg-on-tertiary-container:hover,
    .\!md\:focus\:bg-on-tertiary-container:focus,
    .\!md\:checked\:bg-on-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 31, 42, var(--tw-bg-opacity)) !important;
    }

    .md\:border-on-tertiary-container,
    .md\:hover\:border-on-tertiary-container:hover,
    .md\:focus\:border-on-tertiary-container:focus,
    .md\:checked\:border-on-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 31, 42, var(--tw-border-opacity));
    }

    .\!md\:border-on-tertiary-container,
    .\!md\:hover\:border-on-tertiary-container:hover,
    .\!md\:focus\:border-on-tertiary-container:focus,
    .\!md\:checked\:border-on-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 31, 42, var(--tw-border-opacity)) !important;
    }

    .md\:ring-on-tertiary-container,
    .md\:hover\:ring-on-tertiary-container:hover,
    .md\:focus\:ring-on-tertiary-container:focus,
    .md\:checked\:ring-on-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 31, 42, var(--tw-ring-opacity));
    }

    .\!md\:ring-on-tertiary-container,
    .\!md\:hover\:ring-on-tertiary-container:hover,
    .\!md\:focus\:ring-on-tertiary-container:focus,
    .\!md\:checked\:ring-on-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 31, 42, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-on-tertiary-container,
    .lg\:hover\:text-on-tertiary-container:hover,
    .lg\:focus\:text-on-tertiary-container:focus,
    .lg\:checked\:text-on-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 31, 42, var(--tw-text-opacity));
    }

    .\!lg\:text-on-tertiary-container,
    .\!lg\:hover\:text-on-tertiary-container:hover,
    .\!lg\:focus\:text-on-tertiary-container:focus,
    .\!lg\:checked\:text-on-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 31, 42, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-on-tertiary-container,
    .lg\:hover\:bg-on-tertiary-container:hover,
    .lg\:focus\:bg-on-tertiary-container:focus,
    .lg\:checked\:bg-on-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 31, 42, var(--tw-bg-opacity));
    }

    .\!lg\:bg-on-tertiary-container,
    .\!lg\:hover\:bg-on-tertiary-container:hover,
    .\!lg\:focus\:bg-on-tertiary-container:focus,
    .\!lg\:checked\:bg-on-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 31, 42, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-on-tertiary-container,
    .lg\:hover\:border-on-tertiary-container:hover,
    .lg\:focus\:border-on-tertiary-container:focus,
    .lg\:checked\:border-on-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 31, 42, var(--tw-border-opacity));
    }

    .\!lg\:border-on-tertiary-container,
    .\!lg\:hover\:border-on-tertiary-container:hover,
    .\!lg\:focus\:border-on-tertiary-container:focus,
    .\!lg\:checked\:border-on-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 31, 42, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-on-tertiary-container,
    .lg\:hover\:ring-on-tertiary-container:hover,
    .lg\:focus\:ring-on-tertiary-container:focus,
    .lg\:checked\:ring-on-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 31, 42, var(--tw-ring-opacity));
    }

    .\!lg\:ring-on-tertiary-container,
    .\!lg\:hover\:ring-on-tertiary-container:hover,
    .\!lg\:focus\:ring-on-tertiary-container:focus,
    .\!lg\:checked\:ring-on-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 31, 42, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-on-tertiary-container,
    .xl\:hover\:text-on-tertiary-container:hover,
    .xl\:focus\:text-on-tertiary-container:focus,
    .xl\:checked\:text-on-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 31, 42, var(--tw-text-opacity));
    }

    .\!xl\:text-on-tertiary-container,
    .\!xl\:hover\:text-on-tertiary-container:hover,
    .\!xl\:focus\:text-on-tertiary-container:focus,
    .\!xl\:checked\:text-on-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 31, 42, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-on-tertiary-container,
    .xl\:hover\:bg-on-tertiary-container:hover,
    .xl\:focus\:bg-on-tertiary-container:focus,
    .xl\:checked\:bg-on-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 31, 42, var(--tw-bg-opacity));
    }

    .\!xl\:bg-on-tertiary-container,
    .\!xl\:hover\:bg-on-tertiary-container:hover,
    .\!xl\:focus\:bg-on-tertiary-container:focus,
    .\!xl\:checked\:bg-on-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 31, 42, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-on-tertiary-container,
    .xl\:hover\:border-on-tertiary-container:hover,
    .xl\:focus\:border-on-tertiary-container:focus,
    .xl\:checked\:border-on-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 31, 42, var(--tw-border-opacity));
    }

    .\!xl\:border-on-tertiary-container,
    .\!xl\:hover\:border-on-tertiary-container:hover,
    .\!xl\:focus\:border-on-tertiary-container:focus,
    .\!xl\:checked\:border-on-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 31, 42, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-on-tertiary-container,
    .xl\:hover\:ring-on-tertiary-container:hover,
    .xl\:focus\:ring-on-tertiary-container:focus,
    .xl\:checked\:ring-on-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 31, 42, var(--tw-ring-opacity));
    }

    .\!xl\:ring-on-tertiary-container,
    .\!xl\:hover\:ring-on-tertiary-container:hover,
    .\!xl\:focus\:ring-on-tertiary-container:focus,
    .\!xl\:checked\:ring-on-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 31, 42, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-on-tertiary-container,
    .\2xl\:hover\:text-on-tertiary-container:hover,
    .\2xl\:focus\:text-on-tertiary-container:focus,
    .\2xl\:checked\:text-on-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 31, 42, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-on-tertiary-container,
    .\!2xlsm\:hover\:text-on-tertiary-container:hover,
    .\!2xlsm\:focus\:text-on-tertiary-container:focus,
    .\!2xlsm\:checked\:text-on-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(0, 31, 42, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-on-tertiary-container,
    .\2xl\:hover\:bg-on-tertiary-container:hover,
    .\2xl\:focus\:bg-on-tertiary-container:focus,
    .\2xl\:checked\:bg-on-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 31, 42, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-on-tertiary-container,
    .\!2xlsm\:hover\:bg-on-tertiary-container:hover,
    .\!2xlsm\:focus\:bg-on-tertiary-container:focus,
    .\!2xlsm\:checked\:bg-on-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(0, 31, 42, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-on-tertiary-container,
    .\2xl\:hover\:border-on-tertiary-container:hover,
    .\2xl\:focus\:border-on-tertiary-container:focus,
    .\2xl\:checked\:border-on-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 31, 42, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-on-tertiary-container,
    .\!2xlsm\:hover\:border-on-tertiary-container:hover,
    .\!2xlsm\:focus\:border-on-tertiary-container:focus,
    .\!2xlsm\:checked\:border-on-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(0, 31, 42, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-on-tertiary-container,
    .\2xl\:hover\:ring-on-tertiary-container:hover,
    .\2xl\:focus\:ring-on-tertiary-container:focus,
    .\2xl\:checked\:ring-on-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 31, 42, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-on-tertiary-container,
    .\!2xl\:hover\:ring-on-tertiary-container:hover,
    .\!2xl\:focus\:ring-on-tertiary-container:focus,
    .\!2xl\:checked\:ring-on-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(0, 31, 42, var(--tw-ring-opacity)) !important;
    }
}

//error
.text-error,
.hover\:text-error:hover,
.focus\:text-error:focus,
.checked\:text-error:checked {
    --tw-text-opacity: 1;
    color: rgb(186, 26, 26, var(--tw-text-opacity));
}

.\!text-error,
.\!hover\:text-error:hover,
.\!focus\:text-error:focus,
.\!checked\:text-error:checked {
    --tw-text-opacity: 1;
    color: rgb(186, 26, 26, var(--tw-text-opacity)) !important;
}

.bg-error,
.hover\:bg-error:hover,
.focus\:bg-error:focus,
.checked\:bg-error:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(186, 26, 26, var(--tw-bg-opacity));
}

.\!bg-error,
.\!hover\:bg-error:hover,
.\!focus\:bg-error:focus,
.\!checked\:bg-error:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(186, 26, 26, var(--tw-bg-opacity)) !important;
}

.border-error,
.hover\:border-error:hover,
.focus\:border-error:focus,
.checked\:border-error:checked {
    --tw-border-opacity: 1;
    border-color: rgb(186, 26, 26, var(--tw-border-opacity));
}

.\!border-error,
.\!hover\:border-error:hover,
.\!focus\:border-error:focus,
.\!checked\:border-error:checked {
    --tw-border-opacity: 1;
    border-color: rgb(186, 26, 26, var(--tw-border-opacity)) !important;
}

.ring-error,
.hover\:ring-error:hover,
.focus\:ring-error:focus,
.checked\:ring-error:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(186, 26, 26, var(--tw-ring-opacity));
}

.\!ring-error,
.\!hover\:ring-error:hover,
.\!focus\:ring-error:focus,
.\!checked\:ring-error:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(186, 26, 26, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-error,
    .sm\:hover\:text-error:hover,
    .sm\:focus\:text-error:focus,
    .sm\:checked\:text-error:checked {
        --tw-text-opacity: 1;
        color: rgb(186, 26, 26, var(--tw-text-opacity));
    }

    .\!sm\:text-error,
    .\!sm\:hover\:text-error:hover,
    .\!sm\:focus\:text-error:focus,
    .\!sm\:checked\:text-error:checked {
        --tw-text-opacity: 1;
        color: rgb(186, 26, 26, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-error,
    .sm\:hover\:bg-error:hover,
    .sm\:focus\:bg-error:focus,
    .sm\:checked\:bg-error:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(186, 26, 26, var(--tw-bg-opacity));
    }

    .\!sm\:bg-error,
    .\!sm\:hover\:bg-error:hover,
    .\!sm\:focus\:bg-error:focus,
    .\!sm\:checked\:bg-error:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(186, 26, 26, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-error,
    .sm\:hover\:border-error:hover,
    .sm\:focus\:border-error:focus,
    .sm\:checked\:border-error:checked {
        --tw-border-opacity: 1;
        border-color: rgb(186, 26, 26, var(--tw-border-opacity));
    }

    .\!sm\:border-error,
    .\!sm\:hover\:border-error:hover,
    .\!sm\:focus\:border-error:focus,
    .\!sm\:checked\:border-error:checked {
        --tw-border-opacity: 1;
        border-color: rgb(186, 26, 26, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-error,
    .sm\:hover\:ring-error:hover,
    .sm\:focus\:ring-error:focus,
    .sm\:checked\:ring-error:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(186, 26, 26, var(--tw-ring-opacity));
    }

    .\!sm\:ring-error,
    .\!sm\:hover\:ring-error:hover,
    .\!sm\:focus\:ring-error:focus,
    .\!sm\:checked\:ring-error:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(186, 26, 26, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-error,
    .md\:hover\:text-error:hover,
    .md\:focus\:text-error:focus,
    .md\:checked\:text-error:checked {
        --tw-text-opacity: 1;
        color: rgb(186, 26, 26, var(--tw-text-opacity));
    }

    .\!md\:text-error,
    .\!md\:hover\:text-error:hover,
    .\!md\:focus\:text-error:focus,
    .\!md\:checked\:text-error:checked {
        --tw-text-opacity: 1;
        color: rgb(186, 26, 26, var(--tw-text-opacity)) !important;
    }

    .md\:bg-error,
    .md\:hover\:bg-error:hover,
    .md\:focus\:bg-error:focus,
    .md\:checked\:bg-error:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(186, 26, 26, var(--tw-bg-opacity));
    }

    .\!md\:bg-error,
    .\!md\:hover\:bg-error:hover,
    .\!md\:focus\:bg-error:focus,
    .\!md\:checked\:bg-error:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(186, 26, 26, var(--tw-bg-opacity)) !important;
    }

    .md\:border-error,
    .md\:hover\:border-error:hover,
    .md\:focus\:border-error:focus,
    .md\:checked\:border-error:checked {
        --tw-border-opacity: 1;
        border-color: rgb(186, 26, 26, var(--tw-border-opacity));
    }

    .\!md\:border-error,
    .\!md\:hover\:border-error:hover,
    .\!md\:focus\:border-error:focus,
    .\!md\:checked\:border-error:checked {
        --tw-border-opacity: 1;
        border-color: rgb(186, 26, 26, var(--tw-border-opacity)) !important;
    }

    .md\:ring-error,
    .md\:hover\:ring-error:hover,
    .md\:focus\:ring-error:focus,
    .md\:checked\:ring-error:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(186, 26, 26, var(--tw-ring-opacity));
    }

    .\!md\:ring-error,
    .\!md\:hover\:ring-error:hover,
    .\!md\:focus\:ring-error:focus,
    .\!md\:checked\:ring-error:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(186, 26, 26, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-error,
    .lg\:hover\:text-error:hover,
    .lg\:focus\:text-error:focus,
    .lg\:checked\:text-error:checked {
        --tw-text-opacity: 1;
        color: rgb(186, 26, 26, var(--tw-text-opacity));
    }

    .\!lg\:text-error,
    .\!lg\:hover\:text-error:hover,
    .\!lg\:focus\:text-error:focus,
    .\!lg\:checked\:text-error:checked {
        --tw-text-opacity: 1;
        color: rgb(186, 26, 26, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-error,
    .lg\:hover\:bg-error:hover,
    .lg\:focus\:bg-error:focus,
    .lg\:checked\:bg-error:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(186, 26, 26, var(--tw-bg-opacity));
    }

    .\!lg\:bg-error,
    .\!lg\:hover\:bg-error:hover,
    .\!lg\:focus\:bg-error:focus,
    .\!lg\:checked\:bg-error:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(186, 26, 26, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-error,
    .lg\:hover\:border-error:hover,
    .lg\:focus\:border-error:focus,
    .lg\:checked\:border-error:checked {
        --tw-border-opacity: 1;
        border-color: rgb(186, 26, 26, var(--tw-border-opacity));
    }

    .\!lg\:border-error,
    .\!lg\:hover\:border-error:hover,
    .\!lg\:focus\:border-error:focus,
    .\!lg\:checked\:border-error:checked {
        --tw-border-opacity: 1;
        border-color: rgb(186, 26, 26, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-error,
    .lg\:hover\:ring-error:hover,
    .lg\:focus\:ring-error:focus,
    .lg\:checked\:ring-error:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(186, 26, 26, var(--tw-ring-opacity));
    }

    .\!lg\:ring-error,
    .\!lg\:hover\:ring-error:hover,
    .\!lg\:focus\:ring-error:focus,
    .\!lg\:checked\:ring-error:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(186, 26, 26, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-error,
    .xl\:hover\:text-error:hover,
    .xl\:focus\:text-error:focus,
    .xl\:checked\:text-error:checked {
        --tw-text-opacity: 1;
        color: rgb(186, 26, 26, var(--tw-text-opacity));
    }

    .\!xl\:text-error,
    .\!xl\:hover\:text-error:hover,
    .\!xl\:focus\:text-error:focus,
    .\!xl\:checked\:text-error:checked {
        --tw-text-opacity: 1;
        color: rgb(186, 26, 26, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-error,
    .xl\:hover\:bg-error:hover,
    .xl\:focus\:bg-error:focus,
    .xl\:checked\:bg-error:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(186, 26, 26, var(--tw-bg-opacity));
    }

    .\!xl\:bg-error,
    .\!xl\:hover\:bg-error:hover,
    .\!xl\:focus\:bg-error:focus,
    .\!xl\:checked\:bg-error:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(186, 26, 26, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-error,
    .xl\:hover\:border-error:hover,
    .xl\:focus\:border-error:focus,
    .xl\:checked\:border-error:checked {
        --tw-border-opacity: 1;
        border-color: rgb(186, 26, 26, var(--tw-border-opacity));
    }

    .\!xl\:border-error,
    .\!xl\:hover\:border-error:hover,
    .\!xl\:focus\:border-error:focus,
    .\!xl\:checked\:border-error:checked {
        --tw-border-opacity: 1;
        border-color: rgb(186, 26, 26, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-error,
    .xl\:hover\:ring-error:hover,
    .xl\:focus\:ring-error:focus,
    .xl\:checked\:ring-error:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(186, 26, 26, var(--tw-ring-opacity));
    }

    .\!xl\:ring-error,
    .\!xl\:hover\:ring-error:hover,
    .\!xl\:focus\:ring-error:focus,
    .\!xl\:checked\:ring-error:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(186, 26, 26, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-error,
    .\2xl\:hover\:text-error:hover,
    .\2xl\:focus\:text-error:focus,
    .\2xl\:checked\:text-error:checked {
        --tw-text-opacity: 1;
        color: rgb(186, 26, 26, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-error,
    .\!2xlsm\:hover\:text-error:hover,
    .\!2xlsm\:focus\:text-error:focus,
    .\!2xlsm\:checked\:text-error:checked {
        --tw-text-opacity: 1;
        color: rgb(186, 26, 26, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-error,
    .\2xl\:hover\:bg-error:hover,
    .\2xl\:focus\:bg-error:focus,
    .\2xl\:checked\:bg-error:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(186, 26, 26, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-error,
    .\!2xlsm\:hover\:bg-error:hover,
    .\!2xlsm\:focus\:bg-error:focus,
    .\!2xlsm\:checked\:bg-error:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(186, 26, 26, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-error,
    .\2xl\:hover\:border-error:hover,
    .\2xl\:focus\:border-error:focus,
    .\2xl\:checked\:border-error:checked {
        --tw-border-opacity: 1;
        border-color: rgb(186, 26, 26, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-error,
    .\!2xlsm\:hover\:border-error:hover,
    .\!2xlsm\:focus\:border-error:focus,
    .\!2xlsm\:checked\:border-error:checked {
        --tw-border-opacity: 1;
        border-color: rgb(186, 26, 26, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-error,
    .\2xl\:hover\:ring-error:hover,
    .\2xl\:focus\:ring-error:focus,
    .\2xl\:checked\:ring-error:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(186, 26, 26, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-error,
    .\!2xl\:hover\:ring-error:hover,
    .\!2xl\:focus\:ring-error:focus,
    .\!2xl\:checked\:ring-error:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(186, 26, 26, var(--tw-ring-opacity)) !important;
    }
}

//on-error-container
.text-on-error-container,
.hover\:text-on-error-container:hover,
.focus\:text-on-error-container:focus,
.checked\:text-on-error-container:checked {
    --tw-text-opacity: 1;
    color: rgb(65, 0, 2, var(--tw-text-opacity));
}

.\!text-on-error-container,
.\!hover\:text-on-error-container:hover,
.\!focus\:text-on-error-container:focus,
.\!checked\:text-on-error-container:checked {
    --tw-text-opacity: 1;
    color: rgb(65, 0, 2, var(--tw-text-opacity)) !important;
}

.bg-on-error-container,
.hover\:bg-on-error-container:hover,
.focus\:bg-on-error-container:focus,
.checked\:bg-on-error-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(65, 0, 2, var(--tw-bg-opacity));
}

.\!bg-on-error-container,
.\!hover\:bg-on-error-container:hover,
.\!focus\:bg-on-error-container:focus,
.\!checked\:bg-on-error-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(65, 0, 2, var(--tw-bg-opacity)) !important;
}

.border-on-error-container,
.hover\:border-on-error-container:hover,
.focus\:border-on-error-container:focus,
.checked\:border-on-error-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(65, 0, 2, var(--tw-border-opacity));
}

.\!border-on-error-container,
.\!hover\:border-on-error-container:hover,
.\!focus\:border-on-error-container:focus,
.\!checked\:border-on-error-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(65, 0, 2, var(--tw-border-opacity)) !important;
}

.ring-on-error-container,
.hover\:ring-on-error-container:hover,
.focus\:ring-on-error-container:focus,
.checked\:ring-on-error-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(65, 0, 2, var(--tw-ring-opacity));
}

.\!ring-on-error-container,
.\!hover\:ring-on-error-container:hover,
.\!focus\:ring-on-error-container:focus,
.\!checked\:ring-on-error-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(65, 0, 2, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-on-error-container,
    .sm\:hover\:text-on-error-container:hover,
    .sm\:focus\:text-on-error-container:focus,
    .sm\:checked\:text-on-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 2, var(--tw-text-opacity));
    }

    .\!sm\:text-on-error-container,
    .\!sm\:hover\:text-on-error-container:hover,
    .\!sm\:focus\:text-on-error-container:focus,
    .\!sm\:checked\:text-on-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 2, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-on-error-container,
    .sm\:hover\:bg-on-error-container:hover,
    .sm\:focus\:bg-on-error-container:focus,
    .sm\:checked\:bg-on-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 2, var(--tw-bg-opacity));
    }

    .\!sm\:bg-on-error-container,
    .\!sm\:hover\:bg-on-error-container:hover,
    .\!sm\:focus\:bg-on-error-container:focus,
    .\!sm\:checked\:bg-on-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 2, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-on-error-container,
    .sm\:hover\:border-on-error-container:hover,
    .sm\:focus\:border-on-error-container:focus,
    .sm\:checked\:border-on-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 2, var(--tw-border-opacity));
    }

    .\!sm\:border-on-error-container,
    .\!sm\:hover\:border-on-error-container:hover,
    .\!sm\:focus\:border-on-error-container:focus,
    .\!sm\:checked\:border-on-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 2, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-on-error-container,
    .sm\:hover\:ring-on-error-container:hover,
    .sm\:focus\:ring-on-error-container:focus,
    .sm\:checked\:ring-on-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 2, var(--tw-ring-opacity));
    }

    .\!sm\:ring-on-error-container,
    .\!sm\:hover\:ring-on-error-container:hover,
    .\!sm\:focus\:ring-on-error-container:focus,
    .\!sm\:checked\:ring-on-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 2, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-on-error-container,
    .md\:hover\:text-on-error-container:hover,
    .md\:focus\:text-on-error-container:focus,
    .md\:checked\:text-on-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 2, var(--tw-text-opacity));
    }

    .\!md\:text-on-error-container,
    .\!md\:hover\:text-on-error-container:hover,
    .\!md\:focus\:text-on-error-container:focus,
    .\!md\:checked\:text-on-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 2, var(--tw-text-opacity)) !important;
    }

    .md\:bg-on-error-container,
    .md\:hover\:bg-on-error-container:hover,
    .md\:focus\:bg-on-error-container:focus,
    .md\:checked\:bg-on-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 2, var(--tw-bg-opacity));
    }

    .\!md\:bg-on-error-container,
    .\!md\:hover\:bg-on-error-container:hover,
    .\!md\:focus\:bg-on-error-container:focus,
    .\!md\:checked\:bg-on-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 2, var(--tw-bg-opacity)) !important;
    }

    .md\:border-on-error-container,
    .md\:hover\:border-on-error-container:hover,
    .md\:focus\:border-on-error-container:focus,
    .md\:checked\:border-on-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 2, var(--tw-border-opacity));
    }

    .\!md\:border-on-error-container,
    .\!md\:hover\:border-on-error-container:hover,
    .\!md\:focus\:border-on-error-container:focus,
    .\!md\:checked\:border-on-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 2, var(--tw-border-opacity)) !important;
    }

    .md\:ring-on-error-container,
    .md\:hover\:ring-on-error-container:hover,
    .md\:focus\:ring-on-error-container:focus,
    .md\:checked\:ring-on-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 2, var(--tw-ring-opacity));
    }

    .\!md\:ring-on-error-container,
    .\!md\:hover\:ring-on-error-container:hover,
    .\!md\:focus\:ring-on-error-container:focus,
    .\!md\:checked\:ring-on-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 2, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-on-error-container,
    .lg\:hover\:text-on-error-container:hover,
    .lg\:focus\:text-on-error-container:focus,
    .lg\:checked\:text-on-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 2, var(--tw-text-opacity));
    }

    .\!lg\:text-on-error-container,
    .\!lg\:hover\:text-on-error-container:hover,
    .\!lg\:focus\:text-on-error-container:focus,
    .\!lg\:checked\:text-on-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 2, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-on-error-container,
    .lg\:hover\:bg-on-error-container:hover,
    .lg\:focus\:bg-on-error-container:focus,
    .lg\:checked\:bg-on-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 2, var(--tw-bg-opacity));
    }

    .\!lg\:bg-on-error-container,
    .\!lg\:hover\:bg-on-error-container:hover,
    .\!lg\:focus\:bg-on-error-container:focus,
    .\!lg\:checked\:bg-on-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 2, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-on-error-container,
    .lg\:hover\:border-on-error-container:hover,
    .lg\:focus\:border-on-error-container:focus,
    .lg\:checked\:border-on-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 2, var(--tw-border-opacity));
    }

    .\!lg\:border-on-error-container,
    .\!lg\:hover\:border-on-error-container:hover,
    .\!lg\:focus\:border-on-error-container:focus,
    .\!lg\:checked\:border-on-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 2, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-on-error-container,
    .lg\:hover\:ring-on-error-container:hover,
    .lg\:focus\:ring-on-error-container:focus,
    .lg\:checked\:ring-on-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 2, var(--tw-ring-opacity));
    }

    .\!lg\:ring-on-error-container,
    .\!lg\:hover\:ring-on-error-container:hover,
    .\!lg\:focus\:ring-on-error-container:focus,
    .\!lg\:checked\:ring-on-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 2, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-on-error-container,
    .xl\:hover\:text-on-error-container:hover,
    .xl\:focus\:text-on-error-container:focus,
    .xl\:checked\:text-on-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 2, var(--tw-text-opacity));
    }

    .\!xl\:text-on-error-container,
    .\!xl\:hover\:text-on-error-container:hover,
    .\!xl\:focus\:text-on-error-container:focus,
    .\!xl\:checked\:text-on-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 2, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-on-error-container,
    .xl\:hover\:bg-on-error-container:hover,
    .xl\:focus\:bg-on-error-container:focus,
    .xl\:checked\:bg-on-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 2, var(--tw-bg-opacity));
    }

    .\!xl\:bg-on-error-container,
    .\!xl\:hover\:bg-on-error-container:hover,
    .\!xl\:focus\:bg-on-error-container:focus,
    .\!xl\:checked\:bg-on-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 2, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-on-error-container,
    .xl\:hover\:border-on-error-container:hover,
    .xl\:focus\:border-on-error-container:focus,
    .xl\:checked\:border-on-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 2, var(--tw-border-opacity));
    }

    .\!xl\:border-on-error-container,
    .\!xl\:hover\:border-on-error-container:hover,
    .\!xl\:focus\:border-on-error-container:focus,
    .\!xl\:checked\:border-on-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 2, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-on-error-container,
    .xl\:hover\:ring-on-error-container:hover,
    .xl\:focus\:ring-on-error-container:focus,
    .xl\:checked\:ring-on-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 2, var(--tw-ring-opacity));
    }

    .\!xl\:ring-on-error-container,
    .\!xl\:hover\:ring-on-error-container:hover,
    .\!xl\:focus\:ring-on-error-container:focus,
    .\!xl\:checked\:ring-on-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 2, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-on-error-container,
    .\2xl\:hover\:text-on-error-container:hover,
    .\2xl\:focus\:text-on-error-container:focus,
    .\2xl\:checked\:text-on-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 2, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-on-error-container,
    .\!2xlsm\:hover\:text-on-error-container:hover,
    .\!2xlsm\:focus\:text-on-error-container:focus,
    .\!2xlsm\:checked\:text-on-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 2, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-on-error-container,
    .\2xl\:hover\:bg-on-error-container:hover,
    .\2xl\:focus\:bg-on-error-container:focus,
    .\2xl\:checked\:bg-on-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 2, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-on-error-container,
    .\!2xlsm\:hover\:bg-on-error-container:hover,
    .\!2xlsm\:focus\:bg-on-error-container:focus,
    .\!2xlsm\:checked\:bg-on-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 2, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-on-error-container,
    .\2xl\:hover\:border-on-error-container:hover,
    .\2xl\:focus\:border-on-error-container:focus,
    .\2xl\:checked\:border-on-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 2, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-on-error-container,
    .\!2xlsm\:hover\:border-on-error-container:hover,
    .\!2xlsm\:focus\:border-on-error-container:focus,
    .\!2xlsm\:checked\:border-on-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 2, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-on-error-container,
    .\2xl\:hover\:ring-on-error-container:hover,
    .\2xl\:focus\:ring-on-error-container:focus,
    .\2xl\:checked\:ring-on-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 2, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-on-error-container,
    .\!2xl\:hover\:ring-on-error-container:hover,
    .\!2xl\:focus\:ring-on-error-container:focus,
    .\!2xl\:checked\:ring-on-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 2, var(--tw-ring-opacity)) !important;
    }
}

//outline
.text-outline,
.hover\:text-outline:hover,
.focus\:text-outline:focus,
.checked\:text-outline:checked {
    --tw-text-opacity: 1;
    color: rgb(132, 116, 106, var(--tw-text-opacity));
}

.\!text-outline,
.\!hover\:text-outline:hover,
.\!focus\:text-outline:focus,
.\!checked\:text-outline:checked {
    --tw-text-opacity: 1;
    color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
}

.bg-outline,
.hover\:bg-outline:hover,
.focus\:bg-outline:focus,
.checked\:bg-outline:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
}

.\!bg-outline,
.\!hover\:bg-outline:hover,
.\!focus\:bg-outline:focus,
.\!checked\:bg-outline:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
}

.border-outline,
.hover\:border-outline:hover,
.focus\:border-outline:focus,
.checked\:border-outline:checked {
    --tw-border-opacity: 1;
    border-color: rgb(132, 116, 106, var(--tw-border-opacity));
}

.\!border-outline,
.\!hover\:border-outline:hover,
.\!focus\:border-outline:focus,
.\!checked\:border-outline:checked {
    --tw-border-opacity: 1;
    border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
}

.ring-outline,
.hover\:ring-outline:hover,
.focus\:ring-outline:focus,
.checked\:ring-outline:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
}

.\!ring-outline,
.\!hover\:ring-outline:hover,
.\!focus\:ring-outline:focus,
.\!checked\:ring-outline:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-outline,
    .sm\:hover\:text-outline:hover,
    .sm\:focus\:text-outline:focus,
    .sm\:checked\:text-outline:checked {
        --tw-text-opacity: 1;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!sm\:text-outline,
    .\!sm\:hover\:text-outline:hover,
    .\!sm\:focus\:text-outline:focus,
    .\!sm\:checked\:text-outline:checked {
        --tw-text-opacity: 1;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-outline,
    .sm\:hover\:bg-outline:hover,
    .sm\:focus\:bg-outline:focus,
    .sm\:checked\:bg-outline:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!sm\:bg-outline,
    .\!sm\:hover\:bg-outline:hover,
    .\!sm\:focus\:bg-outline:focus,
    .\!sm\:checked\:bg-outline:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-outline,
    .sm\:hover\:border-outline:hover,
    .sm\:focus\:border-outline:focus,
    .sm\:checked\:border-outline:checked {
        --tw-border-opacity: 1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!sm\:border-outline,
    .\!sm\:hover\:border-outline:hover,
    .\!sm\:focus\:border-outline:focus,
    .\!sm\:checked\:border-outline:checked {
        --tw-border-opacity: 1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-outline,
    .sm\:hover\:ring-outline:hover,
    .sm\:focus\:ring-outline:focus,
    .sm\:checked\:ring-outline:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!sm\:ring-outline,
    .\!sm\:hover\:ring-outline:hover,
    .\!sm\:focus\:ring-outline:focus,
    .\!sm\:checked\:ring-outline:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-outline,
    .md\:hover\:text-outline:hover,
    .md\:focus\:text-outline:focus,
    .md\:checked\:text-outline:checked {
        --tw-text-opacity: 1;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!md\:text-outline,
    .\!md\:hover\:text-outline:hover,
    .\!md\:focus\:text-outline:focus,
    .\!md\:checked\:text-outline:checked {
        --tw-text-opacity: 1;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .md\:bg-outline,
    .md\:hover\:bg-outline:hover,
    .md\:focus\:bg-outline:focus,
    .md\:checked\:bg-outline:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!md\:bg-outline,
    .\!md\:hover\:bg-outline:hover,
    .\!md\:focus\:bg-outline:focus,
    .\!md\:checked\:bg-outline:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .md\:border-outline,
    .md\:hover\:border-outline:hover,
    .md\:focus\:border-outline:focus,
    .md\:checked\:border-outline:checked {
        --tw-border-opacity: 1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!md\:border-outline,
    .\!md\:hover\:border-outline:hover,
    .\!md\:focus\:border-outline:focus,
    .\!md\:checked\:border-outline:checked {
        --tw-border-opacity: 1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .md\:ring-outline,
    .md\:hover\:ring-outline:hover,
    .md\:focus\:ring-outline:focus,
    .md\:checked\:ring-outline:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!md\:ring-outline,
    .\!md\:hover\:ring-outline:hover,
    .\!md\:focus\:ring-outline:focus,
    .\!md\:checked\:ring-outline:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-outline,
    .lg\:hover\:text-outline:hover,
    .lg\:focus\:text-outline:focus,
    .lg\:checked\:text-outline:checked {
        --tw-text-opacity: 1;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!lg\:text-outline,
    .\!lg\:hover\:text-outline:hover,
    .\!lg\:focus\:text-outline:focus,
    .\!lg\:checked\:text-outline:checked {
        --tw-text-opacity: 1;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-outline,
    .lg\:hover\:bg-outline:hover,
    .lg\:focus\:bg-outline:focus,
    .lg\:checked\:bg-outline:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!lg\:bg-outline,
    .\!lg\:hover\:bg-outline:hover,
    .\!lg\:focus\:bg-outline:focus,
    .\!lg\:checked\:bg-outline:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-outline,
    .lg\:hover\:border-outline:hover,
    .lg\:focus\:border-outline:focus,
    .lg\:checked\:border-outline:checked {
        --tw-border-opacity: 1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!lg\:border-outline,
    .\!lg\:hover\:border-outline:hover,
    .\!lg\:focus\:border-outline:focus,
    .\!lg\:checked\:border-outline:checked {
        --tw-border-opacity: 1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-outline,
    .lg\:hover\:ring-outline:hover,
    .lg\:focus\:ring-outline:focus,
    .lg\:checked\:ring-outline:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!lg\:ring-outline,
    .\!lg\:hover\:ring-outline:hover,
    .\!lg\:focus\:ring-outline:focus,
    .\!lg\:checked\:ring-outline:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-outline,
    .xl\:hover\:text-outline:hover,
    .xl\:focus\:text-outline:focus,
    .xl\:checked\:text-outline:checked {
        --tw-text-opacity: 1;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!xl\:text-outline,
    .\!xl\:hover\:text-outline:hover,
    .\!xl\:focus\:text-outline:focus,
    .\!xl\:checked\:text-outline:checked {
        --tw-text-opacity: 1;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-outline,
    .xl\:hover\:bg-outline:hover,
    .xl\:focus\:bg-outline:focus,
    .xl\:checked\:bg-outline:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!xl\:bg-outline,
    .\!xl\:hover\:bg-outline:hover,
    .\!xl\:focus\:bg-outline:focus,
    .\!xl\:checked\:bg-outline:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-outline,
    .xl\:hover\:border-outline:hover,
    .xl\:focus\:border-outline:focus,
    .xl\:checked\:border-outline:checked {
        --tw-border-opacity: 1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!xl\:border-outline,
    .\!xl\:hover\:border-outline:hover,
    .\!xl\:focus\:border-outline:focus,
    .\!xl\:checked\:border-outline:checked {
        --tw-border-opacity: 1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-outline,
    .xl\:hover\:ring-outline:hover,
    .xl\:focus\:ring-outline:focus,
    .xl\:checked\:ring-outline:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!xl\:ring-outline,
    .\!xl\:hover\:ring-outline:hover,
    .\!xl\:focus\:ring-outline:focus,
    .\!xl\:checked\:ring-outline:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-outline,
    .\2xl\:hover\:text-outline:hover,
    .\2xl\:focus\:text-outline:focus,
    .\2xl\:checked\:text-outline:checked {
        --tw-text-opacity: 1;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-outline,
    .\!2xlsm\:hover\:text-outline:hover,
    .\!2xlsm\:focus\:text-outline:focus,
    .\!2xlsm\:checked\:text-outline:checked {
        --tw-text-opacity: 1;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-outline,
    .\2xl\:hover\:bg-outline:hover,
    .\2xl\:focus\:bg-outline:focus,
    .\2xl\:checked\:bg-outline:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-outline,
    .\!2xlsm\:hover\:bg-outline:hover,
    .\!2xlsm\:focus\:bg-outline:focus,
    .\!2xlsm\:checked\:bg-outline:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-outline,
    .\2xl\:hover\:border-outline:hover,
    .\2xl\:focus\:border-outline:focus,
    .\2xl\:checked\:border-outline:checked {
        --tw-border-opacity: 1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-outline,
    .\!2xlsm\:hover\:border-outline:hover,
    .\!2xlsm\:focus\:border-outline:focus,
    .\!2xlsm\:checked\:border-outline:checked {
        --tw-border-opacity: 1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-outline,
    .\2xl\:hover\:ring-outline:hover,
    .\2xl\:focus\:ring-outline:focus,
    .\2xl\:checked\:ring-outline:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-outline,
    .\!2xl\:hover\:ring-outline:hover,
    .\!2xl\:focus\:ring-outline:focus,
    .\!2xl\:checked\:ring-outline:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

//outline-variant
.text-outline-variant,
.hover\:text-outline-variant:hover,
.focus\:text-outline-variant:focus,
.checked\:text-outline-variant:checked {
    --tw-text-opacity: 1;
    color: rgb(215, 195, 183, var(--tw-text-opacity));
}

.\!text-outline-variant,
.\!hover\:text-outline-variant:hover,
.\!focus\:text-outline-variant:focus,
.\!checked\:text-outline-variant:checked {
    --tw-text-opacity: 1;
    color: rgb(215, 195, 183, var(--tw-text-opacity)) !important;
}

.bg-outline-variant,
.hover\:bg-outline-variant:hover,
.focus\:bg-outline-variant:focus,
.checked\:bg-outline-variant:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(215, 195, 183, var(--tw-bg-opacity));
}

.\!bg-outline-variant,
.\!hover\:bg-outline-variant:hover,
.\!focus\:bg-outline-variant:focus,
.\!checked\:bg-outline-variant:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(215, 195, 183, var(--tw-bg-opacity)) !important;
}

.border-outline-variant,
.hover\:border-outline-variant:hover,
.focus\:border-outline-variant:focus,
.checked\:border-outline-variant:checked {
    --tw-border-opacity: 1;
    border-color: rgb(215, 195, 183, var(--tw-border-opacity));
}

.\!border-outline-variant,
.\!hover\:border-outline-variant:hover,
.\!focus\:border-outline-variant:focus,
.\!checked\:border-outline-variant:checked {
    --tw-border-opacity: 1;
    border-color: rgb(215, 195, 183, var(--tw-border-opacity)) !important;
}

.ring-outline-variant,
.hover\:ring-outline-variant:hover,
.focus\:ring-outline-variant:focus,
.checked\:ring-outline-variant:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(215, 195, 183, var(--tw-ring-opacity));
}

.\!ring-outline-variant,
.\!hover\:ring-outline-variant:hover,
.\!focus\:ring-outline-variant:focus,
.\!checked\:ring-outline-variant:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(215, 195, 183, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-outline-variant,
    .sm\:hover\:text-outline-variant:hover,
    .sm\:focus\:text-outline-variant:focus,
    .sm\:checked\:text-outline-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(215, 195, 183, var(--tw-text-opacity));
    }

    .\!sm\:text-outline-variant,
    .\!sm\:hover\:text-outline-variant:hover,
    .\!sm\:focus\:text-outline-variant:focus,
    .\!sm\:checked\:text-outline-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(215, 195, 183, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-outline-variant,
    .sm\:hover\:bg-outline-variant:hover,
    .sm\:focus\:bg-outline-variant:focus,
    .sm\:checked\:bg-outline-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(215, 195, 183, var(--tw-bg-opacity));
    }

    .\!sm\:bg-outline-variant,
    .\!sm\:hover\:bg-outline-variant:hover,
    .\!sm\:focus\:bg-outline-variant:focus,
    .\!sm\:checked\:bg-outline-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(215, 195, 183, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-outline-variant,
    .sm\:hover\:border-outline-variant:hover,
    .sm\:focus\:border-outline-variant:focus,
    .sm\:checked\:border-outline-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(215, 195, 183, var(--tw-border-opacity));
    }

    .\!sm\:border-outline-variant,
    .\!sm\:hover\:border-outline-variant:hover,
    .\!sm\:focus\:border-outline-variant:focus,
    .\!sm\:checked\:border-outline-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(215, 195, 183, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-outline-variant,
    .sm\:hover\:ring-outline-variant:hover,
    .sm\:focus\:ring-outline-variant:focus,
    .sm\:checked\:ring-outline-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(215, 195, 183, var(--tw-ring-opacity));
    }

    .\!sm\:ring-outline-variant,
    .\!sm\:hover\:ring-outline-variant:hover,
    .\!sm\:focus\:ring-outline-variant:focus,
    .\!sm\:checked\:ring-outline-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(215, 195, 183, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-outline-variant,
    .md\:hover\:text-outline-variant:hover,
    .md\:focus\:text-outline-variant:focus,
    .md\:checked\:text-outline-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(215, 195, 183, var(--tw-text-opacity));
    }

    .\!md\:text-outline-variant,
    .\!md\:hover\:text-outline-variant:hover,
    .\!md\:focus\:text-outline-variant:focus,
    .\!md\:checked\:text-outline-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(215, 195, 183, var(--tw-text-opacity)) !important;
    }

    .md\:bg-outline-variant,
    .md\:hover\:bg-outline-variant:hover,
    .md\:focus\:bg-outline-variant:focus,
    .md\:checked\:bg-outline-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(215, 195, 183, var(--tw-bg-opacity));
    }

    .\!md\:bg-outline-variant,
    .\!md\:hover\:bg-outline-variant:hover,
    .\!md\:focus\:bg-outline-variant:focus,
    .\!md\:checked\:bg-outline-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(215, 195, 183, var(--tw-bg-opacity)) !important;
    }

    .md\:border-outline-variant,
    .md\:hover\:border-outline-variant:hover,
    .md\:focus\:border-outline-variant:focus,
    .md\:checked\:border-outline-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(215, 195, 183, var(--tw-border-opacity));
    }

    .\!md\:border-outline-variant,
    .\!md\:hover\:border-outline-variant:hover,
    .\!md\:focus\:border-outline-variant:focus,
    .\!md\:checked\:border-outline-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(215, 195, 183, var(--tw-border-opacity)) !important;
    }

    .md\:ring-outline-variant,
    .md\:hover\:ring-outline-variant:hover,
    .md\:focus\:ring-outline-variant:focus,
    .md\:checked\:ring-outline-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(215, 195, 183, var(--tw-ring-opacity));
    }

    .\!md\:ring-outline-variant,
    .\!md\:hover\:ring-outline-variant:hover,
    .\!md\:focus\:ring-outline-variant:focus,
    .\!md\:checked\:ring-outline-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(215, 195, 183, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-outline-variant,
    .lg\:hover\:text-outline-variant:hover,
    .lg\:focus\:text-outline-variant:focus,
    .lg\:checked\:text-outline-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(215, 195, 183, var(--tw-text-opacity));
    }

    .\!lg\:text-outline-variant,
    .\!lg\:hover\:text-outline-variant:hover,
    .\!lg\:focus\:text-outline-variant:focus,
    .\!lg\:checked\:text-outline-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(215, 195, 183, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-outline-variant,
    .lg\:hover\:bg-outline-variant:hover,
    .lg\:focus\:bg-outline-variant:focus,
    .lg\:checked\:bg-outline-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(215, 195, 183, var(--tw-bg-opacity));
    }

    .\!lg\:bg-outline-variant,
    .\!lg\:hover\:bg-outline-variant:hover,
    .\!lg\:focus\:bg-outline-variant:focus,
    .\!lg\:checked\:bg-outline-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(215, 195, 183, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-outline-variant,
    .lg\:hover\:border-outline-variant:hover,
    .lg\:focus\:border-outline-variant:focus,
    .lg\:checked\:border-outline-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(215, 195, 183, var(--tw-border-opacity));
    }

    .\!lg\:border-outline-variant,
    .\!lg\:hover\:border-outline-variant:hover,
    .\!lg\:focus\:border-outline-variant:focus,
    .\!lg\:checked\:border-outline-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(215, 195, 183, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-outline-variant,
    .lg\:hover\:ring-outline-variant:hover,
    .lg\:focus\:ring-outline-variant:focus,
    .lg\:checked\:ring-outline-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(215, 195, 183, var(--tw-ring-opacity));
    }

    .\!lg\:ring-outline-variant,
    .\!lg\:hover\:ring-outline-variant:hover,
    .\!lg\:focus\:ring-outline-variant:focus,
    .\!lg\:checked\:ring-outline-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(215, 195, 183, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-outline-variant,
    .xl\:hover\:text-outline-variant:hover,
    .xl\:focus\:text-outline-variant:focus,
    .xl\:checked\:text-outline-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(215, 195, 183, var(--tw-text-opacity));
    }

    .\!xl\:text-outline-variant,
    .\!xl\:hover\:text-outline-variant:hover,
    .\!xl\:focus\:text-outline-variant:focus,
    .\!xl\:checked\:text-outline-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(215, 195, 183, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-outline-variant,
    .xl\:hover\:bg-outline-variant:hover,
    .xl\:focus\:bg-outline-variant:focus,
    .xl\:checked\:bg-outline-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(215, 195, 183, var(--tw-bg-opacity));
    }

    .\!xl\:bg-outline-variant,
    .\!xl\:hover\:bg-outline-variant:hover,
    .\!xl\:focus\:bg-outline-variant:focus,
    .\!xl\:checked\:bg-outline-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(215, 195, 183, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-outline-variant,
    .xl\:hover\:border-outline-variant:hover,
    .xl\:focus\:border-outline-variant:focus,
    .xl\:checked\:border-outline-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(215, 195, 183, var(--tw-border-opacity));
    }

    .\!xl\:border-outline-variant,
    .\!xl\:hover\:border-outline-variant:hover,
    .\!xl\:focus\:border-outline-variant:focus,
    .\!xl\:checked\:border-outline-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(215, 195, 183, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-outline-variant,
    .xl\:hover\:ring-outline-variant:hover,
    .xl\:focus\:ring-outline-variant:focus,
    .xl\:checked\:ring-outline-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(215, 195, 183, var(--tw-ring-opacity));
    }

    .\!xl\:ring-outline-variant,
    .\!xl\:hover\:ring-outline-variant:hover,
    .\!xl\:focus\:ring-outline-variant:focus,
    .\!xl\:checked\:ring-outline-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(215, 195, 183, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-outline-variant,
    .\2xl\:hover\:text-outline-variant:hover,
    .\2xl\:focus\:text-outline-variant:focus,
    .\2xl\:checked\:text-outline-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(215, 195, 183, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-outline-variant,
    .\!2xlsm\:hover\:text-outline-variant:hover,
    .\!2xlsm\:focus\:text-outline-variant:focus,
    .\!2xlsm\:checked\:text-outline-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(215, 195, 183, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-outline-variant,
    .\2xl\:hover\:bg-outline-variant:hover,
    .\2xl\:focus\:bg-outline-variant:focus,
    .\2xl\:checked\:bg-outline-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(215, 195, 183, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-outline-variant,
    .\!2xlsm\:hover\:bg-outline-variant:hover,
    .\!2xlsm\:focus\:bg-outline-variant:focus,
    .\!2xlsm\:checked\:bg-outline-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(215, 195, 183, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-outline-variant,
    .\2xl\:hover\:border-outline-variant:hover,
    .\2xl\:focus\:border-outline-variant:focus,
    .\2xl\:checked\:border-outline-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(215, 195, 183, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-outline-variant,
    .\!2xlsm\:hover\:border-outline-variant:hover,
    .\!2xlsm\:focus\:border-outline-variant:focus,
    .\!2xlsm\:checked\:border-outline-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(215, 195, 183, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-outline-variant,
    .\2xl\:hover\:ring-outline-variant:hover,
    .\2xl\:focus\:ring-outline-variant:focus,
    .\2xl\:checked\:ring-outline-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(215, 195, 183, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-outline-variant,
    .\!2xl\:hover\:ring-outline-variant:hover,
    .\!2xl\:focus\:ring-outline-variant:focus,
    .\!2xl\:checked\:ring-outline-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(215, 195, 183, var(--tw-ring-opacity)) !important;
    }
}

//background
.text-background,
.hover\:text-background:hover,
.focus\:text-background:focus,
.checked\:text-background:checked {
    --tw-text-opacity: 1;
    color: rgb(255, 251, 255, var(--tw-text-opacity));
}

.\!text-background,
.\!hover\:text-background:hover,
.\!focus\:text-background:focus,
.\!checked\:text-background:checked {
    --tw-text-opacity: 1;
    color: rgb(255, 251, 255, var(--tw-text-opacity)) !important;
}

.bg-background,
.hover\:bg-background:hover,
.focus\:bg-background:focus,
.checked\:bg-background:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(255, 251, 255, var(--tw-bg-opacity));
}

.\!bg-background,
.\!hover\:bg-background:hover,
.\!focus\:bg-background:focus,
.\!checked\:bg-background:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(255, 251, 255, var(--tw-bg-opacity)) !important;
}

.border-background,
.hover\:border-background:hover,
.focus\:border-background:focus,
.checked\:border-background:checked {
    --tw-border-opacity: 1;
    border-color: rgb(255, 251, 255, var(--tw-border-opacity));
}

.\!border-background,
.\!hover\:border-background:hover,
.\!focus\:border-background:focus,
.\!checked\:border-background:checked {
    --tw-border-opacity: 1;
    border-color: rgb(255, 251, 255, var(--tw-border-opacity)) !important;
}

.ring-background,
.hover\:ring-background:hover,
.focus\:ring-background:focus,
.checked\:ring-background:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255, 251, 255, var(--tw-ring-opacity));
}

.\!ring-background,
.\!hover\:ring-background:hover,
.\!focus\:ring-background:focus,
.\!checked\:ring-background:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255, 251, 255, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-background,
    .sm\:hover\:text-background:hover,
    .sm\:focus\:text-background:focus,
    .sm\:checked\:text-background:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 251, 255, var(--tw-text-opacity));
    }

    .\!sm\:text-background,
    .\!sm\:hover\:text-background:hover,
    .\!sm\:focus\:text-background:focus,
    .\!sm\:checked\:text-background:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 251, 255, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-background,
    .sm\:hover\:bg-background:hover,
    .sm\:focus\:bg-background:focus,
    .sm\:checked\:bg-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 251, 255, var(--tw-bg-opacity));
    }

    .\!sm\:bg-background,
    .\!sm\:hover\:bg-background:hover,
    .\!sm\:focus\:bg-background:focus,
    .\!sm\:checked\:bg-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 251, 255, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-background,
    .sm\:hover\:border-background:hover,
    .sm\:focus\:border-background:focus,
    .sm\:checked\:border-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 251, 255, var(--tw-border-opacity));
    }

    .\!sm\:border-background,
    .\!sm\:hover\:border-background:hover,
    .\!sm\:focus\:border-background:focus,
    .\!sm\:checked\:border-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 251, 255, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-background,
    .sm\:hover\:ring-background:hover,
    .sm\:focus\:ring-background:focus,
    .sm\:checked\:ring-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 251, 255, var(--tw-ring-opacity));
    }

    .\!sm\:ring-background,
    .\!sm\:hover\:ring-background:hover,
    .\!sm\:focus\:ring-background:focus,
    .\!sm\:checked\:ring-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 251, 255, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-background,
    .md\:hover\:text-background:hover,
    .md\:focus\:text-background:focus,
    .md\:checked\:text-background:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 251, 255, var(--tw-text-opacity));
    }

    .\!md\:text-background,
    .\!md\:hover\:text-background:hover,
    .\!md\:focus\:text-background:focus,
    .\!md\:checked\:text-background:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 251, 255, var(--tw-text-opacity)) !important;
    }

    .md\:bg-background,
    .md\:hover\:bg-background:hover,
    .md\:focus\:bg-background:focus,
    .md\:checked\:bg-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 251, 255, var(--tw-bg-opacity));
    }

    .\!md\:bg-background,
    .\!md\:hover\:bg-background:hover,
    .\!md\:focus\:bg-background:focus,
    .\!md\:checked\:bg-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 251, 255, var(--tw-bg-opacity)) !important;
    }

    .md\:border-background,
    .md\:hover\:border-background:hover,
    .md\:focus\:border-background:focus,
    .md\:checked\:border-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 251, 255, var(--tw-border-opacity));
    }

    .\!md\:border-background,
    .\!md\:hover\:border-background:hover,
    .\!md\:focus\:border-background:focus,
    .\!md\:checked\:border-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 251, 255, var(--tw-border-opacity)) !important;
    }

    .md\:ring-background,
    .md\:hover\:ring-background:hover,
    .md\:focus\:ring-background:focus,
    .md\:checked\:ring-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 251, 255, var(--tw-ring-opacity));
    }

    .\!md\:ring-background,
    .\!md\:hover\:ring-background:hover,
    .\!md\:focus\:ring-background:focus,
    .\!md\:checked\:ring-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 251, 255, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-background,
    .lg\:hover\:text-background:hover,
    .lg\:focus\:text-background:focus,
    .lg\:checked\:text-background:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 251, 255, var(--tw-text-opacity));
    }

    .\!lg\:text-background,
    .\!lg\:hover\:text-background:hover,
    .\!lg\:focus\:text-background:focus,
    .\!lg\:checked\:text-background:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 251, 255, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-background,
    .lg\:hover\:bg-background:hover,
    .lg\:focus\:bg-background:focus,
    .lg\:checked\:bg-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 251, 255, var(--tw-bg-opacity));
    }

    .\!lg\:bg-background,
    .\!lg\:hover\:bg-background:hover,
    .\!lg\:focus\:bg-background:focus,
    .\!lg\:checked\:bg-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 251, 255, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-background,
    .lg\:hover\:border-background:hover,
    .lg\:focus\:border-background:focus,
    .lg\:checked\:border-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 251, 255, var(--tw-border-opacity));
    }

    .\!lg\:border-background,
    .\!lg\:hover\:border-background:hover,
    .\!lg\:focus\:border-background:focus,
    .\!lg\:checked\:border-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 251, 255, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-background,
    .lg\:hover\:ring-background:hover,
    .lg\:focus\:ring-background:focus,
    .lg\:checked\:ring-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 251, 255, var(--tw-ring-opacity));
    }

    .\!lg\:ring-background,
    .\!lg\:hover\:ring-background:hover,
    .\!lg\:focus\:ring-background:focus,
    .\!lg\:checked\:ring-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 251, 255, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-background,
    .xl\:hover\:text-background:hover,
    .xl\:focus\:text-background:focus,
    .xl\:checked\:text-background:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 251, 255, var(--tw-text-opacity));
    }

    .\!xl\:text-background,
    .\!xl\:hover\:text-background:hover,
    .\!xl\:focus\:text-background:focus,
    .\!xl\:checked\:text-background:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 251, 255, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-background,
    .xl\:hover\:bg-background:hover,
    .xl\:focus\:bg-background:focus,
    .xl\:checked\:bg-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 251, 255, var(--tw-bg-opacity));
    }

    .\!xl\:bg-background,
    .\!xl\:hover\:bg-background:hover,
    .\!xl\:focus\:bg-background:focus,
    .\!xl\:checked\:bg-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 251, 255, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-background,
    .xl\:hover\:border-background:hover,
    .xl\:focus\:border-background:focus,
    .xl\:checked\:border-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 251, 255, var(--tw-border-opacity));
    }

    .\!xl\:border-background,
    .\!xl\:hover\:border-background:hover,
    .\!xl\:focus\:border-background:focus,
    .\!xl\:checked\:border-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 251, 255, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-background,
    .xl\:hover\:ring-background:hover,
    .xl\:focus\:ring-background:focus,
    .xl\:checked\:ring-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 251, 255, var(--tw-ring-opacity));
    }

    .\!xl\:ring-background,
    .\!xl\:hover\:ring-background:hover,
    .\!xl\:focus\:ring-background:focus,
    .\!xl\:checked\:ring-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 251, 255, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-background,
    .\2xl\:hover\:text-background:hover,
    .\2xl\:focus\:text-background:focus,
    .\2xl\:checked\:text-background:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 251, 255, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-background,
    .\!2xlsm\:hover\:text-background:hover,
    .\!2xlsm\:focus\:text-background:focus,
    .\!2xlsm\:checked\:text-background:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 251, 255, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-background,
    .\2xl\:hover\:bg-background:hover,
    .\2xl\:focus\:bg-background:focus,
    .\2xl\:checked\:bg-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 251, 255, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-background,
    .\!2xlsm\:hover\:bg-background:hover,
    .\!2xlsm\:focus\:bg-background:focus,
    .\!2xlsm\:checked\:bg-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 251, 255, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-background,
    .\2xl\:hover\:border-background:hover,
    .\2xl\:focus\:border-background:focus,
    .\2xl\:checked\:border-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 251, 255, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-background,
    .\!2xlsm\:hover\:border-background:hover,
    .\!2xlsm\:focus\:border-background:focus,
    .\!2xlsm\:checked\:border-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 251, 255, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-background,
    .\2xl\:hover\:ring-background:hover,
    .\2xl\:focus\:ring-background:focus,
    .\2xl\:checked\:ring-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 251, 255, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-background,
    .\!2xl\:hover\:ring-background:hover,
    .\!2xl\:focus\:ring-background:focus,
    .\!2xl\:checked\:ring-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 251, 255, var(--tw-ring-opacity)) !important;
    }
}

//on-background
.text-on-background,
.hover\:text-on-background:hover,
.focus\:text-on-background:focus,
.checked\:text-on-background:checked {
    --tw-text-opacity: 1;
    color: rgb(65, 0, 5, var(--tw-text-opacity));
}

.\!text-on-background,
.\!hover\:text-on-background:hover,
.\!focus\:text-on-background:focus,
.\!checked\:text-on-background:checked {
    --tw-text-opacity: 1;
    color: rgb(65, 0, 5, var(--tw-text-opacity)) !important;
}

.bg-on-background,
.hover\:bg-on-background:hover,
.focus\:bg-on-background:focus,
.checked\:bg-on-background:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(65, 0, 5, var(--tw-bg-opacity));
}

.\!bg-on-background,
.\!hover\:bg-on-background:hover,
.\!focus\:bg-on-background:focus,
.\!checked\:bg-on-background:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(65, 0, 5, var(--tw-bg-opacity)) !important;
}

.border-on-background,
.hover\:border-on-background:hover,
.focus\:border-on-background:focus,
.checked\:border-on-background:checked {
    --tw-border-opacity: 1;
    border-color: rgb(65, 0, 5, var(--tw-border-opacity));
}

.\!border-on-background,
.\!hover\:border-on-background:hover,
.\!focus\:border-on-background:focus,
.\!checked\:border-on-background:checked {
    --tw-border-opacity: 1;
    border-color: rgb(65, 0, 5, var(--tw-border-opacity)) !important;
}

.ring-on-background,
.hover\:ring-on-background:hover,
.focus\:ring-on-background:focus,
.checked\:ring-on-background:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(65, 0, 5, var(--tw-ring-opacity));
}

.\!ring-on-background,
.\!hover\:ring-on-background:hover,
.\!focus\:ring-on-background:focus,
.\!checked\:ring-on-background:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(65, 0, 5, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-on-background,
    .sm\:hover\:text-on-background:hover,
    .sm\:focus\:text-on-background:focus,
    .sm\:checked\:text-on-background:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 5, var(--tw-text-opacity));
    }

    .\!sm\:text-on-background,
    .\!sm\:hover\:text-on-background:hover,
    .\!sm\:focus\:text-on-background:focus,
    .\!sm\:checked\:text-on-background:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 5, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-on-background,
    .sm\:hover\:bg-on-background:hover,
    .sm\:focus\:bg-on-background:focus,
    .sm\:checked\:bg-on-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 5, var(--tw-bg-opacity));
    }

    .\!sm\:bg-on-background,
    .\!sm\:hover\:bg-on-background:hover,
    .\!sm\:focus\:bg-on-background:focus,
    .\!sm\:checked\:bg-on-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 5, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-on-background,
    .sm\:hover\:border-on-background:hover,
    .sm\:focus\:border-on-background:focus,
    .sm\:checked\:border-on-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 5, var(--tw-border-opacity));
    }

    .\!sm\:border-on-background,
    .\!sm\:hover\:border-on-background:hover,
    .\!sm\:focus\:border-on-background:focus,
    .\!sm\:checked\:border-on-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 5, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-on-background,
    .sm\:hover\:ring-on-background:hover,
    .sm\:focus\:ring-on-background:focus,
    .sm\:checked\:ring-on-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 5, var(--tw-ring-opacity));
    }

    .\!sm\:ring-on-background,
    .\!sm\:hover\:ring-on-background:hover,
    .\!sm\:focus\:ring-on-background:focus,
    .\!sm\:checked\:ring-on-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 5, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-on-background,
    .md\:hover\:text-on-background:hover,
    .md\:focus\:text-on-background:focus,
    .md\:checked\:text-on-background:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 5, var(--tw-text-opacity));
    }

    .\!md\:text-on-background,
    .\!md\:hover\:text-on-background:hover,
    .\!md\:focus\:text-on-background:focus,
    .\!md\:checked\:text-on-background:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 5, var(--tw-text-opacity)) !important;
    }

    .md\:bg-on-background,
    .md\:hover\:bg-on-background:hover,
    .md\:focus\:bg-on-background:focus,
    .md\:checked\:bg-on-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 5, var(--tw-bg-opacity));
    }

    .\!md\:bg-on-background,
    .\!md\:hover\:bg-on-background:hover,
    .\!md\:focus\:bg-on-background:focus,
    .\!md\:checked\:bg-on-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 5, var(--tw-bg-opacity)) !important;
    }

    .md\:border-on-background,
    .md\:hover\:border-on-background:hover,
    .md\:focus\:border-on-background:focus,
    .md\:checked\:border-on-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 5, var(--tw-border-opacity));
    }

    .\!md\:border-on-background,
    .\!md\:hover\:border-on-background:hover,
    .\!md\:focus\:border-on-background:focus,
    .\!md\:checked\:border-on-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 5, var(--tw-border-opacity)) !important;
    }

    .md\:ring-on-background,
    .md\:hover\:ring-on-background:hover,
    .md\:focus\:ring-on-background:focus,
    .md\:checked\:ring-on-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 5, var(--tw-ring-opacity));
    }

    .\!md\:ring-on-background,
    .\!md\:hover\:ring-on-background:hover,
    .\!md\:focus\:ring-on-background:focus,
    .\!md\:checked\:ring-on-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 5, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-on-background,
    .lg\:hover\:text-on-background:hover,
    .lg\:focus\:text-on-background:focus,
    .lg\:checked\:text-on-background:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 5, var(--tw-text-opacity));
    }

    .\!lg\:text-on-background,
    .\!lg\:hover\:text-on-background:hover,
    .\!lg\:focus\:text-on-background:focus,
    .\!lg\:checked\:text-on-background:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 5, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-on-background,
    .lg\:hover\:bg-on-background:hover,
    .lg\:focus\:bg-on-background:focus,
    .lg\:checked\:bg-on-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 5, var(--tw-bg-opacity));
    }

    .\!lg\:bg-on-background,
    .\!lg\:hover\:bg-on-background:hover,
    .\!lg\:focus\:bg-on-background:focus,
    .\!lg\:checked\:bg-on-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 5, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-on-background,
    .lg\:hover\:border-on-background:hover,
    .lg\:focus\:border-on-background:focus,
    .lg\:checked\:border-on-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 5, var(--tw-border-opacity));
    }

    .\!lg\:border-on-background,
    .\!lg\:hover\:border-on-background:hover,
    .\!lg\:focus\:border-on-background:focus,
    .\!lg\:checked\:border-on-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 5, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-on-background,
    .lg\:hover\:ring-on-background:hover,
    .lg\:focus\:ring-on-background:focus,
    .lg\:checked\:ring-on-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 5, var(--tw-ring-opacity));
    }

    .\!lg\:ring-on-background,
    .\!lg\:hover\:ring-on-background:hover,
    .\!lg\:focus\:ring-on-background:focus,
    .\!lg\:checked\:ring-on-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 5, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-on-background,
    .xl\:hover\:text-on-background:hover,
    .xl\:focus\:text-on-background:focus,
    .xl\:checked\:text-on-background:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 5, var(--tw-text-opacity));
    }

    .\!xl\:text-on-background,
    .\!xl\:hover\:text-on-background:hover,
    .\!xl\:focus\:text-on-background:focus,
    .\!xl\:checked\:text-on-background:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 5, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-on-background,
    .xl\:hover\:bg-on-background:hover,
    .xl\:focus\:bg-on-background:focus,
    .xl\:checked\:bg-on-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 5, var(--tw-bg-opacity));
    }

    .\!xl\:bg-on-background,
    .\!xl\:hover\:bg-on-background:hover,
    .\!xl\:focus\:bg-on-background:focus,
    .\!xl\:checked\:bg-on-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 5, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-on-background,
    .xl\:hover\:border-on-background:hover,
    .xl\:focus\:border-on-background:focus,
    .xl\:checked\:border-on-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 5, var(--tw-border-opacity));
    }

    .\!xl\:border-on-background,
    .\!xl\:hover\:border-on-background:hover,
    .\!xl\:focus\:border-on-background:focus,
    .\!xl\:checked\:border-on-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 5, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-on-background,
    .xl\:hover\:ring-on-background:hover,
    .xl\:focus\:ring-on-background:focus,
    .xl\:checked\:ring-on-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 5, var(--tw-ring-opacity));
    }

    .\!xl\:ring-on-background,
    .\!xl\:hover\:ring-on-background:hover,
    .\!xl\:focus\:ring-on-background:focus,
    .\!xl\:checked\:ring-on-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 5, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-on-background,
    .\2xl\:hover\:text-on-background:hover,
    .\2xl\:focus\:text-on-background:focus,
    .\2xl\:checked\:text-on-background:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 5, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-on-background,
    .\!2xlsm\:hover\:text-on-background:hover,
    .\!2xlsm\:focus\:text-on-background:focus,
    .\!2xlsm\:checked\:text-on-background:checked {
        --tw-text-opacity: 1;
        color: rgb(65, 0, 5, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-on-background,
    .\2xl\:hover\:bg-on-background:hover,
    .\2xl\:focus\:bg-on-background:focus,
    .\2xl\:checked\:bg-on-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 5, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-on-background,
    .\!2xlsm\:hover\:bg-on-background:hover,
    .\!2xlsm\:focus\:bg-on-background:focus,
    .\!2xlsm\:checked\:bg-on-background:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(65, 0, 5, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-on-background,
    .\2xl\:hover\:border-on-background:hover,
    .\2xl\:focus\:border-on-background:focus,
    .\2xl\:checked\:border-on-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 5, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-on-background,
    .\!2xlsm\:hover\:border-on-background:hover,
    .\!2xlsm\:focus\:border-on-background:focus,
    .\!2xlsm\:checked\:border-on-background:checked {
        --tw-border-opacity: 1;
        border-color: rgb(65, 0, 5, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-on-background,
    .\2xl\:hover\:ring-on-background:hover,
    .\2xl\:focus\:ring-on-background:focus,
    .\2xl\:checked\:ring-on-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 5, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-on-background,
    .\!2xl\:hover\:ring-on-background:hover,
    .\!2xl\:focus\:ring-on-background:focus,
    .\!2xl\:checked\:ring-on-background:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(65, 0, 5, var(--tw-ring-opacity)) !important;
    }
}

//hover-5
.text-hover-5,
.hover\:text-hover-5:hover,
.focus\:text-hover-5:focus,
.checked\:text-hover-5:checked {
    --tw-text-opacity: 0.05;
    color: rgb(132, 116, 106, var(--tw-text-opacity));
}

.\!text-hover-5,
.\!hover\:text-hover-5:hover,
.\!focus\:text-hover-5:focus,
.\!checked\:text-hover-5:checked {
    --tw-text-opacity: 0.05;
    color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
}

.bg-hover-5,
.hover\:bg-hover-5:hover,
.focus\:bg-hover-5:focus,
.checked\:bg-hover-5:checked {
    --tw-bg-opacity: 0.05;
    background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
}

.\!bg-hover-5,
.\!hover\:bg-hover-5:hover,
.\!focus\:bg-hover-5:focus,
.\!checked\:bg-hover-5:checked {
    --tw-bg-opacity: 0.05;
    background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
}

.border-hover-5,
.hover\:border-hover-5:hover,
.focus\:border-hover-5:focus,
.checked\:border-hover-5:checked {
    --tw-border-opacity: 0.05;
    border-color: rgb(132, 116, 106, var(--tw-border-opacity));
}

.\!border-hover-5,
.\!hover\:border-hover-5:hover,
.\!focus\:border-hover-5:focus,
.\!checked\:border-hover-5:checked {
    --tw-border-opacity: 0.05;
    border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
}

.ring-hover-5,
.hover\:ring-hover-5:hover,
.focus\:ring-hover-5:focus,
.checked\:ring-hover-5:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
}

.\!ring-hover-5,
.\!hover\:ring-hover-5:hover,
.\!focus\:ring-hover-5:focus,
.\!checked\:ring-hover-5:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-hover-5,
    .sm\:hover\:text-hover-5:hover,
    .sm\:focus\:text-hover-5:focus,
    .sm\:checked\:text-hover-5:checked {
        --tw-text-opacity: 0.05;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!sm\:text-hover-5,
    .\!sm\:hover\:text-hover-5:hover,
    .\!sm\:focus\:text-hover-5:focus,
    .\!sm\:checked\:text-hover-5:checked {
        --tw-text-opacity: 0.05;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-hover-5,
    .sm\:hover\:bg-hover-5:hover,
    .sm\:focus\:bg-hover-5:focus,
    .sm\:checked\:bg-hover-5:checked {
        --tw-bg-opacity: 0.05;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!sm\:bg-hover-5,
    .\!sm\:hover\:bg-hover-5:hover,
    .\!sm\:focus\:bg-hover-5:focus,
    .\!sm\:checked\:bg-hover-5:checked {
        --tw-bg-opacity: 0.05;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-hover-5,
    .sm\:hover\:border-hover-5:hover,
    .sm\:focus\:border-hover-5:focus,
    .sm\:checked\:border-hover-5:checked {
        --tw-border-opacity: 0.05;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!sm\:border-hover-5,
    .\!sm\:hover\:border-hover-5:hover,
    .\!sm\:focus\:border-hover-5:focus,
    .\!sm\:checked\:border-hover-5:checked {
        --tw-border-opacity: 0.05;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-hover-5,
    .sm\:hover\:ring-hover-5:hover,
    .sm\:focus\:ring-hover-5:focus,
    .sm\:checked\:ring-hover-5:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!sm\:ring-hover-5,
    .\!sm\:hover\:ring-hover-5:hover,
    .\!sm\:focus\:ring-hover-5:focus,
    .\!sm\:checked\:ring-hover-5:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-hover-5,
    .md\:hover\:text-hover-5:hover,
    .md\:focus\:text-hover-5:focus,
    .md\:checked\:text-hover-5:checked {
        --tw-text-opacity: 0.05;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!md\:text-hover-5,
    .\!md\:hover\:text-hover-5:hover,
    .\!md\:focus\:text-hover-5:focus,
    .\!md\:checked\:text-hover-5:checked {
        --tw-text-opacity: 0.05;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .md\:bg-hover-5,
    .md\:hover\:bg-hover-5:hover,
    .md\:focus\:bg-hover-5:focus,
    .md\:checked\:bg-hover-5:checked {
        --tw-bg-opacity: 0.05;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!md\:bg-hover-5,
    .\!md\:hover\:bg-hover-5:hover,
    .\!md\:focus\:bg-hover-5:focus,
    .\!md\:checked\:bg-hover-5:checked {
        --tw-bg-opacity: 0.05;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .md\:border-hover-5,
    .md\:hover\:border-hover-5:hover,
    .md\:focus\:border-hover-5:focus,
    .md\:checked\:border-hover-5:checked {
        --tw-border-opacity: 0.05;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!md\:border-hover-5,
    .\!md\:hover\:border-hover-5:hover,
    .\!md\:focus\:border-hover-5:focus,
    .\!md\:checked\:border-hover-5:checked {
        --tw-border-opacity: 0.05;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .md\:ring-hover-5,
    .md\:hover\:ring-hover-5:hover,
    .md\:focus\:ring-hover-5:focus,
    .md\:checked\:ring-hover-5:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!md\:ring-hover-5,
    .\!md\:hover\:ring-hover-5:hover,
    .\!md\:focus\:ring-hover-5:focus,
    .\!md\:checked\:ring-hover-5:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-hover-5,
    .lg\:hover\:text-hover-5:hover,
    .lg\:focus\:text-hover-5:focus,
    .lg\:checked\:text-hover-5:checked {
        --tw-text-opacity: 0.05;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!lg\:text-hover-5,
    .\!lg\:hover\:text-hover-5:hover,
    .\!lg\:focus\:text-hover-5:focus,
    .\!lg\:checked\:text-hover-5:checked {
        --tw-text-opacity: 0.05;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-hover-5,
    .lg\:hover\:bg-hover-5:hover,
    .lg\:focus\:bg-hover-5:focus,
    .lg\:checked\:bg-hover-5:checked {
        --tw-bg-opacity: 0.05;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!lg\:bg-hover-5,
    .\!lg\:hover\:bg-hover-5:hover,
    .\!lg\:focus\:bg-hover-5:focus,
    .\!lg\:checked\:bg-hover-5:checked {
        --tw-bg-opacity: 0.05;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-hover-5,
    .lg\:hover\:border-hover-5:hover,
    .lg\:focus\:border-hover-5:focus,
    .lg\:checked\:border-hover-5:checked {
        --tw-border-opacity: 0.05;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!lg\:border-hover-5,
    .\!lg\:hover\:border-hover-5:hover,
    .\!lg\:focus\:border-hover-5:focus,
    .\!lg\:checked\:border-hover-5:checked {
        --tw-border-opacity: 0.05;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-hover-5,
    .lg\:hover\:ring-hover-5:hover,
    .lg\:focus\:ring-hover-5:focus,
    .lg\:checked\:ring-hover-5:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!lg\:ring-hover-5,
    .\!lg\:hover\:ring-hover-5:hover,
    .\!lg\:focus\:ring-hover-5:focus,
    .\!lg\:checked\:ring-hover-5:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-hover-5,
    .xl\:hover\:text-hover-5:hover,
    .xl\:focus\:text-hover-5:focus,
    .xl\:checked\:text-hover-5:checked {
        --tw-text-opacity: 0.05;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!xl\:text-hover-5,
    .\!xl\:hover\:text-hover-5:hover,
    .\!xl\:focus\:text-hover-5:focus,
    .\!xl\:checked\:text-hover-5:checked {
        --tw-text-opacity: 0.05;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-hover-5,
    .xl\:hover\:bg-hover-5:hover,
    .xl\:focus\:bg-hover-5:focus,
    .xl\:checked\:bg-hover-5:checked {
        --tw-bg-opacity: 0.05;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!xl\:bg-hover-5,
    .\!xl\:hover\:bg-hover-5:hover,
    .\!xl\:focus\:bg-hover-5:focus,
    .\!xl\:checked\:bg-hover-5:checked {
        --tw-bg-opacity: 0.05;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-hover-5,
    .xl\:hover\:border-hover-5:hover,
    .xl\:focus\:border-hover-5:focus,
    .xl\:checked\:border-hover-5:checked {
        --tw-border-opacity: 0.05;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!xl\:border-hover-5,
    .\!xl\:hover\:border-hover-5:hover,
    .\!xl\:focus\:border-hover-5:focus,
    .\!xl\:checked\:border-hover-5:checked {
        --tw-border-opacity: 0.05;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-hover-5,
    .xl\:hover\:ring-hover-5:hover,
    .xl\:focus\:ring-hover-5:focus,
    .xl\:checked\:ring-hover-5:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!xl\:ring-hover-5,
    .\!xl\:hover\:ring-hover-5:hover,
    .\!xl\:focus\:ring-hover-5:focus,
    .\!xl\:checked\:ring-hover-5:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-hover-5,
    .\2xl\:hover\:text-hover-5:hover,
    .\2xl\:focus\:text-hover-5:focus,
    .\2xl\:checked\:text-hover-5:checked {
        --tw-text-opacity: 0.05;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-hover-5,
    .\!2xlsm\:hover\:text-hover-5:hover,
    .\!2xlsm\:focus\:text-hover-5:focus,
    .\!2xlsm\:checked\:text-hover-5:checked {
        --tw-text-opacity: 0.05;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-hover-5,
    .\2xl\:hover\:bg-hover-5:hover,
    .\2xl\:focus\:bg-hover-5:focus,
    .\2xl\:checked\:bg-hover-5:checked {
        --tw-bg-opacity: 0.05;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-hover-5,
    .\!2xlsm\:hover\:bg-hover-5:hover,
    .\!2xlsm\:focus\:bg-hover-5:focus,
    .\!2xlsm\:checked\:bg-hover-5:checked {
        --tw-bg-opacity: 0.05;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-hover-5,
    .\2xl\:hover\:border-hover-5:hover,
    .\2xl\:focus\:border-hover-5:focus,
    .\2xl\:checked\:border-hover-5:checked {
        --tw-border-opacity: 0.05;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-hover-5,
    .\!2xlsm\:hover\:border-hover-5:hover,
    .\!2xlsm\:focus\:border-hover-5:focus,
    .\!2xlsm\:checked\:border-hover-5:checked {
        --tw-border-opacity: 0.05;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-hover-5,
    .\2xl\:hover\:ring-hover-5:hover,
    .\2xl\:focus\:ring-hover-5:focus,
    .\2xl\:checked\:ring-hover-5:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-hover-5,
    .\!2xl\:hover\:ring-hover-5:hover,
    .\!2xl\:focus\:ring-hover-5:focus,
    .\!2xl\:checked\:ring-hover-5:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

//hover-10
.text-hover-10,
.hover\:text-hover-10:hover,
.focus\:text-hover-10:focus,
.checked\:text-hover-10:checked {
    --tw-text-opacity: 0.1;
    color: rgb(132, 116, 106, var(--tw-text-opacity));
}

.\!text-hover-10,
.\!hover\:text-hover-10:hover,
.\!focus\:text-hover-10:focus,
.\!checked\:text-hover-10:checked {
    --tw-text-opacity: 0.1;
    color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
}

.bg-hover-10,
.hover\:bg-hover-10:hover,
.focus\:bg-hover-10:focus,
.checked\:bg-hover-10:checked {
    --tw-bg-opacity: 0.1;
    background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
}

.\!bg-hover-10,
.\!hover\:bg-hover-10:hover,
.\!focus\:bg-hover-10:focus,
.\!checked\:bg-hover-10:checked {
    --tw-bg-opacity: 0.1;
    background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
}

.border-hover-10,
.hover\:border-hover-10:hover,
.focus\:border-hover-10:focus,
.checked\:border-hover-10:checked {
    --tw-border-opacity: 0.1;
    border-color: rgb(132, 116, 106, var(--tw-border-opacity));
}

.\!border-hover-10,
.\!hover\:border-hover-10:hover,
.\!focus\:border-hover-10:focus,
.\!checked\:border-hover-10:checked {
    --tw-border-opacity: 0.1;
    border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
}

.ring-hover-10,
.hover\:ring-hover-10:hover,
.focus\:ring-hover-10:focus,
.checked\:ring-hover-10:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
}

.\!ring-hover-10,
.\!hover\:ring-hover-10:hover,
.\!focus\:ring-hover-10:focus,
.\!checked\:ring-hover-10:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-hover-10,
    .sm\:hover\:text-hover-10:hover,
    .sm\:focus\:text-hover-10:focus,
    .sm\:checked\:text-hover-10:checked {
        --tw-text-opacity: 0.1;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!sm\:text-hover-10,
    .\!sm\:hover\:text-hover-10:hover,
    .\!sm\:focus\:text-hover-10:focus,
    .\!sm\:checked\:text-hover-10:checked {
        --tw-text-opacity: 0.1;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-hover-10,
    .sm\:hover\:bg-hover-10:hover,
    .sm\:focus\:bg-hover-10:focus,
    .sm\:checked\:bg-hover-10:checked {
        --tw-bg-opacity: 0.1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!sm\:bg-hover-10,
    .\!sm\:hover\:bg-hover-10:hover,
    .\!sm\:focus\:bg-hover-10:focus,
    .\!sm\:checked\:bg-hover-10:checked {
        --tw-bg-opacity: 0.1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-hover-10,
    .sm\:hover\:border-hover-10:hover,
    .sm\:focus\:border-hover-10:focus,
    .sm\:checked\:border-hover-10:checked {
        --tw-border-opacity: 0.1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!sm\:border-hover-10,
    .\!sm\:hover\:border-hover-10:hover,
    .\!sm\:focus\:border-hover-10:focus,
    .\!sm\:checked\:border-hover-10:checked {
        --tw-border-opacity: 0.1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-hover-10,
    .sm\:hover\:ring-hover-10:hover,
    .sm\:focus\:ring-hover-10:focus,
    .sm\:checked\:ring-hover-10:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!sm\:ring-hover-10,
    .\!sm\:hover\:ring-hover-10:hover,
    .\!sm\:focus\:ring-hover-10:focus,
    .\!sm\:checked\:ring-hover-10:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-hover-10,
    .md\:hover\:text-hover-10:hover,
    .md\:focus\:text-hover-10:focus,
    .md\:checked\:text-hover-10:checked {
        --tw-text-opacity: 0.1;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!md\:text-hover-10,
    .\!md\:hover\:text-hover-10:hover,
    .\!md\:focus\:text-hover-10:focus,
    .\!md\:checked\:text-hover-10:checked {
        --tw-text-opacity: 0.1;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .md\:bg-hover-10,
    .md\:hover\:bg-hover-10:hover,
    .md\:focus\:bg-hover-10:focus,
    .md\:checked\:bg-hover-10:checked {
        --tw-bg-opacity: 0.1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!md\:bg-hover-10,
    .\!md\:hover\:bg-hover-10:hover,
    .\!md\:focus\:bg-hover-10:focus,
    .\!md\:checked\:bg-hover-10:checked {
        --tw-bg-opacity: 0.1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .md\:border-hover-10,
    .md\:hover\:border-hover-10:hover,
    .md\:focus\:border-hover-10:focus,
    .md\:checked\:border-hover-10:checked {
        --tw-border-opacity: 0.1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!md\:border-hover-10,
    .\!md\:hover\:border-hover-10:hover,
    .\!md\:focus\:border-hover-10:focus,
    .\!md\:checked\:border-hover-10:checked {
        --tw-border-opacity: 0.1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .md\:ring-hover-10,
    .md\:hover\:ring-hover-10:hover,
    .md\:focus\:ring-hover-10:focus,
    .md\:checked\:ring-hover-10:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!md\:ring-hover-10,
    .\!md\:hover\:ring-hover-10:hover,
    .\!md\:focus\:ring-hover-10:focus,
    .\!md\:checked\:ring-hover-10:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-hover-10,
    .lg\:hover\:text-hover-10:hover,
    .lg\:focus\:text-hover-10:focus,
    .lg\:checked\:text-hover-10:checked {
        --tw-text-opacity: 0.1;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!lg\:text-hover-10,
    .\!lg\:hover\:text-hover-10:hover,
    .\!lg\:focus\:text-hover-10:focus,
    .\!lg\:checked\:text-hover-10:checked {
        --tw-text-opacity: 0.1;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-hover-10,
    .lg\:hover\:bg-hover-10:hover,
    .lg\:focus\:bg-hover-10:focus,
    .lg\:checked\:bg-hover-10:checked {
        --tw-bg-opacity: 0.1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!lg\:bg-hover-10,
    .\!lg\:hover\:bg-hover-10:hover,
    .\!lg\:focus\:bg-hover-10:focus,
    .\!lg\:checked\:bg-hover-10:checked {
        --tw-bg-opacity: 0.1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-hover-10,
    .lg\:hover\:border-hover-10:hover,
    .lg\:focus\:border-hover-10:focus,
    .lg\:checked\:border-hover-10:checked {
        --tw-border-opacity: 0.1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!lg\:border-hover-10,
    .\!lg\:hover\:border-hover-10:hover,
    .\!lg\:focus\:border-hover-10:focus,
    .\!lg\:checked\:border-hover-10:checked {
        --tw-border-opacity: 0.1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-hover-10,
    .lg\:hover\:ring-hover-10:hover,
    .lg\:focus\:ring-hover-10:focus,
    .lg\:checked\:ring-hover-10:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!lg\:ring-hover-10,
    .\!lg\:hover\:ring-hover-10:hover,
    .\!lg\:focus\:ring-hover-10:focus,
    .\!lg\:checked\:ring-hover-10:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-hover-10,
    .xl\:hover\:text-hover-10:hover,
    .xl\:focus\:text-hover-10:focus,
    .xl\:checked\:text-hover-10:checked {
        --tw-text-opacity: 0.1;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!xl\:text-hover-10,
    .\!xl\:hover\:text-hover-10:hover,
    .\!xl\:focus\:text-hover-10:focus,
    .\!xl\:checked\:text-hover-10:checked {
        --tw-text-opacity: 0.1;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-hover-10,
    .xl\:hover\:bg-hover-10:hover,
    .xl\:focus\:bg-hover-10:focus,
    .xl\:checked\:bg-hover-10:checked {
        --tw-bg-opacity: 0.1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!xl\:bg-hover-10,
    .\!xl\:hover\:bg-hover-10:hover,
    .\!xl\:focus\:bg-hover-10:focus,
    .\!xl\:checked\:bg-hover-10:checked {
        --tw-bg-opacity: 0.1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-hover-10,
    .xl\:hover\:border-hover-10:hover,
    .xl\:focus\:border-hover-10:focus,
    .xl\:checked\:border-hover-10:checked {
        --tw-border-opacity: 0.1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!xl\:border-hover-10,
    .\!xl\:hover\:border-hover-10:hover,
    .\!xl\:focus\:border-hover-10:focus,
    .\!xl\:checked\:border-hover-10:checked {
        --tw-border-opacity: 0.1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-hover-10,
    .xl\:hover\:ring-hover-10:hover,
    .xl\:focus\:ring-hover-10:focus,
    .xl\:checked\:ring-hover-10:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!xl\:ring-hover-10,
    .\!xl\:hover\:ring-hover-10:hover,
    .\!xl\:focus\:ring-hover-10:focus,
    .\!xl\:checked\:ring-hover-10:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-hover-10,
    .\2xl\:hover\:text-hover-10:hover,
    .\2xl\:focus\:text-hover-10:focus,
    .\2xl\:checked\:text-hover-10:checked {
        --tw-text-opacity: 0.1;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-hover-10,
    .\!2xlsm\:hover\:text-hover-10:hover,
    .\!2xlsm\:focus\:text-hover-10:focus,
    .\!2xlsm\:checked\:text-hover-10:checked {
        --tw-text-opacity: 0.1;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-hover-10,
    .\2xl\:hover\:bg-hover-10:hover,
    .\2xl\:focus\:bg-hover-10:focus,
    .\2xl\:checked\:bg-hover-10:checked {
        --tw-bg-opacity: 0.1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-hover-10,
    .\!2xlsm\:hover\:bg-hover-10:hover,
    .\!2xlsm\:focus\:bg-hover-10:focus,
    .\!2xlsm\:checked\:bg-hover-10:checked {
        --tw-bg-opacity: 0.1;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-hover-10,
    .\2xl\:hover\:border-hover-10:hover,
    .\2xl\:focus\:border-hover-10:focus,
    .\2xl\:checked\:border-hover-10:checked {
        --tw-border-opacity: 0.1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-hover-10,
    .\!2xlsm\:hover\:border-hover-10:hover,
    .\!2xlsm\:focus\:border-hover-10:focus,
    .\!2xlsm\:checked\:border-hover-10:checked {
        --tw-border-opacity: 0.1;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-hover-10,
    .\2xl\:hover\:ring-hover-10:hover,
    .\2xl\:focus\:ring-hover-10:focus,
    .\2xl\:checked\:ring-hover-10:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-hover-10,
    .\!2xl\:hover\:ring-hover-10:hover,
    .\!2xl\:focus\:ring-hover-10:focus,
    .\!2xl\:checked\:ring-hover-10:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

//hover-20
.text-hover-20,
.hover\:text-hover-20:hover,
.focus\:text-hover-20:focus,
.checked\:text-hover-20:checked {
    --tw-text-opacity: 0.2;
    color: rgb(132, 116, 106, var(--tw-text-opacity));
}

.\!text-hover-20,
.\!hover\:text-hover-20:hover,
.\!focus\:text-hover-20:focus,
.\!checked\:text-hover-20:checked {
    --tw-text-opacity: 0.2;
    color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
}

.bg-hover-20,
.hover\:bg-hover-20:hover,
.focus\:bg-hover-20:focus,
.checked\:bg-hover-20:checked {
    --tw-bg-opacity: 0.2;
    background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
}

.\!bg-hover-20,
.\!hover\:bg-hover-20:hover,
.\!focus\:bg-hover-20:focus,
.\!checked\:bg-hover-20:checked {
    --tw-bg-opacity: 0.2;
    background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
}

.border-hover-20,
.hover\:border-hover-20:hover,
.focus\:border-hover-20:focus,
.checked\:border-hover-20:checked {
    --tw-border-opacity: 0.2;
    border-color: rgb(132, 116, 106, var(--tw-border-opacity));
}

.\!border-hover-20,
.\!hover\:border-hover-20:hover,
.\!focus\:border-hover-20:focus,
.\!checked\:border-hover-20:checked {
    --tw-border-opacity: 0.2;
    border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
}

.ring-hover-20,
.hover\:ring-hover-20:hover,
.focus\:ring-hover-20:focus,
.checked\:ring-hover-20:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
}

.\!ring-hover-20,
.\!hover\:ring-hover-20:hover,
.\!focus\:ring-hover-20:focus,
.\!checked\:ring-hover-20:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-hover-20,
    .sm\:hover\:text-hover-20:hover,
    .sm\:focus\:text-hover-20:focus,
    .sm\:checked\:text-hover-20:checked {
        --tw-text-opacity: 0.2;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!sm\:text-hover-20,
    .\!sm\:hover\:text-hover-20:hover,
    .\!sm\:focus\:text-hover-20:focus,
    .\!sm\:checked\:text-hover-20:checked {
        --tw-text-opacity: 0.2;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-hover-20,
    .sm\:hover\:bg-hover-20:hover,
    .sm\:focus\:bg-hover-20:focus,
    .sm\:checked\:bg-hover-20:checked {
        --tw-bg-opacity: 0.2;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!sm\:bg-hover-20,
    .\!sm\:hover\:bg-hover-20:hover,
    .\!sm\:focus\:bg-hover-20:focus,
    .\!sm\:checked\:bg-hover-20:checked {
        --tw-bg-opacity: 0.2;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-hover-20,
    .sm\:hover\:border-hover-20:hover,
    .sm\:focus\:border-hover-20:focus,
    .sm\:checked\:border-hover-20:checked {
        --tw-border-opacity: 0.2;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!sm\:border-hover-20,
    .\!sm\:hover\:border-hover-20:hover,
    .\!sm\:focus\:border-hover-20:focus,
    .\!sm\:checked\:border-hover-20:checked {
        --tw-border-opacity: 0.2;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-hover-20,
    .sm\:hover\:ring-hover-20:hover,
    .sm\:focus\:ring-hover-20:focus,
    .sm\:checked\:ring-hover-20:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!sm\:ring-hover-20,
    .\!sm\:hover\:ring-hover-20:hover,
    .\!sm\:focus\:ring-hover-20:focus,
    .\!sm\:checked\:ring-hover-20:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-hover-20,
    .md\:hover\:text-hover-20:hover,
    .md\:focus\:text-hover-20:focus,
    .md\:checked\:text-hover-20:checked {
        --tw-text-opacity: 0.2;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!md\:text-hover-20,
    .\!md\:hover\:text-hover-20:hover,
    .\!md\:focus\:text-hover-20:focus,
    .\!md\:checked\:text-hover-20:checked {
        --tw-text-opacity: 0.2;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .md\:bg-hover-20,
    .md\:hover\:bg-hover-20:hover,
    .md\:focus\:bg-hover-20:focus,
    .md\:checked\:bg-hover-20:checked {
        --tw-bg-opacity: 0.2;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!md\:bg-hover-20,
    .\!md\:hover\:bg-hover-20:hover,
    .\!md\:focus\:bg-hover-20:focus,
    .\!md\:checked\:bg-hover-20:checked {
        --tw-bg-opacity: 0.2;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .md\:border-hover-20,
    .md\:hover\:border-hover-20:hover,
    .md\:focus\:border-hover-20:focus,
    .md\:checked\:border-hover-20:checked {
        --tw-border-opacity: 0.2;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!md\:border-hover-20,
    .\!md\:hover\:border-hover-20:hover,
    .\!md\:focus\:border-hover-20:focus,
    .\!md\:checked\:border-hover-20:checked {
        --tw-border-opacity: 0.2;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .md\:ring-hover-20,
    .md\:hover\:ring-hover-20:hover,
    .md\:focus\:ring-hover-20:focus,
    .md\:checked\:ring-hover-20:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!md\:ring-hover-20,
    .\!md\:hover\:ring-hover-20:hover,
    .\!md\:focus\:ring-hover-20:focus,
    .\!md\:checked\:ring-hover-20:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-hover-20,
    .lg\:hover\:text-hover-20:hover,
    .lg\:focus\:text-hover-20:focus,
    .lg\:checked\:text-hover-20:checked {
        --tw-text-opacity: 0.2;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!lg\:text-hover-20,
    .\!lg\:hover\:text-hover-20:hover,
    .\!lg\:focus\:text-hover-20:focus,
    .\!lg\:checked\:text-hover-20:checked {
        --tw-text-opacity: 0.2;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-hover-20,
    .lg\:hover\:bg-hover-20:hover,
    .lg\:focus\:bg-hover-20:focus,
    .lg\:checked\:bg-hover-20:checked {
        --tw-bg-opacity: 0.2;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!lg\:bg-hover-20,
    .\!lg\:hover\:bg-hover-20:hover,
    .\!lg\:focus\:bg-hover-20:focus,
    .\!lg\:checked\:bg-hover-20:checked {
        --tw-bg-opacity: 0.2;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-hover-20,
    .lg\:hover\:border-hover-20:hover,
    .lg\:focus\:border-hover-20:focus,
    .lg\:checked\:border-hover-20:checked {
        --tw-border-opacity: 0.2;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!lg\:border-hover-20,
    .\!lg\:hover\:border-hover-20:hover,
    .\!lg\:focus\:border-hover-20:focus,
    .\!lg\:checked\:border-hover-20:checked {
        --tw-border-opacity: 0.2;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-hover-20,
    .lg\:hover\:ring-hover-20:hover,
    .lg\:focus\:ring-hover-20:focus,
    .lg\:checked\:ring-hover-20:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!lg\:ring-hover-20,
    .\!lg\:hover\:ring-hover-20:hover,
    .\!lg\:focus\:ring-hover-20:focus,
    .\!lg\:checked\:ring-hover-20:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-hover-20,
    .xl\:hover\:text-hover-20:hover,
    .xl\:focus\:text-hover-20:focus,
    .xl\:checked\:text-hover-20:checked {
        --tw-text-opacity: 0.2;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!xl\:text-hover-20,
    .\!xl\:hover\:text-hover-20:hover,
    .\!xl\:focus\:text-hover-20:focus,
    .\!xl\:checked\:text-hover-20:checked {
        --tw-text-opacity: 0.2;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-hover-20,
    .xl\:hover\:bg-hover-20:hover,
    .xl\:focus\:bg-hover-20:focus,
    .xl\:checked\:bg-hover-20:checked {
        --tw-bg-opacity: 0.2;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!xl\:bg-hover-20,
    .\!xl\:hover\:bg-hover-20:hover,
    .\!xl\:focus\:bg-hover-20:focus,
    .\!xl\:checked\:bg-hover-20:checked {
        --tw-bg-opacity: 0.2;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-hover-20,
    .xl\:hover\:border-hover-20:hover,
    .xl\:focus\:border-hover-20:focus,
    .xl\:checked\:border-hover-20:checked {
        --tw-border-opacity: 0.2;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!xl\:border-hover-20,
    .\!xl\:hover\:border-hover-20:hover,
    .\!xl\:focus\:border-hover-20:focus,
    .\!xl\:checked\:border-hover-20:checked {
        --tw-border-opacity: 0.2;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-hover-20,
    .xl\:hover\:ring-hover-20:hover,
    .xl\:focus\:ring-hover-20:focus,
    .xl\:checked\:ring-hover-20:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!xl\:ring-hover-20,
    .\!xl\:hover\:ring-hover-20:hover,
    .\!xl\:focus\:ring-hover-20:focus,
    .\!xl\:checked\:ring-hover-20:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-hover-20,
    .\2xl\:hover\:text-hover-20:hover,
    .\2xl\:focus\:text-hover-20:focus,
    .\2xl\:checked\:text-hover-20:checked {
        --tw-text-opacity: 0.2;
        color: rgb(132, 116, 106, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-hover-20,
    .\!2xlsm\:hover\:text-hover-20:hover,
    .\!2xlsm\:focus\:text-hover-20:focus,
    .\!2xlsm\:checked\:text-hover-20:checked {
        --tw-text-opacity: 0.2;
        color: rgb(132, 116, 106, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-hover-20,
    .\2xl\:hover\:bg-hover-20:hover,
    .\2xl\:focus\:bg-hover-20:focus,
    .\2xl\:checked\:bg-hover-20:checked {
        --tw-bg-opacity: 0.2;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-hover-20,
    .\!2xlsm\:hover\:bg-hover-20:hover,
    .\!2xlsm\:focus\:bg-hover-20:focus,
    .\!2xlsm\:checked\:bg-hover-20:checked {
        --tw-bg-opacity: 0.2;
        background-color: rgb(132, 116, 106, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-hover-20,
    .\2xl\:hover\:border-hover-20:hover,
    .\2xl\:focus\:border-hover-20:focus,
    .\2xl\:checked\:border-hover-20:checked {
        --tw-border-opacity: 0.2;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-hover-20,
    .\!2xlsm\:hover\:border-hover-20:hover,
    .\!2xlsm\:focus\:border-hover-20:focus,
    .\!2xlsm\:checked\:border-hover-20:checked {
        --tw-border-opacity: 0.2;
        border-color: rgb(132, 116, 106, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-hover-20,
    .\2xl\:hover\:ring-hover-20:hover,
    .\2xl\:focus\:ring-hover-20:focus,
    .\2xl\:checked\:ring-hover-20:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-hover-20,
    .\!2xl\:hover\:ring-hover-20:hover,
    .\!2xl\:focus\:ring-hover-20:focus,
    .\!2xl\:checked\:ring-hover-20:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(132, 116, 106, var(--tw-ring-opacity)) !important;
    }
}

//primary-container
.text-primary-container,
.hover\:text-primary-container:hover,
.focus\:text-primary-container:focus,
.checked\:text-primary-container:checked {
    --tw-text-opacity: 1;
    color: rgb(255, 220, 198, var(--tw-text-opacity));
}

.\!text-primary-container,
.\!hover\:text-primary-container:hover,
.\!focus\:text-primary-container:focus,
.\!checked\:text-primary-container:checked {
    --tw-text-opacity: 1;
    color: rgb(255, 220, 198, var(--tw-text-opacity)) !important;
}

.bg-primary-container,
.hover\:bg-primary-container:hover,
.focus\:bg-primary-container:focus,
.checked\:bg-primary-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(255, 220, 198, var(--tw-bg-opacity));
}

.\!bg-primary-container,
.\!hover\:bg-primary-container:hover,
.\!focus\:bg-primary-container:focus,
.\!checked\:bg-primary-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(255, 220, 198, var(--tw-bg-opacity)) !important;
}

.border-primary-container,
.hover\:border-primary-container:hover,
.focus\:border-primary-container:focus,
.checked\:border-primary-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(255, 220, 198, var(--tw-border-opacity));
}

.\!border-primary-container,
.\!hover\:border-primary-container:hover,
.\!focus\:border-primary-container:focus,
.\!checked\:border-primary-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(255, 220, 198, var(--tw-border-opacity)) !important;
}

.ring-primary-container,
.hover\:ring-primary-container:hover,
.focus\:ring-primary-container:focus,
.checked\:ring-primary-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255, 220, 198, var(--tw-ring-opacity));
}

.\!ring-primary-container,
.\!hover\:ring-primary-container:hover,
.\!focus\:ring-primary-container:focus,
.\!checked\:ring-primary-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255, 220, 198, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-primary-container,
    .sm\:hover\:text-primary-container:hover,
    .sm\:focus\:text-primary-container:focus,
    .sm\:checked\:text-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 220, 198, var(--tw-text-opacity));
    }

    .\!sm\:text-primary-container,
    .\!sm\:hover\:text-primary-container:hover,
    .\!sm\:focus\:text-primary-container:focus,
    .\!sm\:checked\:text-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 220, 198, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-primary-container,
    .sm\:hover\:bg-primary-container:hover,
    .sm\:focus\:bg-primary-container:focus,
    .sm\:checked\:bg-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 220, 198, var(--tw-bg-opacity));
    }

    .\!sm\:bg-primary-container,
    .\!sm\:hover\:bg-primary-container:hover,
    .\!sm\:focus\:bg-primary-container:focus,
    .\!sm\:checked\:bg-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 220, 198, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-primary-container,
    .sm\:hover\:border-primary-container:hover,
    .sm\:focus\:border-primary-container:focus,
    .sm\:checked\:border-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 220, 198, var(--tw-border-opacity));
    }

    .\!sm\:border-primary-container,
    .\!sm\:hover\:border-primary-container:hover,
    .\!sm\:focus\:border-primary-container:focus,
    .\!sm\:checked\:border-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 220, 198, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-primary-container,
    .sm\:hover\:ring-primary-container:hover,
    .sm\:focus\:ring-primary-container:focus,
    .sm\:checked\:ring-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 220, 198, var(--tw-ring-opacity));
    }

    .\!sm\:ring-primary-container,
    .\!sm\:hover\:ring-primary-container:hover,
    .\!sm\:focus\:ring-primary-container:focus,
    .\!sm\:checked\:ring-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 220, 198, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-primary-container,
    .md\:hover\:text-primary-container:hover,
    .md\:focus\:text-primary-container:focus,
    .md\:checked\:text-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 220, 198, var(--tw-text-opacity));
    }

    .\!md\:text-primary-container,
    .\!md\:hover\:text-primary-container:hover,
    .\!md\:focus\:text-primary-container:focus,
    .\!md\:checked\:text-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 220, 198, var(--tw-text-opacity)) !important;
    }

    .md\:bg-primary-container,
    .md\:hover\:bg-primary-container:hover,
    .md\:focus\:bg-primary-container:focus,
    .md\:checked\:bg-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 220, 198, var(--tw-bg-opacity));
    }

    .\!md\:bg-primary-container,
    .\!md\:hover\:bg-primary-container:hover,
    .\!md\:focus\:bg-primary-container:focus,
    .\!md\:checked\:bg-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 220, 198, var(--tw-bg-opacity)) !important;
    }

    .md\:border-primary-container,
    .md\:hover\:border-primary-container:hover,
    .md\:focus\:border-primary-container:focus,
    .md\:checked\:border-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 220, 198, var(--tw-border-opacity));
    }

    .\!md\:border-primary-container,
    .\!md\:hover\:border-primary-container:hover,
    .\!md\:focus\:border-primary-container:focus,
    .\!md\:checked\:border-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 220, 198, var(--tw-border-opacity)) !important;
    }

    .md\:ring-primary-container,
    .md\:hover\:ring-primary-container:hover,
    .md\:focus\:ring-primary-container:focus,
    .md\:checked\:ring-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 220, 198, var(--tw-ring-opacity));
    }

    .\!md\:ring-primary-container,
    .\!md\:hover\:ring-primary-container:hover,
    .\!md\:focus\:ring-primary-container:focus,
    .\!md\:checked\:ring-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 220, 198, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-primary-container,
    .lg\:hover\:text-primary-container:hover,
    .lg\:focus\:text-primary-container:focus,
    .lg\:checked\:text-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 220, 198, var(--tw-text-opacity));
    }

    .\!lg\:text-primary-container,
    .\!lg\:hover\:text-primary-container:hover,
    .\!lg\:focus\:text-primary-container:focus,
    .\!lg\:checked\:text-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 220, 198, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-primary-container,
    .lg\:hover\:bg-primary-container:hover,
    .lg\:focus\:bg-primary-container:focus,
    .lg\:checked\:bg-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 220, 198, var(--tw-bg-opacity));
    }

    .\!lg\:bg-primary-container,
    .\!lg\:hover\:bg-primary-container:hover,
    .\!lg\:focus\:bg-primary-container:focus,
    .\!lg\:checked\:bg-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 220, 198, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-primary-container,
    .lg\:hover\:border-primary-container:hover,
    .lg\:focus\:border-primary-container:focus,
    .lg\:checked\:border-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 220, 198, var(--tw-border-opacity));
    }

    .\!lg\:border-primary-container,
    .\!lg\:hover\:border-primary-container:hover,
    .\!lg\:focus\:border-primary-container:focus,
    .\!lg\:checked\:border-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 220, 198, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-primary-container,
    .lg\:hover\:ring-primary-container:hover,
    .lg\:focus\:ring-primary-container:focus,
    .lg\:checked\:ring-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 220, 198, var(--tw-ring-opacity));
    }

    .\!lg\:ring-primary-container,
    .\!lg\:hover\:ring-primary-container:hover,
    .\!lg\:focus\:ring-primary-container:focus,
    .\!lg\:checked\:ring-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 220, 198, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-primary-container,
    .xl\:hover\:text-primary-container:hover,
    .xl\:focus\:text-primary-container:focus,
    .xl\:checked\:text-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 220, 198, var(--tw-text-opacity));
    }

    .\!xl\:text-primary-container,
    .\!xl\:hover\:text-primary-container:hover,
    .\!xl\:focus\:text-primary-container:focus,
    .\!xl\:checked\:text-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 220, 198, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-primary-container,
    .xl\:hover\:bg-primary-container:hover,
    .xl\:focus\:bg-primary-container:focus,
    .xl\:checked\:bg-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 220, 198, var(--tw-bg-opacity));
    }

    .\!xl\:bg-primary-container,
    .\!xl\:hover\:bg-primary-container:hover,
    .\!xl\:focus\:bg-primary-container:focus,
    .\!xl\:checked\:bg-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 220, 198, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-primary-container,
    .xl\:hover\:border-primary-container:hover,
    .xl\:focus\:border-primary-container:focus,
    .xl\:checked\:border-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 220, 198, var(--tw-border-opacity));
    }

    .\!xl\:border-primary-container,
    .\!xl\:hover\:border-primary-container:hover,
    .\!xl\:focus\:border-primary-container:focus,
    .\!xl\:checked\:border-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 220, 198, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-primary-container,
    .xl\:hover\:ring-primary-container:hover,
    .xl\:focus\:ring-primary-container:focus,
    .xl\:checked\:ring-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 220, 198, var(--tw-ring-opacity));
    }

    .\!xl\:ring-primary-container,
    .\!xl\:hover\:ring-primary-container:hover,
    .\!xl\:focus\:ring-primary-container:focus,
    .\!xl\:checked\:ring-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 220, 198, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-primary-container,
    .\2xl\:hover\:text-primary-container:hover,
    .\2xl\:focus\:text-primary-container:focus,
    .\2xl\:checked\:text-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 220, 198, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-primary-container,
    .\!2xlsm\:hover\:text-primary-container:hover,
    .\!2xlsm\:focus\:text-primary-container:focus,
    .\!2xlsm\:checked\:text-primary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 220, 198, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-primary-container,
    .\2xl\:hover\:bg-primary-container:hover,
    .\2xl\:focus\:bg-primary-container:focus,
    .\2xl\:checked\:bg-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 220, 198, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-primary-container,
    .\!2xlsm\:hover\:bg-primary-container:hover,
    .\!2xlsm\:focus\:bg-primary-container:focus,
    .\!2xlsm\:checked\:bg-primary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 220, 198, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-primary-container,
    .\2xl\:hover\:border-primary-container:hover,
    .\2xl\:focus\:border-primary-container:focus,
    .\2xl\:checked\:border-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 220, 198, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-primary-container,
    .\!2xlsm\:hover\:border-primary-container:hover,
    .\!2xlsm\:focus\:border-primary-container:focus,
    .\!2xlsm\:checked\:border-primary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 220, 198, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-primary-container,
    .\2xl\:hover\:ring-primary-container:hover,
    .\2xl\:focus\:ring-primary-container:focus,
    .\2xl\:checked\:ring-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 220, 198, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-primary-container,
    .\!2xl\:hover\:ring-primary-container:hover,
    .\!2xl\:focus\:ring-primary-container:focus,
    .\!2xl\:checked\:ring-primary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 220, 198, var(--tw-ring-opacity)) !important;
    }
}

//on-primary
.text-on-primary,
.hover\:text-on-primary:hover,
.focus\:text-on-primary:focus,
.checked\:text-on-primary:checked {
    --tw-text-opacity: 1;
    color: rgb(135, 135, 135, var(--tw-text-opacity));
}

.\!text-on-primary,
.\!hover\:text-on-primary:hover,
.\!focus\:text-on-primary:focus,
.\!checked\:text-on-primary:checked {
    --tw-text-opacity: 1;
    color: rgb(135, 135, 135, var(--tw-text-opacity)) !important;
}

.bg-on-primary,
.hover\:bg-on-primary:hover,
.focus\:bg-on-primary:focus,
.checked\:bg-on-primary:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(135, 135, 135, var(--tw-bg-opacity));
}

.\!bg-on-primary,
.\!hover\:bg-on-primary:hover,
.\!focus\:bg-on-primary:focus,
.\!checked\:bg-on-primary:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(135, 135, 135, var(--tw-bg-opacity)) !important;
}

.border-on-primary,
.hover\:border-on-primary:hover,
.focus\:border-on-primary:focus,
.checked\:border-on-primary:checked {
    --tw-border-opacity: 1;
    border-color: rgb(135, 135, 135, var(--tw-border-opacity));
}

.\!border-on-primary,
.\!hover\:border-on-primary:hover,
.\!focus\:border-on-primary:focus,
.\!checked\:border-on-primary:checked {
    --tw-border-opacity: 1;
    border-color: rgb(135, 135, 135, var(--tw-border-opacity)) !important;
}

.ring-on-primary,
.hover\:ring-on-primary:hover,
.focus\:ring-on-primary:focus,
.checked\:ring-on-primary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(135, 135, 135, var(--tw-ring-opacity));
}

.\!ring-on-primary,
.\!hover\:ring-on-primary:hover,
.\!focus\:ring-on-primary:focus,
.\!checked\:ring-on-primary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(135, 135, 135, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-on-primary,
    .sm\:hover\:text-on-primary:hover,
    .sm\:focus\:text-on-primary:focus,
    .sm\:checked\:text-on-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(135, 135, 135, var(--tw-text-opacity));
    }

    .\!sm\:text-on-primary,
    .\!sm\:hover\:text-on-primary:hover,
    .\!sm\:focus\:text-on-primary:focus,
    .\!sm\:checked\:text-on-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(135, 135, 135, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-on-primary,
    .sm\:hover\:bg-on-primary:hover,
    .sm\:focus\:bg-on-primary:focus,
    .sm\:checked\:bg-on-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(135, 135, 135, var(--tw-bg-opacity));
    }

    .\!sm\:bg-on-primary,
    .\!sm\:hover\:bg-on-primary:hover,
    .\!sm\:focus\:bg-on-primary:focus,
    .\!sm\:checked\:bg-on-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(135, 135, 135, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-on-primary,
    .sm\:hover\:border-on-primary:hover,
    .sm\:focus\:border-on-primary:focus,
    .sm\:checked\:border-on-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(135, 135, 135, var(--tw-border-opacity));
    }

    .\!sm\:border-on-primary,
    .\!sm\:hover\:border-on-primary:hover,
    .\!sm\:focus\:border-on-primary:focus,
    .\!sm\:checked\:border-on-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(135, 135, 135, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-on-primary,
    .sm\:hover\:ring-on-primary:hover,
    .sm\:focus\:ring-on-primary:focus,
    .sm\:checked\:ring-on-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(135, 135, 135, var(--tw-ring-opacity));
    }

    .\!sm\:ring-on-primary,
    .\!sm\:hover\:ring-on-primary:hover,
    .\!sm\:focus\:ring-on-primary:focus,
    .\!sm\:checked\:ring-on-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(135, 135, 135, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-on-primary,
    .md\:hover\:text-on-primary:hover,
    .md\:focus\:text-on-primary:focus,
    .md\:checked\:text-on-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(135, 135, 135, var(--tw-text-opacity));
    }

    .\!md\:text-on-primary,
    .\!md\:hover\:text-on-primary:hover,
    .\!md\:focus\:text-on-primary:focus,
    .\!md\:checked\:text-on-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(135, 135, 135, var(--tw-text-opacity)) !important;
    }

    .md\:bg-on-primary,
    .md\:hover\:bg-on-primary:hover,
    .md\:focus\:bg-on-primary:focus,
    .md\:checked\:bg-on-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(135, 135, 135, var(--tw-bg-opacity));
    }

    .\!md\:bg-on-primary,
    .\!md\:hover\:bg-on-primary:hover,
    .\!md\:focus\:bg-on-primary:focus,
    .\!md\:checked\:bg-on-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(135, 135, 135, var(--tw-bg-opacity)) !important;
    }

    .md\:border-on-primary,
    .md\:hover\:border-on-primary:hover,
    .md\:focus\:border-on-primary:focus,
    .md\:checked\:border-on-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(135, 135, 135, var(--tw-border-opacity));
    }

    .\!md\:border-on-primary,
    .\!md\:hover\:border-on-primary:hover,
    .\!md\:focus\:border-on-primary:focus,
    .\!md\:checked\:border-on-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(135, 135, 135, var(--tw-border-opacity)) !important;
    }

    .md\:ring-on-primary,
    .md\:hover\:ring-on-primary:hover,
    .md\:focus\:ring-on-primary:focus,
    .md\:checked\:ring-on-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(135, 135, 135, var(--tw-ring-opacity));
    }

    .\!md\:ring-on-primary,
    .\!md\:hover\:ring-on-primary:hover,
    .\!md\:focus\:ring-on-primary:focus,
    .\!md\:checked\:ring-on-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(135, 135, 135, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-on-primary,
    .lg\:hover\:text-on-primary:hover,
    .lg\:focus\:text-on-primary:focus,
    .lg\:checked\:text-on-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(135, 135, 135, var(--tw-text-opacity));
    }

    .\!lg\:text-on-primary,
    .\!lg\:hover\:text-on-primary:hover,
    .\!lg\:focus\:text-on-primary:focus,
    .\!lg\:checked\:text-on-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(135, 135, 135, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-on-primary,
    .lg\:hover\:bg-on-primary:hover,
    .lg\:focus\:bg-on-primary:focus,
    .lg\:checked\:bg-on-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(135, 135, 135, var(--tw-bg-opacity));
    }

    .\!lg\:bg-on-primary,
    .\!lg\:hover\:bg-on-primary:hover,
    .\!lg\:focus\:bg-on-primary:focus,
    .\!lg\:checked\:bg-on-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(135, 135, 135, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-on-primary,
    .lg\:hover\:border-on-primary:hover,
    .lg\:focus\:border-on-primary:focus,
    .lg\:checked\:border-on-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(135, 135, 135, var(--tw-border-opacity));
    }

    .\!lg\:border-on-primary,
    .\!lg\:hover\:border-on-primary:hover,
    .\!lg\:focus\:border-on-primary:focus,
    .\!lg\:checked\:border-on-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(135, 135, 135, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-on-primary,
    .lg\:hover\:ring-on-primary:hover,
    .lg\:focus\:ring-on-primary:focus,
    .lg\:checked\:ring-on-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(135, 135, 135, var(--tw-ring-opacity));
    }

    .\!lg\:ring-on-primary,
    .\!lg\:hover\:ring-on-primary:hover,
    .\!lg\:focus\:ring-on-primary:focus,
    .\!lg\:checked\:ring-on-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(135, 135, 135, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-on-primary,
    .xl\:hover\:text-on-primary:hover,
    .xl\:focus\:text-on-primary:focus,
    .xl\:checked\:text-on-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(135, 135, 135, var(--tw-text-opacity));
    }

    .\!xl\:text-on-primary,
    .\!xl\:hover\:text-on-primary:hover,
    .\!xl\:focus\:text-on-primary:focus,
    .\!xl\:checked\:text-on-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(135, 135, 135, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-on-primary,
    .xl\:hover\:bg-on-primary:hover,
    .xl\:focus\:bg-on-primary:focus,
    .xl\:checked\:bg-on-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(135, 135, 135, var(--tw-bg-opacity));
    }

    .\!xl\:bg-on-primary,
    .\!xl\:hover\:bg-on-primary:hover,
    .\!xl\:focus\:bg-on-primary:focus,
    .\!xl\:checked\:bg-on-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(135, 135, 135, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-on-primary,
    .xl\:hover\:border-on-primary:hover,
    .xl\:focus\:border-on-primary:focus,
    .xl\:checked\:border-on-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(135, 135, 135, var(--tw-border-opacity));
    }

    .\!xl\:border-on-primary,
    .\!xl\:hover\:border-on-primary:hover,
    .\!xl\:focus\:border-on-primary:focus,
    .\!xl\:checked\:border-on-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(135, 135, 135, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-on-primary,
    .xl\:hover\:ring-on-primary:hover,
    .xl\:focus\:ring-on-primary:focus,
    .xl\:checked\:ring-on-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(135, 135, 135, var(--tw-ring-opacity));
    }

    .\!xl\:ring-on-primary,
    .\!xl\:hover\:ring-on-primary:hover,
    .\!xl\:focus\:ring-on-primary:focus,
    .\!xl\:checked\:ring-on-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(135, 135, 135, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-on-primary,
    .\2xl\:hover\:text-on-primary:hover,
    .\2xl\:focus\:text-on-primary:focus,
    .\2xl\:checked\:text-on-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(135, 135, 135, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-on-primary,
    .\!2xlsm\:hover\:text-on-primary:hover,
    .\!2xlsm\:focus\:text-on-primary:focus,
    .\!2xlsm\:checked\:text-on-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(135, 135, 135, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-on-primary,
    .\2xl\:hover\:bg-on-primary:hover,
    .\2xl\:focus\:bg-on-primary:focus,
    .\2xl\:checked\:bg-on-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(135, 135, 135, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-on-primary,
    .\!2xlsm\:hover\:bg-on-primary:hover,
    .\!2xlsm\:focus\:bg-on-primary:focus,
    .\!2xlsm\:checked\:bg-on-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(135, 135, 135, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-on-primary,
    .\2xl\:hover\:border-on-primary:hover,
    .\2xl\:focus\:border-on-primary:focus,
    .\2xl\:checked\:border-on-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(135, 135, 135, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-on-primary,
    .\!2xlsm\:hover\:border-on-primary:hover,
    .\!2xlsm\:focus\:border-on-primary:focus,
    .\!2xlsm\:checked\:border-on-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(135, 135, 135, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-on-primary,
    .\2xl\:hover\:ring-on-primary:hover,
    .\2xl\:focus\:ring-on-primary:focus,
    .\2xl\:checked\:ring-on-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(135, 135, 135, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-on-primary,
    .\!2xl\:hover\:ring-on-primary:hover,
    .\!2xl\:focus\:ring-on-primary:focus,
    .\!2xl\:checked\:ring-on-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(135, 135, 135, var(--tw-ring-opacity)) !important;
    }
}

//secondary-container
.text-secondary-container,
.hover\:text-secondary-container:hover,
.focus\:text-secondary-container:focus,
.checked\:text-secondary-container:checked {
    --tw-text-opacity: 1;
    color: rgb(255, 221, 185, var(--tw-text-opacity));
}

.\!text-secondary-container,
.\!hover\:text-secondary-container:hover,
.\!focus\:text-secondary-container:focus,
.\!checked\:text-secondary-container:checked {
    --tw-text-opacity: 1;
    color: rgb(255, 221, 185, var(--tw-text-opacity)) !important;
}

.bg-secondary-container,
.hover\:bg-secondary-container:hover,
.focus\:bg-secondary-container:focus,
.checked\:bg-secondary-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(255, 221, 185, var(--tw-bg-opacity));
}

.\!bg-secondary-container,
.\!hover\:bg-secondary-container:hover,
.\!focus\:bg-secondary-container:focus,
.\!checked\:bg-secondary-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(255, 221, 185, var(--tw-bg-opacity)) !important;
}

.border-secondary-container,
.hover\:border-secondary-container:hover,
.focus\:border-secondary-container:focus,
.checked\:border-secondary-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(255, 221, 185, var(--tw-border-opacity));
}

.\!border-secondary-container,
.\!hover\:border-secondary-container:hover,
.\!focus\:border-secondary-container:focus,
.\!checked\:border-secondary-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(255, 221, 185, var(--tw-border-opacity)) !important;
}

.ring-secondary-container,
.hover\:ring-secondary-container:hover,
.focus\:ring-secondary-container:focus,
.checked\:ring-secondary-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255, 221, 185, var(--tw-ring-opacity));
}

.\!ring-secondary-container,
.\!hover\:ring-secondary-container:hover,
.\!focus\:ring-secondary-container:focus,
.\!checked\:ring-secondary-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255, 221, 185, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-secondary-container,
    .sm\:hover\:text-secondary-container:hover,
    .sm\:focus\:text-secondary-container:focus,
    .sm\:checked\:text-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 221, 185, var(--tw-text-opacity));
    }

    .\!sm\:text-secondary-container,
    .\!sm\:hover\:text-secondary-container:hover,
    .\!sm\:focus\:text-secondary-container:focus,
    .\!sm\:checked\:text-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 221, 185, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-secondary-container,
    .sm\:hover\:bg-secondary-container:hover,
    .sm\:focus\:bg-secondary-container:focus,
    .sm\:checked\:bg-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 221, 185, var(--tw-bg-opacity));
    }

    .\!sm\:bg-secondary-container,
    .\!sm\:hover\:bg-secondary-container:hover,
    .\!sm\:focus\:bg-secondary-container:focus,
    .\!sm\:checked\:bg-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 221, 185, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-secondary-container,
    .sm\:hover\:border-secondary-container:hover,
    .sm\:focus\:border-secondary-container:focus,
    .sm\:checked\:border-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 221, 185, var(--tw-border-opacity));
    }

    .\!sm\:border-secondary-container,
    .\!sm\:hover\:border-secondary-container:hover,
    .\!sm\:focus\:border-secondary-container:focus,
    .\!sm\:checked\:border-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 221, 185, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-secondary-container,
    .sm\:hover\:ring-secondary-container:hover,
    .sm\:focus\:ring-secondary-container:focus,
    .sm\:checked\:ring-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 221, 185, var(--tw-ring-opacity));
    }

    .\!sm\:ring-secondary-container,
    .\!sm\:hover\:ring-secondary-container:hover,
    .\!sm\:focus\:ring-secondary-container:focus,
    .\!sm\:checked\:ring-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 221, 185, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-secondary-container,
    .md\:hover\:text-secondary-container:hover,
    .md\:focus\:text-secondary-container:focus,
    .md\:checked\:text-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 221, 185, var(--tw-text-opacity));
    }

    .\!md\:text-secondary-container,
    .\!md\:hover\:text-secondary-container:hover,
    .\!md\:focus\:text-secondary-container:focus,
    .\!md\:checked\:text-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 221, 185, var(--tw-text-opacity)) !important;
    }

    .md\:bg-secondary-container,
    .md\:hover\:bg-secondary-container:hover,
    .md\:focus\:bg-secondary-container:focus,
    .md\:checked\:bg-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 221, 185, var(--tw-bg-opacity));
    }

    .\!md\:bg-secondary-container,
    .\!md\:hover\:bg-secondary-container:hover,
    .\!md\:focus\:bg-secondary-container:focus,
    .\!md\:checked\:bg-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 221, 185, var(--tw-bg-opacity)) !important;
    }

    .md\:border-secondary-container,
    .md\:hover\:border-secondary-container:hover,
    .md\:focus\:border-secondary-container:focus,
    .md\:checked\:border-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 221, 185, var(--tw-border-opacity));
    }

    .\!md\:border-secondary-container,
    .\!md\:hover\:border-secondary-container:hover,
    .\!md\:focus\:border-secondary-container:focus,
    .\!md\:checked\:border-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 221, 185, var(--tw-border-opacity)) !important;
    }

    .md\:ring-secondary-container,
    .md\:hover\:ring-secondary-container:hover,
    .md\:focus\:ring-secondary-container:focus,
    .md\:checked\:ring-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 221, 185, var(--tw-ring-opacity));
    }

    .\!md\:ring-secondary-container,
    .\!md\:hover\:ring-secondary-container:hover,
    .\!md\:focus\:ring-secondary-container:focus,
    .\!md\:checked\:ring-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 221, 185, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-secondary-container,
    .lg\:hover\:text-secondary-container:hover,
    .lg\:focus\:text-secondary-container:focus,
    .lg\:checked\:text-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 221, 185, var(--tw-text-opacity));
    }

    .\!lg\:text-secondary-container,
    .\!lg\:hover\:text-secondary-container:hover,
    .\!lg\:focus\:text-secondary-container:focus,
    .\!lg\:checked\:text-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 221, 185, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-secondary-container,
    .lg\:hover\:bg-secondary-container:hover,
    .lg\:focus\:bg-secondary-container:focus,
    .lg\:checked\:bg-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 221, 185, var(--tw-bg-opacity));
    }

    .\!lg\:bg-secondary-container,
    .\!lg\:hover\:bg-secondary-container:hover,
    .\!lg\:focus\:bg-secondary-container:focus,
    .\!lg\:checked\:bg-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 221, 185, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-secondary-container,
    .lg\:hover\:border-secondary-container:hover,
    .lg\:focus\:border-secondary-container:focus,
    .lg\:checked\:border-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 221, 185, var(--tw-border-opacity));
    }

    .\!lg\:border-secondary-container,
    .\!lg\:hover\:border-secondary-container:hover,
    .\!lg\:focus\:border-secondary-container:focus,
    .\!lg\:checked\:border-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 221, 185, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-secondary-container,
    .lg\:hover\:ring-secondary-container:hover,
    .lg\:focus\:ring-secondary-container:focus,
    .lg\:checked\:ring-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 221, 185, var(--tw-ring-opacity));
    }

    .\!lg\:ring-secondary-container,
    .\!lg\:hover\:ring-secondary-container:hover,
    .\!lg\:focus\:ring-secondary-container:focus,
    .\!lg\:checked\:ring-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 221, 185, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-secondary-container,
    .xl\:hover\:text-secondary-container:hover,
    .xl\:focus\:text-secondary-container:focus,
    .xl\:checked\:text-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 221, 185, var(--tw-text-opacity));
    }

    .\!xl\:text-secondary-container,
    .\!xl\:hover\:text-secondary-container:hover,
    .\!xl\:focus\:text-secondary-container:focus,
    .\!xl\:checked\:text-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 221, 185, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-secondary-container,
    .xl\:hover\:bg-secondary-container:hover,
    .xl\:focus\:bg-secondary-container:focus,
    .xl\:checked\:bg-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 221, 185, var(--tw-bg-opacity));
    }

    .\!xl\:bg-secondary-container,
    .\!xl\:hover\:bg-secondary-container:hover,
    .\!xl\:focus\:bg-secondary-container:focus,
    .\!xl\:checked\:bg-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 221, 185, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-secondary-container,
    .xl\:hover\:border-secondary-container:hover,
    .xl\:focus\:border-secondary-container:focus,
    .xl\:checked\:border-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 221, 185, var(--tw-border-opacity));
    }

    .\!xl\:border-secondary-container,
    .\!xl\:hover\:border-secondary-container:hover,
    .\!xl\:focus\:border-secondary-container:focus,
    .\!xl\:checked\:border-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 221, 185, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-secondary-container,
    .xl\:hover\:ring-secondary-container:hover,
    .xl\:focus\:ring-secondary-container:focus,
    .xl\:checked\:ring-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 221, 185, var(--tw-ring-opacity));
    }

    .\!xl\:ring-secondary-container,
    .\!xl\:hover\:ring-secondary-container:hover,
    .\!xl\:focus\:ring-secondary-container:focus,
    .\!xl\:checked\:ring-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 221, 185, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-secondary-container,
    .\2xl\:hover\:text-secondary-container:hover,
    .\2xl\:focus\:text-secondary-container:focus,
    .\2xl\:checked\:text-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 221, 185, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-secondary-container,
    .\!2xlsm\:hover\:text-secondary-container:hover,
    .\!2xlsm\:focus\:text-secondary-container:focus,
    .\!2xlsm\:checked\:text-secondary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 221, 185, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-secondary-container,
    .\2xl\:hover\:bg-secondary-container:hover,
    .\2xl\:focus\:bg-secondary-container:focus,
    .\2xl\:checked\:bg-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 221, 185, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-secondary-container,
    .\!2xlsm\:hover\:bg-secondary-container:hover,
    .\!2xlsm\:focus\:bg-secondary-container:focus,
    .\!2xlsm\:checked\:bg-secondary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 221, 185, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-secondary-container,
    .\2xl\:hover\:border-secondary-container:hover,
    .\2xl\:focus\:border-secondary-container:focus,
    .\2xl\:checked\:border-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 221, 185, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-secondary-container,
    .\!2xlsm\:hover\:border-secondary-container:hover,
    .\!2xlsm\:focus\:border-secondary-container:focus,
    .\!2xlsm\:checked\:border-secondary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 221, 185, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-secondary-container,
    .\2xl\:hover\:ring-secondary-container:hover,
    .\2xl\:focus\:ring-secondary-container:focus,
    .\2xl\:checked\:ring-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 221, 185, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-secondary-container,
    .\!2xl\:hover\:ring-secondary-container:hover,
    .\!2xl\:focus\:ring-secondary-container:focus,
    .\!2xl\:checked\:ring-secondary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 221, 185, var(--tw-ring-opacity)) !important;
    }
}

//tertiary-container
.text-tertiary-container,
.hover\:text-tertiary-container:hover,
.focus\:text-tertiary-container:focus,
.checked\:text-tertiary-container:checked {
    --tw-text-opacity: 1;
    color: rgb(188, 233, 255, var(--tw-text-opacity));
}

.\!text-tertiary-container,
.\!hover\:text-tertiary-container:hover,
.\!focus\:text-tertiary-container:focus,
.\!checked\:text-tertiary-container:checked {
    --tw-text-opacity: 1;
    color: rgb(188, 233, 255, var(--tw-text-opacity)) !important;
}

.bg-tertiary-container,
.hover\:bg-tertiary-container:hover,
.focus\:bg-tertiary-container:focus,
.checked\:bg-tertiary-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(188, 233, 255, var(--tw-bg-opacity));
}

.\!bg-tertiary-container,
.\!hover\:bg-tertiary-container:hover,
.\!focus\:bg-tertiary-container:focus,
.\!checked\:bg-tertiary-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(188, 233, 255, var(--tw-bg-opacity)) !important;
}

.border-tertiary-container,
.hover\:border-tertiary-container:hover,
.focus\:border-tertiary-container:focus,
.checked\:border-tertiary-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(188, 233, 255, var(--tw-border-opacity));
}

.\!border-tertiary-container,
.\!hover\:border-tertiary-container:hover,
.\!focus\:border-tertiary-container:focus,
.\!checked\:border-tertiary-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(188, 233, 255, var(--tw-border-opacity)) !important;
}

.ring-tertiary-container,
.hover\:ring-tertiary-container:hover,
.focus\:ring-tertiary-container:focus,
.checked\:ring-tertiary-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(188, 233, 255, var(--tw-ring-opacity));
}

.\!ring-tertiary-container,
.\!hover\:ring-tertiary-container:hover,
.\!focus\:ring-tertiary-container:focus,
.\!checked\:ring-tertiary-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(188, 233, 255, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-tertiary-container,
    .sm\:hover\:text-tertiary-container:hover,
    .sm\:focus\:text-tertiary-container:focus,
    .sm\:checked\:text-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(188, 233, 255, var(--tw-text-opacity));
    }

    .\!sm\:text-tertiary-container,
    .\!sm\:hover\:text-tertiary-container:hover,
    .\!sm\:focus\:text-tertiary-container:focus,
    .\!sm\:checked\:text-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(188, 233, 255, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-tertiary-container,
    .sm\:hover\:bg-tertiary-container:hover,
    .sm\:focus\:bg-tertiary-container:focus,
    .sm\:checked\:bg-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(188, 233, 255, var(--tw-bg-opacity));
    }

    .\!sm\:bg-tertiary-container,
    .\!sm\:hover\:bg-tertiary-container:hover,
    .\!sm\:focus\:bg-tertiary-container:focus,
    .\!sm\:checked\:bg-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(188, 233, 255, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-tertiary-container,
    .sm\:hover\:border-tertiary-container:hover,
    .sm\:focus\:border-tertiary-container:focus,
    .sm\:checked\:border-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(188, 233, 255, var(--tw-border-opacity));
    }

    .\!sm\:border-tertiary-container,
    .\!sm\:hover\:border-tertiary-container:hover,
    .\!sm\:focus\:border-tertiary-container:focus,
    .\!sm\:checked\:border-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(188, 233, 255, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-tertiary-container,
    .sm\:hover\:ring-tertiary-container:hover,
    .sm\:focus\:ring-tertiary-container:focus,
    .sm\:checked\:ring-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(188, 233, 255, var(--tw-ring-opacity));
    }

    .\!sm\:ring-tertiary-container,
    .\!sm\:hover\:ring-tertiary-container:hover,
    .\!sm\:focus\:ring-tertiary-container:focus,
    .\!sm\:checked\:ring-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(188, 233, 255, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-tertiary-container,
    .md\:hover\:text-tertiary-container:hover,
    .md\:focus\:text-tertiary-container:focus,
    .md\:checked\:text-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(188, 233, 255, var(--tw-text-opacity));
    }

    .\!md\:text-tertiary-container,
    .\!md\:hover\:text-tertiary-container:hover,
    .\!md\:focus\:text-tertiary-container:focus,
    .\!md\:checked\:text-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(188, 233, 255, var(--tw-text-opacity)) !important;
    }

    .md\:bg-tertiary-container,
    .md\:hover\:bg-tertiary-container:hover,
    .md\:focus\:bg-tertiary-container:focus,
    .md\:checked\:bg-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(188, 233, 255, var(--tw-bg-opacity));
    }

    .\!md\:bg-tertiary-container,
    .\!md\:hover\:bg-tertiary-container:hover,
    .\!md\:focus\:bg-tertiary-container:focus,
    .\!md\:checked\:bg-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(188, 233, 255, var(--tw-bg-opacity)) !important;
    }

    .md\:border-tertiary-container,
    .md\:hover\:border-tertiary-container:hover,
    .md\:focus\:border-tertiary-container:focus,
    .md\:checked\:border-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(188, 233, 255, var(--tw-border-opacity));
    }

    .\!md\:border-tertiary-container,
    .\!md\:hover\:border-tertiary-container:hover,
    .\!md\:focus\:border-tertiary-container:focus,
    .\!md\:checked\:border-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(188, 233, 255, var(--tw-border-opacity)) !important;
    }

    .md\:ring-tertiary-container,
    .md\:hover\:ring-tertiary-container:hover,
    .md\:focus\:ring-tertiary-container:focus,
    .md\:checked\:ring-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(188, 233, 255, var(--tw-ring-opacity));
    }

    .\!md\:ring-tertiary-container,
    .\!md\:hover\:ring-tertiary-container:hover,
    .\!md\:focus\:ring-tertiary-container:focus,
    .\!md\:checked\:ring-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(188, 233, 255, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-tertiary-container,
    .lg\:hover\:text-tertiary-container:hover,
    .lg\:focus\:text-tertiary-container:focus,
    .lg\:checked\:text-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(188, 233, 255, var(--tw-text-opacity));
    }

    .\!lg\:text-tertiary-container,
    .\!lg\:hover\:text-tertiary-container:hover,
    .\!lg\:focus\:text-tertiary-container:focus,
    .\!lg\:checked\:text-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(188, 233, 255, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-tertiary-container,
    .lg\:hover\:bg-tertiary-container:hover,
    .lg\:focus\:bg-tertiary-container:focus,
    .lg\:checked\:bg-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(188, 233, 255, var(--tw-bg-opacity));
    }

    .\!lg\:bg-tertiary-container,
    .\!lg\:hover\:bg-tertiary-container:hover,
    .\!lg\:focus\:bg-tertiary-container:focus,
    .\!lg\:checked\:bg-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(188, 233, 255, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-tertiary-container,
    .lg\:hover\:border-tertiary-container:hover,
    .lg\:focus\:border-tertiary-container:focus,
    .lg\:checked\:border-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(188, 233, 255, var(--tw-border-opacity));
    }

    .\!lg\:border-tertiary-container,
    .\!lg\:hover\:border-tertiary-container:hover,
    .\!lg\:focus\:border-tertiary-container:focus,
    .\!lg\:checked\:border-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(188, 233, 255, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-tertiary-container,
    .lg\:hover\:ring-tertiary-container:hover,
    .lg\:focus\:ring-tertiary-container:focus,
    .lg\:checked\:ring-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(188, 233, 255, var(--tw-ring-opacity));
    }

    .\!lg\:ring-tertiary-container,
    .\!lg\:hover\:ring-tertiary-container:hover,
    .\!lg\:focus\:ring-tertiary-container:focus,
    .\!lg\:checked\:ring-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(188, 233, 255, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-tertiary-container,
    .xl\:hover\:text-tertiary-container:hover,
    .xl\:focus\:text-tertiary-container:focus,
    .xl\:checked\:text-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(188, 233, 255, var(--tw-text-opacity));
    }

    .\!xl\:text-tertiary-container,
    .\!xl\:hover\:text-tertiary-container:hover,
    .\!xl\:focus\:text-tertiary-container:focus,
    .\!xl\:checked\:text-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(188, 233, 255, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-tertiary-container,
    .xl\:hover\:bg-tertiary-container:hover,
    .xl\:focus\:bg-tertiary-container:focus,
    .xl\:checked\:bg-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(188, 233, 255, var(--tw-bg-opacity));
    }

    .\!xl\:bg-tertiary-container,
    .\!xl\:hover\:bg-tertiary-container:hover,
    .\!xl\:focus\:bg-tertiary-container:focus,
    .\!xl\:checked\:bg-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(188, 233, 255, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-tertiary-container,
    .xl\:hover\:border-tertiary-container:hover,
    .xl\:focus\:border-tertiary-container:focus,
    .xl\:checked\:border-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(188, 233, 255, var(--tw-border-opacity));
    }

    .\!xl\:border-tertiary-container,
    .\!xl\:hover\:border-tertiary-container:hover,
    .\!xl\:focus\:border-tertiary-container:focus,
    .\!xl\:checked\:border-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(188, 233, 255, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-tertiary-container,
    .xl\:hover\:ring-tertiary-container:hover,
    .xl\:focus\:ring-tertiary-container:focus,
    .xl\:checked\:ring-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(188, 233, 255, var(--tw-ring-opacity));
    }

    .\!xl\:ring-tertiary-container,
    .\!xl\:hover\:ring-tertiary-container:hover,
    .\!xl\:focus\:ring-tertiary-container:focus,
    .\!xl\:checked\:ring-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(188, 233, 255, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-tertiary-container,
    .\2xl\:hover\:text-tertiary-container:hover,
    .\2xl\:focus\:text-tertiary-container:focus,
    .\2xl\:checked\:text-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(188, 233, 255, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-tertiary-container,
    .\!2xlsm\:hover\:text-tertiary-container:hover,
    .\!2xlsm\:focus\:text-tertiary-container:focus,
    .\!2xlsm\:checked\:text-tertiary-container:checked {
        --tw-text-opacity: 1;
        color: rgb(188, 233, 255, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-tertiary-container,
    .\2xl\:hover\:bg-tertiary-container:hover,
    .\2xl\:focus\:bg-tertiary-container:focus,
    .\2xl\:checked\:bg-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(188, 233, 255, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-tertiary-container,
    .\!2xlsm\:hover\:bg-tertiary-container:hover,
    .\!2xlsm\:focus\:bg-tertiary-container:focus,
    .\!2xlsm\:checked\:bg-tertiary-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(188, 233, 255, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-tertiary-container,
    .\2xl\:hover\:border-tertiary-container:hover,
    .\2xl\:focus\:border-tertiary-container:focus,
    .\2xl\:checked\:border-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(188, 233, 255, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-tertiary-container,
    .\!2xlsm\:hover\:border-tertiary-container:hover,
    .\!2xlsm\:focus\:border-tertiary-container:focus,
    .\!2xlsm\:checked\:border-tertiary-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(188, 233, 255, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-tertiary-container,
    .\2xl\:hover\:ring-tertiary-container:hover,
    .\2xl\:focus\:ring-tertiary-container:focus,
    .\2xl\:checked\:ring-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(188, 233, 255, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-tertiary-container,
    .\!2xl\:hover\:ring-tertiary-container:hover,
    .\!2xl\:focus\:ring-tertiary-container:focus,
    .\!2xl\:checked\:ring-tertiary-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(188, 233, 255, var(--tw-ring-opacity)) !important;
    }
}

//error-container
.text-error-container,
.hover\:text-error-container:hover,
.focus\:text-error-container:focus,
.checked\:text-error-container:checked {
    --tw-text-opacity: 1;
    color: rgb(255, 218, 214, var(--tw-text-opacity));
}

.\!text-error-container,
.\!hover\:text-error-container:hover,
.\!focus\:text-error-container:focus,
.\!checked\:text-error-container:checked {
    --tw-text-opacity: 1;
    color: rgb(255, 218, 214, var(--tw-text-opacity)) !important;
}

.bg-error-container,
.hover\:bg-error-container:hover,
.focus\:bg-error-container:focus,
.checked\:bg-error-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(255, 218, 214, var(--tw-bg-opacity));
}

.\!bg-error-container,
.\!hover\:bg-error-container:hover,
.\!focus\:bg-error-container:focus,
.\!checked\:bg-error-container:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(255, 218, 214, var(--tw-bg-opacity)) !important;
}

.border-error-container,
.hover\:border-error-container:hover,
.focus\:border-error-container:focus,
.checked\:border-error-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(255, 218, 214, var(--tw-border-opacity));
}

.\!border-error-container,
.\!hover\:border-error-container:hover,
.\!focus\:border-error-container:focus,
.\!checked\:border-error-container:checked {
    --tw-border-opacity: 1;
    border-color: rgb(255, 218, 214, var(--tw-border-opacity)) !important;
}

.ring-error-container,
.hover\:ring-error-container:hover,
.focus\:ring-error-container:focus,
.checked\:ring-error-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255, 218, 214, var(--tw-ring-opacity));
}

.\!ring-error-container,
.\!hover\:ring-error-container:hover,
.\!focus\:ring-error-container:focus,
.\!checked\:ring-error-container:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255, 218, 214, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-error-container,
    .sm\:hover\:text-error-container:hover,
    .sm\:focus\:text-error-container:focus,
    .sm\:checked\:text-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 218, 214, var(--tw-text-opacity));
    }

    .\!sm\:text-error-container,
    .\!sm\:hover\:text-error-container:hover,
    .\!sm\:focus\:text-error-container:focus,
    .\!sm\:checked\:text-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 218, 214, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-error-container,
    .sm\:hover\:bg-error-container:hover,
    .sm\:focus\:bg-error-container:focus,
    .sm\:checked\:bg-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 218, 214, var(--tw-bg-opacity));
    }

    .\!sm\:bg-error-container,
    .\!sm\:hover\:bg-error-container:hover,
    .\!sm\:focus\:bg-error-container:focus,
    .\!sm\:checked\:bg-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 218, 214, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-error-container,
    .sm\:hover\:border-error-container:hover,
    .sm\:focus\:border-error-container:focus,
    .sm\:checked\:border-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 218, 214, var(--tw-border-opacity));
    }

    .\!sm\:border-error-container,
    .\!sm\:hover\:border-error-container:hover,
    .\!sm\:focus\:border-error-container:focus,
    .\!sm\:checked\:border-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 218, 214, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-error-container,
    .sm\:hover\:ring-error-container:hover,
    .sm\:focus\:ring-error-container:focus,
    .sm\:checked\:ring-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 218, 214, var(--tw-ring-opacity));
    }

    .\!sm\:ring-error-container,
    .\!sm\:hover\:ring-error-container:hover,
    .\!sm\:focus\:ring-error-container:focus,
    .\!sm\:checked\:ring-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 218, 214, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-error-container,
    .md\:hover\:text-error-container:hover,
    .md\:focus\:text-error-container:focus,
    .md\:checked\:text-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 218, 214, var(--tw-text-opacity));
    }

    .\!md\:text-error-container,
    .\!md\:hover\:text-error-container:hover,
    .\!md\:focus\:text-error-container:focus,
    .\!md\:checked\:text-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 218, 214, var(--tw-text-opacity)) !important;
    }

    .md\:bg-error-container,
    .md\:hover\:bg-error-container:hover,
    .md\:focus\:bg-error-container:focus,
    .md\:checked\:bg-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 218, 214, var(--tw-bg-opacity));
    }

    .\!md\:bg-error-container,
    .\!md\:hover\:bg-error-container:hover,
    .\!md\:focus\:bg-error-container:focus,
    .\!md\:checked\:bg-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 218, 214, var(--tw-bg-opacity)) !important;
    }

    .md\:border-error-container,
    .md\:hover\:border-error-container:hover,
    .md\:focus\:border-error-container:focus,
    .md\:checked\:border-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 218, 214, var(--tw-border-opacity));
    }

    .\!md\:border-error-container,
    .\!md\:hover\:border-error-container:hover,
    .\!md\:focus\:border-error-container:focus,
    .\!md\:checked\:border-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 218, 214, var(--tw-border-opacity)) !important;
    }

    .md\:ring-error-container,
    .md\:hover\:ring-error-container:hover,
    .md\:focus\:ring-error-container:focus,
    .md\:checked\:ring-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 218, 214, var(--tw-ring-opacity));
    }

    .\!md\:ring-error-container,
    .\!md\:hover\:ring-error-container:hover,
    .\!md\:focus\:ring-error-container:focus,
    .\!md\:checked\:ring-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 218, 214, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-error-container,
    .lg\:hover\:text-error-container:hover,
    .lg\:focus\:text-error-container:focus,
    .lg\:checked\:text-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 218, 214, var(--tw-text-opacity));
    }

    .\!lg\:text-error-container,
    .\!lg\:hover\:text-error-container:hover,
    .\!lg\:focus\:text-error-container:focus,
    .\!lg\:checked\:text-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 218, 214, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-error-container,
    .lg\:hover\:bg-error-container:hover,
    .lg\:focus\:bg-error-container:focus,
    .lg\:checked\:bg-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 218, 214, var(--tw-bg-opacity));
    }

    .\!lg\:bg-error-container,
    .\!lg\:hover\:bg-error-container:hover,
    .\!lg\:focus\:bg-error-container:focus,
    .\!lg\:checked\:bg-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 218, 214, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-error-container,
    .lg\:hover\:border-error-container:hover,
    .lg\:focus\:border-error-container:focus,
    .lg\:checked\:border-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 218, 214, var(--tw-border-opacity));
    }

    .\!lg\:border-error-container,
    .\!lg\:hover\:border-error-container:hover,
    .\!lg\:focus\:border-error-container:focus,
    .\!lg\:checked\:border-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 218, 214, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-error-container,
    .lg\:hover\:ring-error-container:hover,
    .lg\:focus\:ring-error-container:focus,
    .lg\:checked\:ring-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 218, 214, var(--tw-ring-opacity));
    }

    .\!lg\:ring-error-container,
    .\!lg\:hover\:ring-error-container:hover,
    .\!lg\:focus\:ring-error-container:focus,
    .\!lg\:checked\:ring-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 218, 214, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-error-container,
    .xl\:hover\:text-error-container:hover,
    .xl\:focus\:text-error-container:focus,
    .xl\:checked\:text-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 218, 214, var(--tw-text-opacity));
    }

    .\!xl\:text-error-container,
    .\!xl\:hover\:text-error-container:hover,
    .\!xl\:focus\:text-error-container:focus,
    .\!xl\:checked\:text-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 218, 214, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-error-container,
    .xl\:hover\:bg-error-container:hover,
    .xl\:focus\:bg-error-container:focus,
    .xl\:checked\:bg-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 218, 214, var(--tw-bg-opacity));
    }

    .\!xl\:bg-error-container,
    .\!xl\:hover\:bg-error-container:hover,
    .\!xl\:focus\:bg-error-container:focus,
    .\!xl\:checked\:bg-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 218, 214, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-error-container,
    .xl\:hover\:border-error-container:hover,
    .xl\:focus\:border-error-container:focus,
    .xl\:checked\:border-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 218, 214, var(--tw-border-opacity));
    }

    .\!xl\:border-error-container,
    .\!xl\:hover\:border-error-container:hover,
    .\!xl\:focus\:border-error-container:focus,
    .\!xl\:checked\:border-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 218, 214, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-error-container,
    .xl\:hover\:ring-error-container:hover,
    .xl\:focus\:ring-error-container:focus,
    .xl\:checked\:ring-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 218, 214, var(--tw-ring-opacity));
    }

    .\!xl\:ring-error-container,
    .\!xl\:hover\:ring-error-container:hover,
    .\!xl\:focus\:ring-error-container:focus,
    .\!xl\:checked\:ring-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 218, 214, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-error-container,
    .\2xl\:hover\:text-error-container:hover,
    .\2xl\:focus\:text-error-container:focus,
    .\2xl\:checked\:text-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 218, 214, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-error-container,
    .\!2xlsm\:hover\:text-error-container:hover,
    .\!2xlsm\:focus\:text-error-container:focus,
    .\!2xlsm\:checked\:text-error-container:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 218, 214, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-error-container,
    .\2xl\:hover\:bg-error-container:hover,
    .\2xl\:focus\:bg-error-container:focus,
    .\2xl\:checked\:bg-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 218, 214, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-error-container,
    .\!2xlsm\:hover\:bg-error-container:hover,
    .\!2xlsm\:focus\:bg-error-container:focus,
    .\!2xlsm\:checked\:bg-error-container:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 218, 214, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-error-container,
    .\2xl\:hover\:border-error-container:hover,
    .\2xl\:focus\:border-error-container:focus,
    .\2xl\:checked\:border-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 218, 214, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-error-container,
    .\!2xlsm\:hover\:border-error-container:hover,
    .\!2xlsm\:focus\:border-error-container:focus,
    .\!2xlsm\:checked\:border-error-container:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 218, 214, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-error-container,
    .\2xl\:hover\:ring-error-container:hover,
    .\2xl\:focus\:ring-error-container:focus,
    .\2xl\:checked\:ring-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 218, 214, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-error-container,
    .\!2xl\:hover\:ring-error-container:hover,
    .\!2xl\:focus\:ring-error-container:focus,
    .\!2xl\:checked\:ring-error-container:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 218, 214, var(--tw-ring-opacity)) !important;
    }
}

//surface-variant
.text-surface-variant,
.hover\:text-surface-variant:hover,
.focus\:text-surface-variant:focus,
.checked\:text-surface-variant:checked {
    --tw-text-opacity: 1;
    color: rgb(243, 222, 211, var(--tw-text-opacity));
}

.\!text-surface-variant,
.\!hover\:text-surface-variant:hover,
.\!focus\:text-surface-variant:focus,
.\!checked\:text-surface-variant:checked {
    --tw-text-opacity: 1;
    color: rgb(243, 222, 211, var(--tw-text-opacity)) !important;
}

.bg-surface-variant,
.hover\:bg-surface-variant:hover,
.focus\:bg-surface-variant:focus,
.checked\:bg-surface-variant:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(243, 222, 211, var(--tw-bg-opacity));
}

.\!bg-surface-variant,
.\!hover\:bg-surface-variant:hover,
.\!focus\:bg-surface-variant:focus,
.\!checked\:bg-surface-variant:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(243, 222, 211, var(--tw-bg-opacity)) !important;
}

.border-surface-variant,
.hover\:border-surface-variant:hover,
.focus\:border-surface-variant:focus,
.checked\:border-surface-variant:checked {
    --tw-border-opacity: 1;
    border-color: rgb(243, 222, 211, var(--tw-border-opacity));
}

.\!border-surface-variant,
.\!hover\:border-surface-variant:hover,
.\!focus\:border-surface-variant:focus,
.\!checked\:border-surface-variant:checked {
    --tw-border-opacity: 1;
    border-color: rgb(243, 222, 211, var(--tw-border-opacity)) !important;
}

.ring-surface-variant,
.hover\:ring-surface-variant:hover,
.focus\:ring-surface-variant:focus,
.checked\:ring-surface-variant:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243, 222, 211, var(--tw-ring-opacity));
}

.\!ring-surface-variant,
.\!hover\:ring-surface-variant:hover,
.\!focus\:ring-surface-variant:focus,
.\!checked\:ring-surface-variant:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243, 222, 211, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-surface-variant,
    .sm\:hover\:text-surface-variant:hover,
    .sm\:focus\:text-surface-variant:focus,
    .sm\:checked\:text-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(243, 222, 211, var(--tw-text-opacity));
    }

    .\!sm\:text-surface-variant,
    .\!sm\:hover\:text-surface-variant:hover,
    .\!sm\:focus\:text-surface-variant:focus,
    .\!sm\:checked\:text-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(243, 222, 211, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-surface-variant,
    .sm\:hover\:bg-surface-variant:hover,
    .sm\:focus\:bg-surface-variant:focus,
    .sm\:checked\:bg-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(243, 222, 211, var(--tw-bg-opacity));
    }

    .\!sm\:bg-surface-variant,
    .\!sm\:hover\:bg-surface-variant:hover,
    .\!sm\:focus\:bg-surface-variant:focus,
    .\!sm\:checked\:bg-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(243, 222, 211, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-surface-variant,
    .sm\:hover\:border-surface-variant:hover,
    .sm\:focus\:border-surface-variant:focus,
    .sm\:checked\:border-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(243, 222, 211, var(--tw-border-opacity));
    }

    .\!sm\:border-surface-variant,
    .\!sm\:hover\:border-surface-variant:hover,
    .\!sm\:focus\:border-surface-variant:focus,
    .\!sm\:checked\:border-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(243, 222, 211, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-surface-variant,
    .sm\:hover\:ring-surface-variant:hover,
    .sm\:focus\:ring-surface-variant:focus,
    .sm\:checked\:ring-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(243, 222, 211, var(--tw-ring-opacity));
    }

    .\!sm\:ring-surface-variant,
    .\!sm\:hover\:ring-surface-variant:hover,
    .\!sm\:focus\:ring-surface-variant:focus,
    .\!sm\:checked\:ring-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(243, 222, 211, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-surface-variant,
    .md\:hover\:text-surface-variant:hover,
    .md\:focus\:text-surface-variant:focus,
    .md\:checked\:text-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(243, 222, 211, var(--tw-text-opacity));
    }

    .\!md\:text-surface-variant,
    .\!md\:hover\:text-surface-variant:hover,
    .\!md\:focus\:text-surface-variant:focus,
    .\!md\:checked\:text-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(243, 222, 211, var(--tw-text-opacity)) !important;
    }

    .md\:bg-surface-variant,
    .md\:hover\:bg-surface-variant:hover,
    .md\:focus\:bg-surface-variant:focus,
    .md\:checked\:bg-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(243, 222, 211, var(--tw-bg-opacity));
    }

    .\!md\:bg-surface-variant,
    .\!md\:hover\:bg-surface-variant:hover,
    .\!md\:focus\:bg-surface-variant:focus,
    .\!md\:checked\:bg-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(243, 222, 211, var(--tw-bg-opacity)) !important;
    }

    .md\:border-surface-variant,
    .md\:hover\:border-surface-variant:hover,
    .md\:focus\:border-surface-variant:focus,
    .md\:checked\:border-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(243, 222, 211, var(--tw-border-opacity));
    }

    .\!md\:border-surface-variant,
    .\!md\:hover\:border-surface-variant:hover,
    .\!md\:focus\:border-surface-variant:focus,
    .\!md\:checked\:border-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(243, 222, 211, var(--tw-border-opacity)) !important;
    }

    .md\:ring-surface-variant,
    .md\:hover\:ring-surface-variant:hover,
    .md\:focus\:ring-surface-variant:focus,
    .md\:checked\:ring-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(243, 222, 211, var(--tw-ring-opacity));
    }

    .\!md\:ring-surface-variant,
    .\!md\:hover\:ring-surface-variant:hover,
    .\!md\:focus\:ring-surface-variant:focus,
    .\!md\:checked\:ring-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(243, 222, 211, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-surface-variant,
    .lg\:hover\:text-surface-variant:hover,
    .lg\:focus\:text-surface-variant:focus,
    .lg\:checked\:text-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(243, 222, 211, var(--tw-text-opacity));
    }

    .\!lg\:text-surface-variant,
    .\!lg\:hover\:text-surface-variant:hover,
    .\!lg\:focus\:text-surface-variant:focus,
    .\!lg\:checked\:text-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(243, 222, 211, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-surface-variant,
    .lg\:hover\:bg-surface-variant:hover,
    .lg\:focus\:bg-surface-variant:focus,
    .lg\:checked\:bg-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(243, 222, 211, var(--tw-bg-opacity));
    }

    .\!lg\:bg-surface-variant,
    .\!lg\:hover\:bg-surface-variant:hover,
    .\!lg\:focus\:bg-surface-variant:focus,
    .\!lg\:checked\:bg-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(243, 222, 211, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-surface-variant,
    .lg\:hover\:border-surface-variant:hover,
    .lg\:focus\:border-surface-variant:focus,
    .lg\:checked\:border-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(243, 222, 211, var(--tw-border-opacity));
    }

    .\!lg\:border-surface-variant,
    .\!lg\:hover\:border-surface-variant:hover,
    .\!lg\:focus\:border-surface-variant:focus,
    .\!lg\:checked\:border-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(243, 222, 211, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-surface-variant,
    .lg\:hover\:ring-surface-variant:hover,
    .lg\:focus\:ring-surface-variant:focus,
    .lg\:checked\:ring-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(243, 222, 211, var(--tw-ring-opacity));
    }

    .\!lg\:ring-surface-variant,
    .\!lg\:hover\:ring-surface-variant:hover,
    .\!lg\:focus\:ring-surface-variant:focus,
    .\!lg\:checked\:ring-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(243, 222, 211, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-surface-variant,
    .xl\:hover\:text-surface-variant:hover,
    .xl\:focus\:text-surface-variant:focus,
    .xl\:checked\:text-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(243, 222, 211, var(--tw-text-opacity));
    }

    .\!xl\:text-surface-variant,
    .\!xl\:hover\:text-surface-variant:hover,
    .\!xl\:focus\:text-surface-variant:focus,
    .\!xl\:checked\:text-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(243, 222, 211, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-surface-variant,
    .xl\:hover\:bg-surface-variant:hover,
    .xl\:focus\:bg-surface-variant:focus,
    .xl\:checked\:bg-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(243, 222, 211, var(--tw-bg-opacity));
    }

    .\!xl\:bg-surface-variant,
    .\!xl\:hover\:bg-surface-variant:hover,
    .\!xl\:focus\:bg-surface-variant:focus,
    .\!xl\:checked\:bg-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(243, 222, 211, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-surface-variant,
    .xl\:hover\:border-surface-variant:hover,
    .xl\:focus\:border-surface-variant:focus,
    .xl\:checked\:border-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(243, 222, 211, var(--tw-border-opacity));
    }

    .\!xl\:border-surface-variant,
    .\!xl\:hover\:border-surface-variant:hover,
    .\!xl\:focus\:border-surface-variant:focus,
    .\!xl\:checked\:border-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(243, 222, 211, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-surface-variant,
    .xl\:hover\:ring-surface-variant:hover,
    .xl\:focus\:ring-surface-variant:focus,
    .xl\:checked\:ring-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(243, 222, 211, var(--tw-ring-opacity));
    }

    .\!xl\:ring-surface-variant,
    .\!xl\:hover\:ring-surface-variant:hover,
    .\!xl\:focus\:ring-surface-variant:focus,
    .\!xl\:checked\:ring-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(243, 222, 211, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-surface-variant,
    .\2xl\:hover\:text-surface-variant:hover,
    .\2xl\:focus\:text-surface-variant:focus,
    .\2xl\:checked\:text-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(243, 222, 211, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-surface-variant,
    .\!2xlsm\:hover\:text-surface-variant:hover,
    .\!2xlsm\:focus\:text-surface-variant:focus,
    .\!2xlsm\:checked\:text-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(243, 222, 211, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-surface-variant,
    .\2xl\:hover\:bg-surface-variant:hover,
    .\2xl\:focus\:bg-surface-variant:focus,
    .\2xl\:checked\:bg-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(243, 222, 211, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-surface-variant,
    .\!2xlsm\:hover\:bg-surface-variant:hover,
    .\!2xlsm\:focus\:bg-surface-variant:focus,
    .\!2xlsm\:checked\:bg-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(243, 222, 211, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-surface-variant,
    .\2xl\:hover\:border-surface-variant:hover,
    .\2xl\:focus\:border-surface-variant:focus,
    .\2xl\:checked\:border-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(243, 222, 211, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-surface-variant,
    .\!2xlsm\:hover\:border-surface-variant:hover,
    .\!2xlsm\:focus\:border-surface-variant:focus,
    .\!2xlsm\:checked\:border-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(243, 222, 211, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-surface-variant,
    .\2xl\:hover\:ring-surface-variant:hover,
    .\2xl\:focus\:ring-surface-variant:focus,
    .\2xl\:checked\:ring-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(243, 222, 211, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-surface-variant,
    .\!2xl\:hover\:ring-surface-variant:hover,
    .\!2xl\:focus\:ring-surface-variant:focus,
    .\!2xl\:checked\:ring-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(243, 222, 211, var(--tw-ring-opacity)) !important;
    }
}

//on-surface-variant
.text-on-surface-variant,
.hover\:text-on-surface-variant:hover,
.focus\:text-on-surface-variant:focus,
.checked\:text-on-surface-variant:checked {
    --tw-text-opacity: 1;
    color: rgb(82, 68, 59, var(--tw-text-opacity));
}

.\!text-on-surface-variant,
.\!hover\:text-on-surface-variant:hover,
.\!focus\:text-on-surface-variant:focus,
.\!checked\:text-on-surface-variant:checked {
    --tw-text-opacity: 1;
    color: rgb(82, 68, 59, var(--tw-text-opacity)) !important;
}

.bg-on-surface-variant,
.hover\:bg-on-surface-variant:hover,
.focus\:bg-on-surface-variant:focus,
.checked\:bg-on-surface-variant:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(82, 68, 59, var(--tw-bg-opacity));
}

.\!bg-on-surface-variant,
.\!hover\:bg-on-surface-variant:hover,
.\!focus\:bg-on-surface-variant:focus,
.\!checked\:bg-on-surface-variant:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(82, 68, 59, var(--tw-bg-opacity)) !important;
}

.border-on-surface-variant,
.hover\:border-on-surface-variant:hover,
.focus\:border-on-surface-variant:focus,
.checked\:border-on-surface-variant:checked {
    --tw-border-opacity: 1;
    border-color: rgb(82, 68, 59, var(--tw-border-opacity));
}

.\!border-on-surface-variant,
.\!hover\:border-on-surface-variant:hover,
.\!focus\:border-on-surface-variant:focus,
.\!checked\:border-on-surface-variant:checked {
    --tw-border-opacity: 1;
    border-color: rgb(82, 68, 59, var(--tw-border-opacity)) !important;
}

.ring-on-surface-variant,
.hover\:ring-on-surface-variant:hover,
.focus\:ring-on-surface-variant:focus,
.checked\:ring-on-surface-variant:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82, 68, 59, var(--tw-ring-opacity));
}

.\!ring-on-surface-variant,
.\!hover\:ring-on-surface-variant:hover,
.\!focus\:ring-on-surface-variant:focus,
.\!checked\:ring-on-surface-variant:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82, 68, 59, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-on-surface-variant,
    .sm\:hover\:text-on-surface-variant:hover,
    .sm\:focus\:text-on-surface-variant:focus,
    .sm\:checked\:text-on-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(82, 68, 59, var(--tw-text-opacity));
    }

    .\!sm\:text-on-surface-variant,
    .\!sm\:hover\:text-on-surface-variant:hover,
    .\!sm\:focus\:text-on-surface-variant:focus,
    .\!sm\:checked\:text-on-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(82, 68, 59, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-on-surface-variant,
    .sm\:hover\:bg-on-surface-variant:hover,
    .sm\:focus\:bg-on-surface-variant:focus,
    .sm\:checked\:bg-on-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(82, 68, 59, var(--tw-bg-opacity));
    }

    .\!sm\:bg-on-surface-variant,
    .\!sm\:hover\:bg-on-surface-variant:hover,
    .\!sm\:focus\:bg-on-surface-variant:focus,
    .\!sm\:checked\:bg-on-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(82, 68, 59, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-on-surface-variant,
    .sm\:hover\:border-on-surface-variant:hover,
    .sm\:focus\:border-on-surface-variant:focus,
    .sm\:checked\:border-on-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(82, 68, 59, var(--tw-border-opacity));
    }

    .\!sm\:border-on-surface-variant,
    .\!sm\:hover\:border-on-surface-variant:hover,
    .\!sm\:focus\:border-on-surface-variant:focus,
    .\!sm\:checked\:border-on-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(82, 68, 59, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-on-surface-variant,
    .sm\:hover\:ring-on-surface-variant:hover,
    .sm\:focus\:ring-on-surface-variant:focus,
    .sm\:checked\:ring-on-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(82, 68, 59, var(--tw-ring-opacity));
    }

    .\!sm\:ring-on-surface-variant,
    .\!sm\:hover\:ring-on-surface-variant:hover,
    .\!sm\:focus\:ring-on-surface-variant:focus,
    .\!sm\:checked\:ring-on-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(82, 68, 59, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-on-surface-variant,
    .md\:hover\:text-on-surface-variant:hover,
    .md\:focus\:text-on-surface-variant:focus,
    .md\:checked\:text-on-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(82, 68, 59, var(--tw-text-opacity));
    }

    .\!md\:text-on-surface-variant,
    .\!md\:hover\:text-on-surface-variant:hover,
    .\!md\:focus\:text-on-surface-variant:focus,
    .\!md\:checked\:text-on-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(82, 68, 59, var(--tw-text-opacity)) !important;
    }

    .md\:bg-on-surface-variant,
    .md\:hover\:bg-on-surface-variant:hover,
    .md\:focus\:bg-on-surface-variant:focus,
    .md\:checked\:bg-on-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(82, 68, 59, var(--tw-bg-opacity));
    }

    .\!md\:bg-on-surface-variant,
    .\!md\:hover\:bg-on-surface-variant:hover,
    .\!md\:focus\:bg-on-surface-variant:focus,
    .\!md\:checked\:bg-on-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(82, 68, 59, var(--tw-bg-opacity)) !important;
    }

    .md\:border-on-surface-variant,
    .md\:hover\:border-on-surface-variant:hover,
    .md\:focus\:border-on-surface-variant:focus,
    .md\:checked\:border-on-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(82, 68, 59, var(--tw-border-opacity));
    }

    .\!md\:border-on-surface-variant,
    .\!md\:hover\:border-on-surface-variant:hover,
    .\!md\:focus\:border-on-surface-variant:focus,
    .\!md\:checked\:border-on-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(82, 68, 59, var(--tw-border-opacity)) !important;
    }

    .md\:ring-on-surface-variant,
    .md\:hover\:ring-on-surface-variant:hover,
    .md\:focus\:ring-on-surface-variant:focus,
    .md\:checked\:ring-on-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(82, 68, 59, var(--tw-ring-opacity));
    }

    .\!md\:ring-on-surface-variant,
    .\!md\:hover\:ring-on-surface-variant:hover,
    .\!md\:focus\:ring-on-surface-variant:focus,
    .\!md\:checked\:ring-on-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(82, 68, 59, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-on-surface-variant,
    .lg\:hover\:text-on-surface-variant:hover,
    .lg\:focus\:text-on-surface-variant:focus,
    .lg\:checked\:text-on-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(82, 68, 59, var(--tw-text-opacity));
    }

    .\!lg\:text-on-surface-variant,
    .\!lg\:hover\:text-on-surface-variant:hover,
    .\!lg\:focus\:text-on-surface-variant:focus,
    .\!lg\:checked\:text-on-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(82, 68, 59, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-on-surface-variant,
    .lg\:hover\:bg-on-surface-variant:hover,
    .lg\:focus\:bg-on-surface-variant:focus,
    .lg\:checked\:bg-on-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(82, 68, 59, var(--tw-bg-opacity));
    }

    .\!lg\:bg-on-surface-variant,
    .\!lg\:hover\:bg-on-surface-variant:hover,
    .\!lg\:focus\:bg-on-surface-variant:focus,
    .\!lg\:checked\:bg-on-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(82, 68, 59, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-on-surface-variant,
    .lg\:hover\:border-on-surface-variant:hover,
    .lg\:focus\:border-on-surface-variant:focus,
    .lg\:checked\:border-on-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(82, 68, 59, var(--tw-border-opacity));
    }

    .\!lg\:border-on-surface-variant,
    .\!lg\:hover\:border-on-surface-variant:hover,
    .\!lg\:focus\:border-on-surface-variant:focus,
    .\!lg\:checked\:border-on-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(82, 68, 59, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-on-surface-variant,
    .lg\:hover\:ring-on-surface-variant:hover,
    .lg\:focus\:ring-on-surface-variant:focus,
    .lg\:checked\:ring-on-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(82, 68, 59, var(--tw-ring-opacity));
    }

    .\!lg\:ring-on-surface-variant,
    .\!lg\:hover\:ring-on-surface-variant:hover,
    .\!lg\:focus\:ring-on-surface-variant:focus,
    .\!lg\:checked\:ring-on-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(82, 68, 59, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-on-surface-variant,
    .xl\:hover\:text-on-surface-variant:hover,
    .xl\:focus\:text-on-surface-variant:focus,
    .xl\:checked\:text-on-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(82, 68, 59, var(--tw-text-opacity));
    }

    .\!xl\:text-on-surface-variant,
    .\!xl\:hover\:text-on-surface-variant:hover,
    .\!xl\:focus\:text-on-surface-variant:focus,
    .\!xl\:checked\:text-on-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(82, 68, 59, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-on-surface-variant,
    .xl\:hover\:bg-on-surface-variant:hover,
    .xl\:focus\:bg-on-surface-variant:focus,
    .xl\:checked\:bg-on-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(82, 68, 59, var(--tw-bg-opacity));
    }

    .\!xl\:bg-on-surface-variant,
    .\!xl\:hover\:bg-on-surface-variant:hover,
    .\!xl\:focus\:bg-on-surface-variant:focus,
    .\!xl\:checked\:bg-on-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(82, 68, 59, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-on-surface-variant,
    .xl\:hover\:border-on-surface-variant:hover,
    .xl\:focus\:border-on-surface-variant:focus,
    .xl\:checked\:border-on-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(82, 68, 59, var(--tw-border-opacity));
    }

    .\!xl\:border-on-surface-variant,
    .\!xl\:hover\:border-on-surface-variant:hover,
    .\!xl\:focus\:border-on-surface-variant:focus,
    .\!xl\:checked\:border-on-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(82, 68, 59, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-on-surface-variant,
    .xl\:hover\:ring-on-surface-variant:hover,
    .xl\:focus\:ring-on-surface-variant:focus,
    .xl\:checked\:ring-on-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(82, 68, 59, var(--tw-ring-opacity));
    }

    .\!xl\:ring-on-surface-variant,
    .\!xl\:hover\:ring-on-surface-variant:hover,
    .\!xl\:focus\:ring-on-surface-variant:focus,
    .\!xl\:checked\:ring-on-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(82, 68, 59, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-on-surface-variant,
    .\2xl\:hover\:text-on-surface-variant:hover,
    .\2xl\:focus\:text-on-surface-variant:focus,
    .\2xl\:checked\:text-on-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(82, 68, 59, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-on-surface-variant,
    .\!2xlsm\:hover\:text-on-surface-variant:hover,
    .\!2xlsm\:focus\:text-on-surface-variant:focus,
    .\!2xlsm\:checked\:text-on-surface-variant:checked {
        --tw-text-opacity: 1;
        color: rgb(82, 68, 59, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-on-surface-variant,
    .\2xl\:hover\:bg-on-surface-variant:hover,
    .\2xl\:focus\:bg-on-surface-variant:focus,
    .\2xl\:checked\:bg-on-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(82, 68, 59, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-on-surface-variant,
    .\!2xlsm\:hover\:bg-on-surface-variant:hover,
    .\!2xlsm\:focus\:bg-on-surface-variant:focus,
    .\!2xlsm\:checked\:bg-on-surface-variant:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(82, 68, 59, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-on-surface-variant,
    .\2xl\:hover\:border-on-surface-variant:hover,
    .\2xl\:focus\:border-on-surface-variant:focus,
    .\2xl\:checked\:border-on-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(82, 68, 59, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-on-surface-variant,
    .\!2xlsm\:hover\:border-on-surface-variant:hover,
    .\!2xlsm\:focus\:border-on-surface-variant:focus,
    .\!2xlsm\:checked\:border-on-surface-variant:checked {
        --tw-border-opacity: 1;
        border-color: rgb(82, 68, 59, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-on-surface-variant,
    .\2xl\:hover\:ring-on-surface-variant:hover,
    .\2xl\:focus\:ring-on-surface-variant:focus,
    .\2xl\:checked\:ring-on-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(82, 68, 59, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-on-surface-variant,
    .\!2xl\:hover\:ring-on-surface-variant:hover,
    .\!2xl\:focus\:ring-on-surface-variant:focus,
    .\!2xl\:checked\:ring-on-surface-variant:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(82, 68, 59, var(--tw-ring-opacity)) !important;
    }
}

//inverse-on-surface
.text-inverse-on-surface,
.hover\:text-inverse-on-surface:hover,
.focus\:text-inverse-on-surface:focus,
.checked\:text-inverse-on-surface:checked {
    --tw-text-opacity: 1;
    color: rgb(255, 237, 235, var(--tw-text-opacity));
}

.\!text-inverse-on-surface,
.\!hover\:text-inverse-on-surface:hover,
.\!focus\:text-inverse-on-surface:focus,
.\!checked\:text-inverse-on-surface:checked {
    --tw-text-opacity: 1;
    color: rgb(255, 237, 235, var(--tw-text-opacity)) !important;
}

.bg-inverse-on-surface,
.hover\:bg-inverse-on-surface:hover,
.focus\:bg-inverse-on-surface:focus,
.checked\:bg-inverse-on-surface:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(255, 237, 235, var(--tw-bg-opacity));
}

.\!bg-inverse-on-surface,
.\!hover\:bg-inverse-on-surface:hover,
.\!focus\:bg-inverse-on-surface:focus,
.\!checked\:bg-inverse-on-surface:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(255, 237, 235, var(--tw-bg-opacity)) !important;
}

.border-inverse-on-surface,
.hover\:border-inverse-on-surface:hover,
.focus\:border-inverse-on-surface:focus,
.checked\:border-inverse-on-surface:checked {
    --tw-border-opacity: 1;
    border-color: rgb(255, 237, 235, var(--tw-border-opacity));
}

.\!border-inverse-on-surface,
.\!hover\:border-inverse-on-surface:hover,
.\!focus\:border-inverse-on-surface:focus,
.\!checked\:border-inverse-on-surface:checked {
    --tw-border-opacity: 1;
    border-color: rgb(255, 237, 235, var(--tw-border-opacity)) !important;
}

.ring-inverse-on-surface,
.hover\:ring-inverse-on-surface:hover,
.focus\:ring-inverse-on-surface:focus,
.checked\:ring-inverse-on-surface:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255, 237, 235, var(--tw-ring-opacity));
}

.\!ring-inverse-on-surface,
.\!hover\:ring-inverse-on-surface:hover,
.\!focus\:ring-inverse-on-surface:focus,
.\!checked\:ring-inverse-on-surface:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255, 237, 235, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-inverse-on-surface,
    .sm\:hover\:text-inverse-on-surface:hover,
    .sm\:focus\:text-inverse-on-surface:focus,
    .sm\:checked\:text-inverse-on-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 237, 235, var(--tw-text-opacity));
    }

    .\!sm\:text-inverse-on-surface,
    .\!sm\:hover\:text-inverse-on-surface:hover,
    .\!sm\:focus\:text-inverse-on-surface:focus,
    .\!sm\:checked\:text-inverse-on-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 237, 235, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-inverse-on-surface,
    .sm\:hover\:bg-inverse-on-surface:hover,
    .sm\:focus\:bg-inverse-on-surface:focus,
    .sm\:checked\:bg-inverse-on-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 237, 235, var(--tw-bg-opacity));
    }

    .\!sm\:bg-inverse-on-surface,
    .\!sm\:hover\:bg-inverse-on-surface:hover,
    .\!sm\:focus\:bg-inverse-on-surface:focus,
    .\!sm\:checked\:bg-inverse-on-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 237, 235, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-inverse-on-surface,
    .sm\:hover\:border-inverse-on-surface:hover,
    .sm\:focus\:border-inverse-on-surface:focus,
    .sm\:checked\:border-inverse-on-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 237, 235, var(--tw-border-opacity));
    }

    .\!sm\:border-inverse-on-surface,
    .\!sm\:hover\:border-inverse-on-surface:hover,
    .\!sm\:focus\:border-inverse-on-surface:focus,
    .\!sm\:checked\:border-inverse-on-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 237, 235, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-inverse-on-surface,
    .sm\:hover\:ring-inverse-on-surface:hover,
    .sm\:focus\:ring-inverse-on-surface:focus,
    .sm\:checked\:ring-inverse-on-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 237, 235, var(--tw-ring-opacity));
    }

    .\!sm\:ring-inverse-on-surface,
    .\!sm\:hover\:ring-inverse-on-surface:hover,
    .\!sm\:focus\:ring-inverse-on-surface:focus,
    .\!sm\:checked\:ring-inverse-on-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 237, 235, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-inverse-on-surface,
    .md\:hover\:text-inverse-on-surface:hover,
    .md\:focus\:text-inverse-on-surface:focus,
    .md\:checked\:text-inverse-on-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 237, 235, var(--tw-text-opacity));
    }

    .\!md\:text-inverse-on-surface,
    .\!md\:hover\:text-inverse-on-surface:hover,
    .\!md\:focus\:text-inverse-on-surface:focus,
    .\!md\:checked\:text-inverse-on-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 237, 235, var(--tw-text-opacity)) !important;
    }

    .md\:bg-inverse-on-surface,
    .md\:hover\:bg-inverse-on-surface:hover,
    .md\:focus\:bg-inverse-on-surface:focus,
    .md\:checked\:bg-inverse-on-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 237, 235, var(--tw-bg-opacity));
    }

    .\!md\:bg-inverse-on-surface,
    .\!md\:hover\:bg-inverse-on-surface:hover,
    .\!md\:focus\:bg-inverse-on-surface:focus,
    .\!md\:checked\:bg-inverse-on-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 237, 235, var(--tw-bg-opacity)) !important;
    }

    .md\:border-inverse-on-surface,
    .md\:hover\:border-inverse-on-surface:hover,
    .md\:focus\:border-inverse-on-surface:focus,
    .md\:checked\:border-inverse-on-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 237, 235, var(--tw-border-opacity));
    }

    .\!md\:border-inverse-on-surface,
    .\!md\:hover\:border-inverse-on-surface:hover,
    .\!md\:focus\:border-inverse-on-surface:focus,
    .\!md\:checked\:border-inverse-on-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 237, 235, var(--tw-border-opacity)) !important;
    }

    .md\:ring-inverse-on-surface,
    .md\:hover\:ring-inverse-on-surface:hover,
    .md\:focus\:ring-inverse-on-surface:focus,
    .md\:checked\:ring-inverse-on-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 237, 235, var(--tw-ring-opacity));
    }

    .\!md\:ring-inverse-on-surface,
    .\!md\:hover\:ring-inverse-on-surface:hover,
    .\!md\:focus\:ring-inverse-on-surface:focus,
    .\!md\:checked\:ring-inverse-on-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 237, 235, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-inverse-on-surface,
    .lg\:hover\:text-inverse-on-surface:hover,
    .lg\:focus\:text-inverse-on-surface:focus,
    .lg\:checked\:text-inverse-on-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 237, 235, var(--tw-text-opacity));
    }

    .\!lg\:text-inverse-on-surface,
    .\!lg\:hover\:text-inverse-on-surface:hover,
    .\!lg\:focus\:text-inverse-on-surface:focus,
    .\!lg\:checked\:text-inverse-on-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 237, 235, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-inverse-on-surface,
    .lg\:hover\:bg-inverse-on-surface:hover,
    .lg\:focus\:bg-inverse-on-surface:focus,
    .lg\:checked\:bg-inverse-on-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 237, 235, var(--tw-bg-opacity));
    }

    .\!lg\:bg-inverse-on-surface,
    .\!lg\:hover\:bg-inverse-on-surface:hover,
    .\!lg\:focus\:bg-inverse-on-surface:focus,
    .\!lg\:checked\:bg-inverse-on-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 237, 235, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-inverse-on-surface,
    .lg\:hover\:border-inverse-on-surface:hover,
    .lg\:focus\:border-inverse-on-surface:focus,
    .lg\:checked\:border-inverse-on-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 237, 235, var(--tw-border-opacity));
    }

    .\!lg\:border-inverse-on-surface,
    .\!lg\:hover\:border-inverse-on-surface:hover,
    .\!lg\:focus\:border-inverse-on-surface:focus,
    .\!lg\:checked\:border-inverse-on-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 237, 235, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-inverse-on-surface,
    .lg\:hover\:ring-inverse-on-surface:hover,
    .lg\:focus\:ring-inverse-on-surface:focus,
    .lg\:checked\:ring-inverse-on-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 237, 235, var(--tw-ring-opacity));
    }

    .\!lg\:ring-inverse-on-surface,
    .\!lg\:hover\:ring-inverse-on-surface:hover,
    .\!lg\:focus\:ring-inverse-on-surface:focus,
    .\!lg\:checked\:ring-inverse-on-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 237, 235, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-inverse-on-surface,
    .xl\:hover\:text-inverse-on-surface:hover,
    .xl\:focus\:text-inverse-on-surface:focus,
    .xl\:checked\:text-inverse-on-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 237, 235, var(--tw-text-opacity));
    }

    .\!xl\:text-inverse-on-surface,
    .\!xl\:hover\:text-inverse-on-surface:hover,
    .\!xl\:focus\:text-inverse-on-surface:focus,
    .\!xl\:checked\:text-inverse-on-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 237, 235, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-inverse-on-surface,
    .xl\:hover\:bg-inverse-on-surface:hover,
    .xl\:focus\:bg-inverse-on-surface:focus,
    .xl\:checked\:bg-inverse-on-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 237, 235, var(--tw-bg-opacity));
    }

    .\!xl\:bg-inverse-on-surface,
    .\!xl\:hover\:bg-inverse-on-surface:hover,
    .\!xl\:focus\:bg-inverse-on-surface:focus,
    .\!xl\:checked\:bg-inverse-on-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 237, 235, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-inverse-on-surface,
    .xl\:hover\:border-inverse-on-surface:hover,
    .xl\:focus\:border-inverse-on-surface:focus,
    .xl\:checked\:border-inverse-on-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 237, 235, var(--tw-border-opacity));
    }

    .\!xl\:border-inverse-on-surface,
    .\!xl\:hover\:border-inverse-on-surface:hover,
    .\!xl\:focus\:border-inverse-on-surface:focus,
    .\!xl\:checked\:border-inverse-on-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 237, 235, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-inverse-on-surface,
    .xl\:hover\:ring-inverse-on-surface:hover,
    .xl\:focus\:ring-inverse-on-surface:focus,
    .xl\:checked\:ring-inverse-on-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 237, 235, var(--tw-ring-opacity));
    }

    .\!xl\:ring-inverse-on-surface,
    .\!xl\:hover\:ring-inverse-on-surface:hover,
    .\!xl\:focus\:ring-inverse-on-surface:focus,
    .\!xl\:checked\:ring-inverse-on-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 237, 235, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-inverse-on-surface,
    .\2xl\:hover\:text-inverse-on-surface:hover,
    .\2xl\:focus\:text-inverse-on-surface:focus,
    .\2xl\:checked\:text-inverse-on-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 237, 235, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-inverse-on-surface,
    .\!2xlsm\:hover\:text-inverse-on-surface:hover,
    .\!2xlsm\:focus\:text-inverse-on-surface:focus,
    .\!2xlsm\:checked\:text-inverse-on-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 237, 235, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-inverse-on-surface,
    .\2xl\:hover\:bg-inverse-on-surface:hover,
    .\2xl\:focus\:bg-inverse-on-surface:focus,
    .\2xl\:checked\:bg-inverse-on-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 237, 235, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-inverse-on-surface,
    .\!2xlsm\:hover\:bg-inverse-on-surface:hover,
    .\!2xlsm\:focus\:bg-inverse-on-surface:focus,
    .\!2xlsm\:checked\:bg-inverse-on-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 237, 235, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-inverse-on-surface,
    .\2xl\:hover\:border-inverse-on-surface:hover,
    .\2xl\:focus\:border-inverse-on-surface:focus,
    .\2xl\:checked\:border-inverse-on-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 237, 235, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-inverse-on-surface,
    .\!2xlsm\:hover\:border-inverse-on-surface:hover,
    .\!2xlsm\:focus\:border-inverse-on-surface:focus,
    .\!2xlsm\:checked\:border-inverse-on-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 237, 235, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-inverse-on-surface,
    .\2xl\:hover\:ring-inverse-on-surface:hover,
    .\2xl\:focus\:ring-inverse-on-surface:focus,
    .\2xl\:checked\:ring-inverse-on-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 237, 235, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-inverse-on-surface,
    .\!2xl\:hover\:ring-inverse-on-surface:hover,
    .\!2xl\:focus\:ring-inverse-on-surface:focus,
    .\!2xl\:checked\:ring-inverse-on-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 237, 235, var(--tw-ring-opacity)) !important;
    }
}

//inverse-surface
.text-inverse-surface,
.hover\:text-inverse-surface:hover,
.focus\:text-inverse-surface:focus,
.checked\:text-inverse-surface:checked {
    --tw-text-opacity: 1;
    color: rgb(95, 20, 22, var(--tw-text-opacity));
}

.\!text-inverse-surface,
.\!hover\:text-inverse-surface:hover,
.\!focus\:text-inverse-surface:focus,
.\!checked\:text-inverse-surface:checked {
    --tw-text-opacity: 1;
    color: rgb(95, 20, 22, var(--tw-text-opacity)) !important;
}

.bg-inverse-surface,
.hover\:bg-inverse-surface:hover,
.focus\:bg-inverse-surface:focus,
.checked\:bg-inverse-surface:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(95, 20, 22, var(--tw-bg-opacity));
}

.\!bg-inverse-surface,
.\!hover\:bg-inverse-surface:hover,
.\!focus\:bg-inverse-surface:focus,
.\!checked\:bg-inverse-surface:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(95, 20, 22, var(--tw-bg-opacity)) !important;
}

.border-inverse-surface,
.hover\:border-inverse-surface:hover,
.focus\:border-inverse-surface:focus,
.checked\:border-inverse-surface:checked {
    --tw-border-opacity: 1;
    border-color: rgb(95, 20, 22, var(--tw-border-opacity));
}

.\!border-inverse-surface,
.\!hover\:border-inverse-surface:hover,
.\!focus\:border-inverse-surface:focus,
.\!checked\:border-inverse-surface:checked {
    --tw-border-opacity: 1;
    border-color: rgb(95, 20, 22, var(--tw-border-opacity)) !important;
}

.ring-inverse-surface,
.hover\:ring-inverse-surface:hover,
.focus\:ring-inverse-surface:focus,
.checked\:ring-inverse-surface:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(95, 20, 22, var(--tw-ring-opacity));
}

.\!ring-inverse-surface,
.\!hover\:ring-inverse-surface:hover,
.\!focus\:ring-inverse-surface:focus,
.\!checked\:ring-inverse-surface:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(95, 20, 22, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-inverse-surface,
    .sm\:hover\:text-inverse-surface:hover,
    .sm\:focus\:text-inverse-surface:focus,
    .sm\:checked\:text-inverse-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(95, 20, 22, var(--tw-text-opacity));
    }

    .\!sm\:text-inverse-surface,
    .\!sm\:hover\:text-inverse-surface:hover,
    .\!sm\:focus\:text-inverse-surface:focus,
    .\!sm\:checked\:text-inverse-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(95, 20, 22, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-inverse-surface,
    .sm\:hover\:bg-inverse-surface:hover,
    .sm\:focus\:bg-inverse-surface:focus,
    .sm\:checked\:bg-inverse-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(95, 20, 22, var(--tw-bg-opacity));
    }

    .\!sm\:bg-inverse-surface,
    .\!sm\:hover\:bg-inverse-surface:hover,
    .\!sm\:focus\:bg-inverse-surface:focus,
    .\!sm\:checked\:bg-inverse-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(95, 20, 22, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-inverse-surface,
    .sm\:hover\:border-inverse-surface:hover,
    .sm\:focus\:border-inverse-surface:focus,
    .sm\:checked\:border-inverse-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(95, 20, 22, var(--tw-border-opacity));
    }

    .\!sm\:border-inverse-surface,
    .\!sm\:hover\:border-inverse-surface:hover,
    .\!sm\:focus\:border-inverse-surface:focus,
    .\!sm\:checked\:border-inverse-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(95, 20, 22, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-inverse-surface,
    .sm\:hover\:ring-inverse-surface:hover,
    .sm\:focus\:ring-inverse-surface:focus,
    .sm\:checked\:ring-inverse-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(95, 20, 22, var(--tw-ring-opacity));
    }

    .\!sm\:ring-inverse-surface,
    .\!sm\:hover\:ring-inverse-surface:hover,
    .\!sm\:focus\:ring-inverse-surface:focus,
    .\!sm\:checked\:ring-inverse-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(95, 20, 22, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-inverse-surface,
    .md\:hover\:text-inverse-surface:hover,
    .md\:focus\:text-inverse-surface:focus,
    .md\:checked\:text-inverse-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(95, 20, 22, var(--tw-text-opacity));
    }

    .\!md\:text-inverse-surface,
    .\!md\:hover\:text-inverse-surface:hover,
    .\!md\:focus\:text-inverse-surface:focus,
    .\!md\:checked\:text-inverse-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(95, 20, 22, var(--tw-text-opacity)) !important;
    }

    .md\:bg-inverse-surface,
    .md\:hover\:bg-inverse-surface:hover,
    .md\:focus\:bg-inverse-surface:focus,
    .md\:checked\:bg-inverse-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(95, 20, 22, var(--tw-bg-opacity));
    }

    .\!md\:bg-inverse-surface,
    .\!md\:hover\:bg-inverse-surface:hover,
    .\!md\:focus\:bg-inverse-surface:focus,
    .\!md\:checked\:bg-inverse-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(95, 20, 22, var(--tw-bg-opacity)) !important;
    }

    .md\:border-inverse-surface,
    .md\:hover\:border-inverse-surface:hover,
    .md\:focus\:border-inverse-surface:focus,
    .md\:checked\:border-inverse-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(95, 20, 22, var(--tw-border-opacity));
    }

    .\!md\:border-inverse-surface,
    .\!md\:hover\:border-inverse-surface:hover,
    .\!md\:focus\:border-inverse-surface:focus,
    .\!md\:checked\:border-inverse-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(95, 20, 22, var(--tw-border-opacity)) !important;
    }

    .md\:ring-inverse-surface,
    .md\:hover\:ring-inverse-surface:hover,
    .md\:focus\:ring-inverse-surface:focus,
    .md\:checked\:ring-inverse-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(95, 20, 22, var(--tw-ring-opacity));
    }

    .\!md\:ring-inverse-surface,
    .\!md\:hover\:ring-inverse-surface:hover,
    .\!md\:focus\:ring-inverse-surface:focus,
    .\!md\:checked\:ring-inverse-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(95, 20, 22, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-inverse-surface,
    .lg\:hover\:text-inverse-surface:hover,
    .lg\:focus\:text-inverse-surface:focus,
    .lg\:checked\:text-inverse-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(95, 20, 22, var(--tw-text-opacity));
    }

    .\!lg\:text-inverse-surface,
    .\!lg\:hover\:text-inverse-surface:hover,
    .\!lg\:focus\:text-inverse-surface:focus,
    .\!lg\:checked\:text-inverse-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(95, 20, 22, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-inverse-surface,
    .lg\:hover\:bg-inverse-surface:hover,
    .lg\:focus\:bg-inverse-surface:focus,
    .lg\:checked\:bg-inverse-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(95, 20, 22, var(--tw-bg-opacity));
    }

    .\!lg\:bg-inverse-surface,
    .\!lg\:hover\:bg-inverse-surface:hover,
    .\!lg\:focus\:bg-inverse-surface:focus,
    .\!lg\:checked\:bg-inverse-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(95, 20, 22, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-inverse-surface,
    .lg\:hover\:border-inverse-surface:hover,
    .lg\:focus\:border-inverse-surface:focus,
    .lg\:checked\:border-inverse-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(95, 20, 22, var(--tw-border-opacity));
    }

    .\!lg\:border-inverse-surface,
    .\!lg\:hover\:border-inverse-surface:hover,
    .\!lg\:focus\:border-inverse-surface:focus,
    .\!lg\:checked\:border-inverse-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(95, 20, 22, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-inverse-surface,
    .lg\:hover\:ring-inverse-surface:hover,
    .lg\:focus\:ring-inverse-surface:focus,
    .lg\:checked\:ring-inverse-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(95, 20, 22, var(--tw-ring-opacity));
    }

    .\!lg\:ring-inverse-surface,
    .\!lg\:hover\:ring-inverse-surface:hover,
    .\!lg\:focus\:ring-inverse-surface:focus,
    .\!lg\:checked\:ring-inverse-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(95, 20, 22, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-inverse-surface,
    .xl\:hover\:text-inverse-surface:hover,
    .xl\:focus\:text-inverse-surface:focus,
    .xl\:checked\:text-inverse-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(95, 20, 22, var(--tw-text-opacity));
    }

    .\!xl\:text-inverse-surface,
    .\!xl\:hover\:text-inverse-surface:hover,
    .\!xl\:focus\:text-inverse-surface:focus,
    .\!xl\:checked\:text-inverse-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(95, 20, 22, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-inverse-surface,
    .xl\:hover\:bg-inverse-surface:hover,
    .xl\:focus\:bg-inverse-surface:focus,
    .xl\:checked\:bg-inverse-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(95, 20, 22, var(--tw-bg-opacity));
    }

    .\!xl\:bg-inverse-surface,
    .\!xl\:hover\:bg-inverse-surface:hover,
    .\!xl\:focus\:bg-inverse-surface:focus,
    .\!xl\:checked\:bg-inverse-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(95, 20, 22, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-inverse-surface,
    .xl\:hover\:border-inverse-surface:hover,
    .xl\:focus\:border-inverse-surface:focus,
    .xl\:checked\:border-inverse-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(95, 20, 22, var(--tw-border-opacity));
    }

    .\!xl\:border-inverse-surface,
    .\!xl\:hover\:border-inverse-surface:hover,
    .\!xl\:focus\:border-inverse-surface:focus,
    .\!xl\:checked\:border-inverse-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(95, 20, 22, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-inverse-surface,
    .xl\:hover\:ring-inverse-surface:hover,
    .xl\:focus\:ring-inverse-surface:focus,
    .xl\:checked\:ring-inverse-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(95, 20, 22, var(--tw-ring-opacity));
    }

    .\!xl\:ring-inverse-surface,
    .\!xl\:hover\:ring-inverse-surface:hover,
    .\!xl\:focus\:ring-inverse-surface:focus,
    .\!xl\:checked\:ring-inverse-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(95, 20, 22, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-inverse-surface,
    .\2xl\:hover\:text-inverse-surface:hover,
    .\2xl\:focus\:text-inverse-surface:focus,
    .\2xl\:checked\:text-inverse-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(95, 20, 22, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-inverse-surface,
    .\!2xlsm\:hover\:text-inverse-surface:hover,
    .\!2xlsm\:focus\:text-inverse-surface:focus,
    .\!2xlsm\:checked\:text-inverse-surface:checked {
        --tw-text-opacity: 1;
        color: rgb(95, 20, 22, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-inverse-surface,
    .\2xl\:hover\:bg-inverse-surface:hover,
    .\2xl\:focus\:bg-inverse-surface:focus,
    .\2xl\:checked\:bg-inverse-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(95, 20, 22, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-inverse-surface,
    .\!2xlsm\:hover\:bg-inverse-surface:hover,
    .\!2xlsm\:focus\:bg-inverse-surface:focus,
    .\!2xlsm\:checked\:bg-inverse-surface:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(95, 20, 22, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-inverse-surface,
    .\2xl\:hover\:border-inverse-surface:hover,
    .\2xl\:focus\:border-inverse-surface:focus,
    .\2xl\:checked\:border-inverse-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(95, 20, 22, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-inverse-surface,
    .\!2xlsm\:hover\:border-inverse-surface:hover,
    .\!2xlsm\:focus\:border-inverse-surface:focus,
    .\!2xlsm\:checked\:border-inverse-surface:checked {
        --tw-border-opacity: 1;
        border-color: rgb(95, 20, 22, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-inverse-surface,
    .\2xl\:hover\:ring-inverse-surface:hover,
    .\2xl\:focus\:ring-inverse-surface:focus,
    .\2xl\:checked\:ring-inverse-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(95, 20, 22, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-inverse-surface,
    .\!2xl\:hover\:ring-inverse-surface:hover,
    .\!2xl\:focus\:ring-inverse-surface:focus,
    .\!2xl\:checked\:ring-inverse-surface:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(95, 20, 22, var(--tw-ring-opacity)) !important;
    }
}

//inverse-primary
.text-inverse-primary,
.hover\:text-inverse-primary:hover,
.focus\:text-inverse-primary:focus,
.checked\:text-inverse-primary:checked {
    --tw-text-opacity: 1;
    color: rgb(255, 183, 133, var(--tw-text-opacity));
}

.\!text-inverse-primary,
.\!hover\:text-inverse-primary:hover,
.\!focus\:text-inverse-primary:focus,
.\!checked\:text-inverse-primary:checked {
    --tw-text-opacity: 1;
    color: rgb(255, 183, 133, var(--tw-text-opacity)) !important;
}

.bg-inverse-primary,
.hover\:bg-inverse-primary:hover,
.focus\:bg-inverse-primary:focus,
.checked\:bg-inverse-primary:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(255, 183, 133, var(--tw-bg-opacity));
}

.\!bg-inverse-primary,
.\!hover\:bg-inverse-primary:hover,
.\!focus\:bg-inverse-primary:focus,
.\!checked\:bg-inverse-primary:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(255, 183, 133, var(--tw-bg-opacity)) !important;
}

.border-inverse-primary,
.hover\:border-inverse-primary:hover,
.focus\:border-inverse-primary:focus,
.checked\:border-inverse-primary:checked {
    --tw-border-opacity: 1;
    border-color: rgb(255, 183, 133, var(--tw-border-opacity));
}

.\!border-inverse-primary,
.\!hover\:border-inverse-primary:hover,
.\!focus\:border-inverse-primary:focus,
.\!checked\:border-inverse-primary:checked {
    --tw-border-opacity: 1;
    border-color: rgb(255, 183, 133, var(--tw-border-opacity)) !important;
}

.ring-inverse-primary,
.hover\:ring-inverse-primary:hover,
.focus\:ring-inverse-primary:focus,
.checked\:ring-inverse-primary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255, 183, 133, var(--tw-ring-opacity));
}

.\!ring-inverse-primary,
.\!hover\:ring-inverse-primary:hover,
.\!focus\:ring-inverse-primary:focus,
.\!checked\:ring-inverse-primary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255, 183, 133, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-inverse-primary,
    .sm\:hover\:text-inverse-primary:hover,
    .sm\:focus\:text-inverse-primary:focus,
    .sm\:checked\:text-inverse-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 183, 133, var(--tw-text-opacity));
    }

    .\!sm\:text-inverse-primary,
    .\!sm\:hover\:text-inverse-primary:hover,
    .\!sm\:focus\:text-inverse-primary:focus,
    .\!sm\:checked\:text-inverse-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 183, 133, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-inverse-primary,
    .sm\:hover\:bg-inverse-primary:hover,
    .sm\:focus\:bg-inverse-primary:focus,
    .sm\:checked\:bg-inverse-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 183, 133, var(--tw-bg-opacity));
    }

    .\!sm\:bg-inverse-primary,
    .\!sm\:hover\:bg-inverse-primary:hover,
    .\!sm\:focus\:bg-inverse-primary:focus,
    .\!sm\:checked\:bg-inverse-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 183, 133, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-inverse-primary,
    .sm\:hover\:border-inverse-primary:hover,
    .sm\:focus\:border-inverse-primary:focus,
    .sm\:checked\:border-inverse-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 183, 133, var(--tw-border-opacity));
    }

    .\!sm\:border-inverse-primary,
    .\!sm\:hover\:border-inverse-primary:hover,
    .\!sm\:focus\:border-inverse-primary:focus,
    .\!sm\:checked\:border-inverse-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 183, 133, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-inverse-primary,
    .sm\:hover\:ring-inverse-primary:hover,
    .sm\:focus\:ring-inverse-primary:focus,
    .sm\:checked\:ring-inverse-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 183, 133, var(--tw-ring-opacity));
    }

    .\!sm\:ring-inverse-primary,
    .\!sm\:hover\:ring-inverse-primary:hover,
    .\!sm\:focus\:ring-inverse-primary:focus,
    .\!sm\:checked\:ring-inverse-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 183, 133, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-inverse-primary,
    .md\:hover\:text-inverse-primary:hover,
    .md\:focus\:text-inverse-primary:focus,
    .md\:checked\:text-inverse-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 183, 133, var(--tw-text-opacity));
    }

    .\!md\:text-inverse-primary,
    .\!md\:hover\:text-inverse-primary:hover,
    .\!md\:focus\:text-inverse-primary:focus,
    .\!md\:checked\:text-inverse-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 183, 133, var(--tw-text-opacity)) !important;
    }

    .md\:bg-inverse-primary,
    .md\:hover\:bg-inverse-primary:hover,
    .md\:focus\:bg-inverse-primary:focus,
    .md\:checked\:bg-inverse-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 183, 133, var(--tw-bg-opacity));
    }

    .\!md\:bg-inverse-primary,
    .\!md\:hover\:bg-inverse-primary:hover,
    .\!md\:focus\:bg-inverse-primary:focus,
    .\!md\:checked\:bg-inverse-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 183, 133, var(--tw-bg-opacity)) !important;
    }

    .md\:border-inverse-primary,
    .md\:hover\:border-inverse-primary:hover,
    .md\:focus\:border-inverse-primary:focus,
    .md\:checked\:border-inverse-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 183, 133, var(--tw-border-opacity));
    }

    .\!md\:border-inverse-primary,
    .\!md\:hover\:border-inverse-primary:hover,
    .\!md\:focus\:border-inverse-primary:focus,
    .\!md\:checked\:border-inverse-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 183, 133, var(--tw-border-opacity)) !important;
    }

    .md\:ring-inverse-primary,
    .md\:hover\:ring-inverse-primary:hover,
    .md\:focus\:ring-inverse-primary:focus,
    .md\:checked\:ring-inverse-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 183, 133, var(--tw-ring-opacity));
    }

    .\!md\:ring-inverse-primary,
    .\!md\:hover\:ring-inverse-primary:hover,
    .\!md\:focus\:ring-inverse-primary:focus,
    .\!md\:checked\:ring-inverse-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 183, 133, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-inverse-primary,
    .lg\:hover\:text-inverse-primary:hover,
    .lg\:focus\:text-inverse-primary:focus,
    .lg\:checked\:text-inverse-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 183, 133, var(--tw-text-opacity));
    }

    .\!lg\:text-inverse-primary,
    .\!lg\:hover\:text-inverse-primary:hover,
    .\!lg\:focus\:text-inverse-primary:focus,
    .\!lg\:checked\:text-inverse-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 183, 133, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-inverse-primary,
    .lg\:hover\:bg-inverse-primary:hover,
    .lg\:focus\:bg-inverse-primary:focus,
    .lg\:checked\:bg-inverse-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 183, 133, var(--tw-bg-opacity));
    }

    .\!lg\:bg-inverse-primary,
    .\!lg\:hover\:bg-inverse-primary:hover,
    .\!lg\:focus\:bg-inverse-primary:focus,
    .\!lg\:checked\:bg-inverse-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 183, 133, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-inverse-primary,
    .lg\:hover\:border-inverse-primary:hover,
    .lg\:focus\:border-inverse-primary:focus,
    .lg\:checked\:border-inverse-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 183, 133, var(--tw-border-opacity));
    }

    .\!lg\:border-inverse-primary,
    .\!lg\:hover\:border-inverse-primary:hover,
    .\!lg\:focus\:border-inverse-primary:focus,
    .\!lg\:checked\:border-inverse-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 183, 133, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-inverse-primary,
    .lg\:hover\:ring-inverse-primary:hover,
    .lg\:focus\:ring-inverse-primary:focus,
    .lg\:checked\:ring-inverse-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 183, 133, var(--tw-ring-opacity));
    }

    .\!lg\:ring-inverse-primary,
    .\!lg\:hover\:ring-inverse-primary:hover,
    .\!lg\:focus\:ring-inverse-primary:focus,
    .\!lg\:checked\:ring-inverse-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 183, 133, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-inverse-primary,
    .xl\:hover\:text-inverse-primary:hover,
    .xl\:focus\:text-inverse-primary:focus,
    .xl\:checked\:text-inverse-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 183, 133, var(--tw-text-opacity));
    }

    .\!xl\:text-inverse-primary,
    .\!xl\:hover\:text-inverse-primary:hover,
    .\!xl\:focus\:text-inverse-primary:focus,
    .\!xl\:checked\:text-inverse-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 183, 133, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-inverse-primary,
    .xl\:hover\:bg-inverse-primary:hover,
    .xl\:focus\:bg-inverse-primary:focus,
    .xl\:checked\:bg-inverse-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 183, 133, var(--tw-bg-opacity));
    }

    .\!xl\:bg-inverse-primary,
    .\!xl\:hover\:bg-inverse-primary:hover,
    .\!xl\:focus\:bg-inverse-primary:focus,
    .\!xl\:checked\:bg-inverse-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 183, 133, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-inverse-primary,
    .xl\:hover\:border-inverse-primary:hover,
    .xl\:focus\:border-inverse-primary:focus,
    .xl\:checked\:border-inverse-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 183, 133, var(--tw-border-opacity));
    }

    .\!xl\:border-inverse-primary,
    .\!xl\:hover\:border-inverse-primary:hover,
    .\!xl\:focus\:border-inverse-primary:focus,
    .\!xl\:checked\:border-inverse-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 183, 133, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-inverse-primary,
    .xl\:hover\:ring-inverse-primary:hover,
    .xl\:focus\:ring-inverse-primary:focus,
    .xl\:checked\:ring-inverse-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 183, 133, var(--tw-ring-opacity));
    }

    .\!xl\:ring-inverse-primary,
    .\!xl\:hover\:ring-inverse-primary:hover,
    .\!xl\:focus\:ring-inverse-primary:focus,
    .\!xl\:checked\:ring-inverse-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 183, 133, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-inverse-primary,
    .\2xl\:hover\:text-inverse-primary:hover,
    .\2xl\:focus\:text-inverse-primary:focus,
    .\2xl\:checked\:text-inverse-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 183, 133, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-inverse-primary,
    .\!2xlsm\:hover\:text-inverse-primary:hover,
    .\!2xlsm\:focus\:text-inverse-primary:focus,
    .\!2xlsm\:checked\:text-inverse-primary:checked {
        --tw-text-opacity: 1;
        color: rgb(255, 183, 133, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-inverse-primary,
    .\2xl\:hover\:bg-inverse-primary:hover,
    .\2xl\:focus\:bg-inverse-primary:focus,
    .\2xl\:checked\:bg-inverse-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 183, 133, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-inverse-primary,
    .\!2xlsm\:hover\:bg-inverse-primary:hover,
    .\!2xlsm\:focus\:bg-inverse-primary:focus,
    .\!2xlsm\:checked\:bg-inverse-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(255, 183, 133, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-inverse-primary,
    .\2xl\:hover\:border-inverse-primary:hover,
    .\2xl\:focus\:border-inverse-primary:focus,
    .\2xl\:checked\:border-inverse-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 183, 133, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-inverse-primary,
    .\!2xlsm\:hover\:border-inverse-primary:hover,
    .\!2xlsm\:focus\:border-inverse-primary:focus,
    .\!2xlsm\:checked\:border-inverse-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgb(255, 183, 133, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-inverse-primary,
    .\2xl\:hover\:ring-inverse-primary:hover,
    .\2xl\:focus\:ring-inverse-primary:focus,
    .\2xl\:checked\:ring-inverse-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 183, 133, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-inverse-primary,
    .\!2xl\:hover\:ring-inverse-primary:hover,
    .\!2xl\:focus\:ring-inverse-primary:focus,
    .\!2xl\:checked\:ring-inverse-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255, 183, 133, var(--tw-ring-opacity)) !important;
    }
}


.border-opacity-5,
.hover\:border-opacity-5:hover,
.focus\:border-opacity-5:focus,
.disabled\:border-opacity-5:disabled {
    --tw-border-opacity: 0.05;
}

.\!border-opacity-5,
.\!hover\:border-opacity-5:hover,
.\!focus\:border-opacity-5:focus,
.\!disabled\:border-opacity-5:disabled {
    --tw-border-opacity: 0.05 !important;
}

.text-opacity-5,
.hover\:text-opacity-5:hover,
.focus\:text-opacity-5:focus,
.disabled\:text-opacity-5:disabled {
    --tw-text-opacity: 0.05;
}

.\!text-opacity-5,
.\!hover\:text-opacity-5:hover,
.\!focus\:text-opacity-5:focus,
.\!disabled\:text-opacity-5:disabled {
    --tw-text-opacity: 0.05 !important;
}

.bg-opacity-5,
.hover\:bg-opacity-5:hover,
.focus\:bg-opacity-5:focus,
.disabled\:bg-opacity-5:disabled {
    --tw-bg-opacity: 0.05;
}

.\!bg-opacity-5,
.\!hover\:bg-opacity-5:hover,
.\!focus\:bg-opacity-5:focus,
.\!disabled\:bg-opacity-5:disabled {
    --tw-bg-opacity: 0.05 !important;
}

.ring-opacity-5,
.hover\:ring-opacity-5:hover,
.focus\:ring-opacity-5:focus,
.disabled\:ring-opacity-5:disabled {
    --tw-ring-opacity: 0.05;
}

.\!ring-opacity-5,
.\!hover\:ring-opacity-5:hover,
.\!focus\:ring-opacity-5:focus,
.\!disabled\:ring-opacity-5:disabled {
    --tw-ring-opacity: 0.05 !important;
}


.border-opacity-10,
.hover\:border-opacity-10:hover,
.focus\:border-opacity-10:focus,
.disabled\:border-opacity-10:disabled {
    --tw-border-opacity: 0.1;
}

.\!border-opacity-10,
.\!hover\:border-opacity-10:hover,
.\!focus\:border-opacity-10:focus,
.\!disabled\:border-opacity-10:disabled {
    --tw-border-opacity: 0.1 !important;
}

.text-opacity-10,
.hover\:text-opacity-10:hover,
.focus\:text-opacity-10:focus,
.disabled\:text-opacity-10:disabled {
    --tw-text-opacity: 0.1;
}

.\!text-opacity-10,
.\!hover\:text-opacity-10:hover,
.\!focus\:text-opacity-10:focus,
.\!disabled\:text-opacity-10:disabled {
    --tw-text-opacity: 0.1 !important;
}

.bg-opacity-10,
.hover\:bg-opacity-10:hover,
.focus\:bg-opacity-10:focus,
.disabled\:bg-opacity-10:disabled {
    --tw-bg-opacity: 0.1;
}

.\!bg-opacity-10,
.\!hover\:bg-opacity-10:hover,
.\!focus\:bg-opacity-10:focus,
.\!disabled\:bg-opacity-10:disabled {
    --tw-bg-opacity: 0.1 !important;
}

.ring-opacity-10,
.hover\:ring-opacity-10:hover,
.focus\:ring-opacity-10:focus,
.disabled\:ring-opacity-10:disabled {
    --tw-ring-opacity: 0.1;
}

.\!ring-opacity-10,
.\!hover\:ring-opacity-10:hover,
.\!focus\:ring-opacity-10:focus,
.\!disabled\:ring-opacity-10:disabled {
    --tw-ring-opacity: 0.1 !important;
}


.border-opacity-20,
.hover\:border-opacity-20:hover,
.focus\:border-opacity-20:focus,
.disabled\:border-opacity-20:disabled {
    --tw-border-opacity: 0.2;
}

.\!border-opacity-20,
.\!hover\:border-opacity-20:hover,
.\!focus\:border-opacity-20:focus,
.\!disabled\:border-opacity-20:disabled {
    --tw-border-opacity: 0.2 !important;
}

.text-opacity-20,
.hover\:text-opacity-20:hover,
.focus\:text-opacity-20:focus,
.disabled\:text-opacity-20:disabled {
    --tw-text-opacity: 0.2;
}

.\!text-opacity-20,
.\!hover\:text-opacity-20:hover,
.\!focus\:text-opacity-20:focus,
.\!disabled\:text-opacity-20:disabled {
    --tw-text-opacity: 0.2 !important;
}

.bg-opacity-20,
.hover\:bg-opacity-20:hover,
.focus\:bg-opacity-20:focus,
.disabled\:bg-opacity-20:disabled {
    --tw-bg-opacity: 0.2;
}

.\!bg-opacity-20,
.\!hover\:bg-opacity-20:hover,
.\!focus\:bg-opacity-20:focus,
.\!disabled\:bg-opacity-20:disabled {
    --tw-bg-opacity: 0.2 !important;
}

.ring-opacity-20,
.hover\:ring-opacity-20:hover,
.focus\:ring-opacity-20:focus,
.disabled\:ring-opacity-20:disabled {
    --tw-ring-opacity: 0.2;
}

.\!ring-opacity-20,
.\!hover\:ring-opacity-20:hover,
.\!focus\:ring-opacity-20:focus,
.\!disabled\:ring-opacity-20:disabled {
    --tw-ring-opacity: 0.2 !important;
}


.border-opacity-50,
.hover\:border-opacity-50:hover,
.focus\:border-opacity-50:focus,
.disabled\:border-opacity-50:disabled {
    --tw-border-opacity: 0.5;
}

.\!border-opacity-50,
.\!hover\:border-opacity-50:hover,
.\!focus\:border-opacity-50:focus,
.\!disabled\:border-opacity-50:disabled {
    --tw-border-opacity: 0.5 !important;
}

.text-opacity-50,
.hover\:text-opacity-50:hover,
.focus\:text-opacity-50:focus,
.disabled\:text-opacity-50:disabled {
    --tw-text-opacity: 0.5;
}

.\!text-opacity-50,
.\!hover\:text-opacity-50:hover,
.\!focus\:text-opacity-50:focus,
.\!disabled\:text-opacity-50:disabled {
    --tw-text-opacity: 0.5 !important;
}

.bg-opacity-50,
.hover\:bg-opacity-50:hover,
.focus\:bg-opacity-50:focus,
.disabled\:bg-opacity-50:disabled {
    --tw-bg-opacity: 0.5;
}

.\!bg-opacity-50,
.\!hover\:bg-opacity-50:hover,
.\!focus\:bg-opacity-50:focus,
.\!disabled\:bg-opacity-50:disabled {
    --tw-bg-opacity: 0.5 !important;
}

.ring-opacity-50,
.hover\:ring-opacity-50:hover,
.focus\:ring-opacity-50:focus,
.disabled\:ring-opacity-50:disabled {
    --tw-ring-opacity: 0.5;
}

.\!ring-opacity-50,
.\!hover\:ring-opacity-50:hover,
.\!focus\:ring-opacity-50:focus,
.\!disabled\:ring-opacity-50:disabled {
    --tw-ring-opacity: 0.5 !important;
}


.border-opacity-80,
.hover\:border-opacity-80:hover,
.focus\:border-opacity-80:focus,
.disabled\:border-opacity-80:disabled {
    --tw-border-opacity: 0.8;
}

.\!border-opacity-80,
.\!hover\:border-opacity-80:hover,
.\!focus\:border-opacity-80:focus,
.\!disabled\:border-opacity-80:disabled {
    --tw-border-opacity: 0.8 !important;
}

.text-opacity-80,
.hover\:text-opacity-80:hover,
.focus\:text-opacity-80:focus,
.disabled\:text-opacity-80:disabled {
    --tw-text-opacity: 0.8;
}

.\!text-opacity-80,
.\!hover\:text-opacity-80:hover,
.\!focus\:text-opacity-80:focus,
.\!disabled\:text-opacity-80:disabled {
    --tw-text-opacity: 0.8 !important;
}

.bg-opacity-80,
.hover\:bg-opacity-80:hover,
.focus\:bg-opacity-80:focus,
.disabled\:bg-opacity-80:disabled {
    --tw-bg-opacity: 0.8;
}

.\!bg-opacity-80,
.\!hover\:bg-opacity-80:hover,
.\!focus\:bg-opacity-80:focus,
.\!disabled\:bg-opacity-80:disabled {
    --tw-bg-opacity: 0.8 !important;
}

.ring-opacity-80,
.hover\:ring-opacity-80:hover,
.focus\:ring-opacity-80:focus,
.disabled\:ring-opacity-80:disabled {
    --tw-ring-opacity: 0.8;
}

.\!ring-opacity-80,
.\!hover\:ring-opacity-80:hover,
.\!focus\:ring-opacity-80:focus,
.\!disabled\:ring-opacity-80:disabled {
    --tw-ring-opacity: 0.8 !important;
}


.border-opacity-100,
.hover\:border-opacity-100:hover,
.focus\:border-opacity-100:focus,
.disabled\:border-opacity-100:disabled {
    --tw-border-opacity: 1;
}

.\!border-opacity-100,
.\!hover\:border-opacity-100:hover,
.\!focus\:border-opacity-100:focus,
.\!disabled\:border-opacity-100:disabled {
    --tw-border-opacity: 1 !important;
}

.text-opacity-100,
.hover\:text-opacity-100:hover,
.focus\:text-opacity-100:focus,
.disabled\:text-opacity-100:disabled {
    --tw-text-opacity: 1;
}

.\!text-opacity-100,
.\!hover\:text-opacity-100:hover,
.\!focus\:text-opacity-100:focus,
.\!disabled\:text-opacity-100:disabled {
    --tw-text-opacity: 1 !important;
}

.bg-opacity-100,
.hover\:bg-opacity-100:hover,
.focus\:bg-opacity-100:focus,
.disabled\:bg-opacity-100:disabled {
    --tw-bg-opacity: 1;
}

.\!bg-opacity-100,
.\!hover\:bg-opacity-100:hover,
.\!focus\:bg-opacity-100:focus,
.\!disabled\:bg-opacity-100:disabled {
    --tw-bg-opacity: 1 !important;
}

.ring-opacity-100,
.hover\:ring-opacity-100:hover,
.focus\:ring-opacity-100:focus,
.disabled\:ring-opacity-100:disabled {
    --tw-ring-opacity: 1;
}

.\!ring-opacity-100,
.\!hover\:ring-opacity-100:hover,
.\!focus\:ring-opacity-100:focus,
.\!disabled\:ring-opacity-100:disabled {
    --tw-ring-opacity: 1 !important;
}