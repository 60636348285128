.select-custom {
    // .react-select-custom__menu-list {
    //     padding: 10px;
    //     padding-bottom: 0px;
    // }

    .react-select-custom__option {
        width: auto;
        color: #301400;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
        border-bottom: 1px rgba(132, 116, 106, 0.20) solid;
    }

    .react-select-custom__option--is-focused {
        background: rgba(132, 116, 106, 0.1)
    }

    .react-select-custom__multi-value__label {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: #301400;
    }

    .react-select-custom__multi-value__remove {
        color: #84746A;
    }

    .react-select-custom__placeholder {
        color: #84746A
    }

    .react-select-custom__value-container {
        min-height: 40px;
    }

    .holder {
        font-size: 14px;
        line-height: 16px;
        position: absolute;
        color: #84746A;
        left: 5px;
        top: 24px;
        transform: translateY(-50%);
        transition: all 0.25s ease;
        pointer-events: none;
        background: none;
        padding: 0 5px;
        z-index: 1;
    }

    .label {
        background: white;
        border-radius: 6px;
        left: 10px;
        top: 0;
        font-size: 12px;
    }

    .react-select-custom__control {
        background: none !important;
        border-radius: 2px;
    }

    .react-select-custom__menu {
        z-index: 10;
    }

    .prompt-name {
        min-width: 150px;
        max-width: 150px;
    }
}