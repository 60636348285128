@media screen and (max-width: 1500px) {
    .patient-dashboard {
        display: block;

        .left-side,
        .right-side {
            width: 100%;
        }

        .right-side {
            padding-left: 1.25rem;
        }
    }
}