@import '../../../assets/scss/variable';

.org-clinician-detail {
    height: 100%;

    .input-name {
        input {
            height: 48px;
        }
    }

    .back-to-clinician {
        height: 80px;
    }

    .avatar-large {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 130px;
        background: #FF8A4C;
        border-radius: 50%;
        text-transform: uppercase;
        color: white;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
    }

    .clinician-summary {
        background-image: url("../../../assets/image/svg/bg-intro-org.svg");

        .clinician-avatar-container {
            .clinician-avatar-edit {
                display: none;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                opacity: 0.7;
                background-color: black !important;

                &:hover {
                    display: flex !important;
                }
            }

            .clinician-avatar {
                width: 130px;
                height: 130px;

                img {
                    width: 100%;
                    height: 100%;
                }

            }

            .clinician-avatar:hover+.clinician-avatar-edit {
                display: flex !important;

            }
        }
    }

    .badge-active {
        background-color: rgba(0, 103, 131, 1);
        height: 24px;

        span {
            color: white;
            text-transform: uppercase;
            font-weight: 300;
        }
    }

    .badge-inactive {
        background-color: #F05252 !important;
        height: 24px;

        span {
            color: white;
            text-transform: uppercase;
            font-weight: 300;
        }
    }



    .clinician-infor {
        .tab-button {
            .tab-button {
                padding-left: 25px !important;

                button {
                    box-shadow: unset !important;
                    color: $primary_text;
                }

                button[aria-selected="true"] {
                    border-color: $second_text;
                    color: $second_text;
                }
            }

            >div:nth-child(2) {
                height: 100%;

                >div:nth-child(2) {
                    height: 100%;
                }
            }
        }



        .info-left-side {
            width: 60%;
            padding-right: 48px;
        }

        .info-right-side {
            width: 40%;
        }

        .clinician-label {
            min-width: 150px;
            max-width: 150px;
        }

        .avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            background: #FF8A4C;
            border-radius: 30px;
            text-transform: uppercase;
            color: white;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
        }

    }

    .clinician-patients-container {
        td {
            .copy-icon-name {
                display: none;
                padding-left: 0px;
            }

            .copy-icon-email {
                visibility: hidden;
            }

            &:hover {
                .copy-icon {
                    visibility: unset !important;
                }
            }
        }

        .hover-name {
            &:hover {
                .copy-icon-name {
                    display: block;
                    padding-left: 0px;
                }
            }
        }

        .hover-email {
            padding-top: 0px;

            &:hover {
                .copy-icon-email {
                    visibility: visible;
                    padding-left: 0px;
                }
            }
        }

        .clinician-patients {
            .action-table {
                padding-top: 1rem;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .org-clinician-detail {

        .clinician-infor {

            .info-left-side,
            .info-right-side {
                width: 100%;
                padding-right: 0px;
            }
        }

    }
}

@media screen and (max-width: 1024px) {
    .org-clinician-detail {
        .hidden-custom {
            display: none;
        }
    }
}

@media screen and (max-width: 1200px) {
    .org-clinician-detail {
        .clinician-detail {
            .clinician-detail-infor {
                height: 250px;
                flex-direction: column !important;

                .clinician-detail-infor-side {
                    height: 50%;
                    width: 100%;
                }
            }
        }

    }


}

@media screen and (max-width: 640px) {
    .org-clinician-detail {
        .external-id {
            max-width: 100px;
            min-width: 100px;
            padding-right: 5px;
            padding-left: 5px;
        }

        .clinician-patients-container {
            .clinician-patients {
                .action-table {
                    padding-top: 0px;
                }
            }
        }

        .clinician-summary {
            .clinician-avatar-edit {
                .edit-icon {
                    width: 26px;
                    height: 26px;
                }

                width: 90px !important;
                height: 90px !important;
            }

            .name-status {
                display: block !important;

                .badge-active {
                    width: 60px;
                }

                .badge-inactive {
                    width: 72px;
                }

                p {
                    font-size: 1.25rem !important;
                }

            }

            .clinician-id {
                p {
                    font-size: 12px;
                }
            }

            .clinician-avatar {
                width: 90px !important;
                height: 90px !important;
            }
        }

        .clinician-infor {

            .clinician-label {
                min-width: 100px;
                max-width: 100px;
            }
        }
    }
}

@media screen and (max-width:550px) {
    .org-clinician-detail {
        .external-id {
            max-width: 120px;
            padding-right: 5px;
            padding-left: 5px;

            .patient-externalId {
                max-width: 100px;
            }
        }

        .full-name {
            padding-right: 5px;
            padding-left: 5px;
        }

        .patient-externalId {
            width: 100px;

            .patient-id {
                width: 80px;
            }
        }
    }
}

@media screen and (max-width: 467px) {
    .org-clinician-detail {

        .clinician-infor {
            .clinician-detail-infor {
                padding: 1rem;


                .clinician-row {
                    display: block;
                    height: fit-content;
                    margin-bottom: 2px;

                    .clinician-label {
                        width: 100% !important;
                        max-width: 100%;
                        margin-bottom: 1px;
                    }

                    .clinician-value {
                        padding-left: 27px;
                    }
                }
            }
        }

        .clinician-patients-container {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}