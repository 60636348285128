.select-custom {

  &.select-multi .react-select__menu-list {
    padding: 10px;
    padding-bottom: 0px;
  }

  .react-select__option {
    width: auto;
    padding: 10px 10px;
    color: #301400;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
  }

  &.select-multi .react-select__option {
    font-size: 12px;
    float: left;
    padding: 3px 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid rgba(132, 116, 106, 0.20);
  }

  &.custom-single .react-select__single-value {
    font-size: 12px;
    float: left;
    padding: 3px 10px;
    margin-left: 0;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid rgba(132, 116, 106, 0.2);
    width: fit-content;
    background: rgba(132, 116, 106, 0.20) !important;
  }

  .react-select__option--is-focused {
    background: rgba(132, 116, 106, 0.20) !important;
  }

  .react-select__option--is-selected {
    background: #944A01 !important;
    color: white;
  }

  .react-select__multi-value__label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #301400;
  }

  .react-select__multi-value__remove {
    color: #84746A;
  }

  .react-select__placeholder {
    color: #84746A
  }

  .react-select__value-container {
    min-height: 46px;
  }

  .holder {
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    color: #84746A;
    left: 5px;
    top: 24px;
    transform: translateY(-50%);
    transition: all 0.25s ease;
    pointer-events: none;
    background: none;
    padding: 0 5px;
    z-index: 1;
  }

  .label {
    background: white;
    border-radius: 6px;
    left: 10px;
    top: 0;
    font-size: 12px;
  }

  .react-select__control {
    background: none !important;
    border-radius: 2px;
    border: 1px solid rgba(132, 116, 106, 0.20);

    &--is-focused {
      box-shadow: 0 0 0 1px rgb(132, 116, 106, 1);
      border-color: rgb(132, 116, 106, 0.2) !important;
    }

    &.css-13cymwt-control,
    &.css-t3ipsp-control {
      max-height: 200px;
      overflow-y: scroll;
      overflow: overlay;
    }
  }
}