.learning-plan {
    .checkbox-complete {
        &:checked {
            background-color: rgba(148, 74, 1, 1);
        }
    }

    .slp-style {
        cursor: pointer !important;
    }

    .patient-style {
        cursor: default !important;
    }

}