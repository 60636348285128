@import '../assets//scss/variable';

.selected-org-container {
    width: 250px;

    .select-org {
        margin-left: 20px;
        display: flex;
        align-items: center;
        background-color: #301400;
        border-radius: 4px;

        .css-13cymwt-control,
        .css-t3ipsp-control {
            min-height: 40px;
            max-height: 40px;
            border-radius: 4px;
            background-color: #301400;
            border: 0;
            box-shadow: unset !important;

            &:hover,
            &:active,
            &:focus,
            &:focus-within {
                background-color: rgba(255, 255, 255, 0.05);
            }

            .css-1xc3v61-indicatorContainer,
            .css-15lsz6c-indicatorContainer {
                color: white !important;
                font-size: 12px;
                font-weight: 400;
            }
        }

        .css-1u9des2-indicatorSeparator {
            width: 0;
        }

        &.one {
            height: 55px;
            padding-left: 10px;
        }

        .select-label {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            color: white;

            img {
                padding-top: 3px;
            }

            .text-name {
                padding-top: 3px;
                font-weight: 400;
                font-size: 13px;
                max-width: 134px;
            }

            .text-member {
                font-weight: 300;
                font-size: 11px;
                max-width: 134px;
            }
        }

    }

    .css-1nmdiq5-menu {
        width: 300px;
        z-index: 10;

        .select-options {
            padding: 0px 1.25rem;

            >div {
                padding-left: 10px;
                margin-bottom: 5px;

                &:active {
                    background-color: rgba(132, 116, 106, 0.2) !important;
                }
            }
        }

        .select-label {
            .text-name {
                font-weight: 500;
                font-size: 14px;
                color: #111928;
            }

            .text-member {
                font-weight: 400;
                font-size: 12px;
                color: #6B7280;

            }
        }
    }


    .site-admin {
        margin-left: 20px;

        p {
            padding-top: 3px;
        }

        .site-admin-icon {
            border-radius: 3px;
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #EBF5FF;

            svg {
                color: #FFFFFF !important;
            }
        }

        // p {
        //     color: white;
        // }
    }
}


@media screen and (max-width: 640px) {
    .selected-org-container {
        width: 100% !important;
    }
}