.button.is-loading:after,
.control.is-loading:after,
.loader,
.select.is-loading:after {
    border-radius: 290486px;
    border-color: transparent transparent #d8d8d8 #d8d8d8;
    border-style: solid;
    border-width: 2px;
    content: "";
    display: block;
    height: 1em;
    position: relative;
    width: 1em;
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.button.is-loading {
    color: transparent !important;
    pointer-events: none;
}

.button.is-black.is-loading:after {
    border-color: transparent transparent #fff #fff !important;
}

.button.is-loading:after {
    position: absolute !important;
    left: calc(50% - 0.5em);
    top: calc(50% - 0.5em);
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}