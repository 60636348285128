 .organization-patients-container {
     td {
         .copy-icon-name {
             display: none;
             padding-left: 0px;
         }

         .copy-icon-email {
             visibility: hidden;
         }

         &:hover {
             .copy-icon {
                 visibility: unset !important;
             }
         }
     }

     .hover-name {
         &:hover {
             .copy-icon-name {
                 display: block;
                 padding-left: 0px;
             }
         }
     }

     .hover-email {
         padding-top: 0px;

         &:hover {
             .copy-icon-email {
                 visibility: visible;
                 padding-left: 0px;
             }
         }
     }

     .name-badge {
         min-height: 25px;
     }

     .patient-id {
         max-width: 9rem;
     }
 }



 @media screen and (max-width: 1300px) {
     .organization-patients-container {
         .hidden-custom {
             display: none;
         }
     }
 }

 @media screen and (max-width: 800px) {
     .organization-patients-container {
         .hidden-clinician {
             display: none;
         }
     }
 }

 @media screen and (max-width: 640px) {
     .organization-patients-container {
         .patient-externalId {
             width: 6rem;

             .patient-id {
                 width: 5rem;
             }
         }


     }
 }


 @media screen and (max-width:550px) {
     .organization-patients-container {
         .external-id {
             max-width: 100px;
             min-width: 100px;
             padding-right: 5px;
             padding-left: 10px;
         }

         .full-name {
             .name-badge {
                 flex-direction: column;
                 align-items: unset;
             }
         }
     }
 }

 @media screen and (max-width: 450px) {
     .organization-patients-container {
         .external-id {
             max-width: 100px;
             min-width: 100px;
             padding-right: 5px;
             padding-left: 5px;
         }

         .patient-externalId {
             width: 100px;

             .patient-id {
                 width: 80px;
             }
         }

         .full-name {
             max-width: 170px;
             padding-left: 10px;
             padding-right: 5px;

             .name-badge {
                 flex-direction: column;
                 align-items: unset;
             }
         }
     }
 }