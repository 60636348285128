.form-update-profile {
    .user-profile-page {
        padding: 30px;
    }
}

@media screen and (max-width: 800px) {
    .form-update-profile {
        .user-profile-page {
            width: 70% !important;
            padding: 30px;
        }
    }
}

@media screen and (max-width: 470px) {
    .form-update-profile {
        .user-profile-page {
            width: 100% !important;
            padding: 30px;
            margin: 0 20px;
        }
    }
}