.modal-organization-clinician {
    input {
        height: 48px;
    }

    >div {
        height: unset !important;
    }

    .modal-body {
        overflow: unset;
    }
}

@media screen and (max-height: 550px) {
    .modal-organization {
        .modal-body {
            overflow: auto;
            height: 100% !important;
        }

        ::-webkit-scrollbar {
            display: none;
        }
    }
}