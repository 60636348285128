.bullet {
  width: 7px;
  height: 7px;
  border-radius: 20px;
}

.hide-status {
  display: none;
}

.veri-modal {
  overflow: hidden;
  transition: height 0.2s;
  height: 0px;
}

.height-80 {
  height: 80px;
}

.height-16 {
  height: 16px;
}

.custom-multi-select {
  .css-qbdosj-Input {
    margin: 0;

    input {
      //min-width: 200px !important;
    }
  }

  .p-2\.5 {
    padding: 0;
  }

  .react-select__menu {
    z-index: 10;

  }


  .css-13cymwt-control,
  .css-t3ipsp-control {
    min-height: 48px !important;
    border-radius: 2px;
    background-color: #F9FAFB;

    input {
      height: 20px !important;
    }

    border-color: rgba(132, 116, 106, 0.2) !important;

    &:focus,
    &:focus-within {
      border-color: #84746A !important;
      box-shadow: unset !important;
    }

    svg {
      color: #84746A !important;
    }
  }

  [type=text]:focus,
  [type=time]:focus,
  [type=url]:focus,
  [type=week]:focus,
  select:focus,
  textarea:focus {
    --tw-ring-inset: var(--tw-empty,
        /*!*/
        /*!*/
      );
    border-color: transparent;
    box-shadow: none;
    outline: 0 solid transparent;
  }

  .css-3w2yfm-ValueContainer {
    padding: 4px 2px;
  }

  .css-1p3m7a8-multiValue {
    margin: 2px 0 2px 4px;
  }

  .css-1xc3v61-indicatorContainer {
    color: #111928;
  }
}

.post-fix-width {
  max-width: 150px;
}