@import '../../assets/scss/variable';

.action-table {
    .action-add {
        button {
            background-color: $second_button !important;

            &:hover {
                background-color: $primary_button !important;
            }

            box-shadow: unset !important;
        }
    }

    .action-search {
        &:focus {
            box-shadow: unset !important;
            border-color: #63574f;
        }
    }

    .action-export {
        button {
            box-shadow: unset !important;
        }

        &:hover {
            background-color: #63574f !important;
        }
    }
}

@media screen and (max-width: 640px) {
    .action-table {
        display: block;
        padding: 10px 0px;
        margin-right: 0px;

        input {
            box-shadow: unset !important;
        }

        .action-search {
            width: 100%;
            margin-bottom: 10px;
            margin-right: 0px !important;
        }

        .action-button {
            justify-content: flex-start;
        }

        .action-add {
            width: unset !important;

            button {
                background-color: $second_button !important;
            }
        }
    }
}