@import '../assets/scss/variable';

.header {
    background-color: $primary !important;
    width: 100% !important;

    // .logo-container {
    //     width: 256px;
    //     height: 56px;
    //     // background-color: #84746A;
    //     // line-height: 20px;
    //     border-right: 1px solid #84746A;
    //     line-height: 10px;
    //     top: 25%;
    //     bottom: 25%;
    //     // margin-top: 10px;
    //     // margin-bottom: 10px;
    // }

    .logo-container {
        position: relative;
        width: 247px;
        height: 56px;
    }

    .logo-container:after {
        content: '';
        height: 20px;
        width: 1px;

        position: absolute;
        right: 0;
        top: 18px;

        background-color: #84746A;
    }
}

@media screen and (max-width: 640px) {
    .header {
        .user-profile {
            display: none;
        }

        align-items: unset !important;

        >div {
            display: block;
        }

        box-shadow: unset !important;
    }
}