@import '../assets/scss/variable';

.capitalize {
  text-transform: capitalize;
}

.pre {
  white-space: pre-wrap;
}

@media (max-width: 1600px) {
  .custom-w-full {
    width: 60%;
  }
}

@media (min-width: 0) and (max-width: 1300px) {
  .custom-w-full {
    width: 76%;
  }

  .hidden-column {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 1100px) {
  .custom-w-full {
    width: 100%;
  }
}

//media
@media screen and (max-width: 1024px) {
  .hidden-mobile-tablet {
    display: none;
  }

  .assignment-table-style {
    .hidden-column-custom {
      display: none;
    }
  }
}

@media screen and (max-width: 769px) {
  .assignment-table-style {
    .hidden-tablet {
      display: none;
    }
  }
}

@media screen and (max-width: 640px) {
  .hidden-mobile {
    display: none;
  }

  .w-1\/2 {
    width: 100%;
  }
}

/*Toast*/
.Toastify__toast-icon {
  width: 24px !important;
}

.Toastify__close-button--light {
  color: #111827 !important;
  opacity: 1 !important;
  align-self: center !important;
}

.Toastify__toast {
  border-radius: 10px !important;
  padding: 0 15px !important;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.05) !important;
}

.Toastify__toast-container {
  padding: 0 !important;
}

.Toastify__toast-theme--light {
  color: #111827 !important;
  font-size: 14px !important;
}

.Toastify__toast-body {
  padding: 0 !important;
}

//
.break-word {
  word-break: break-word;
}

.border-error {
  input {
    border: 1px solid rgb(220, 38, 38, .5) !important;

    &:focus {
      --tw-border-opacity: 1;
      border: solid 2px rgb(240 82 82/var(--tw-border-opacity)) !important;
      box-shadow: unset !important;
    }
  }

}

.border-error-select {
  .css-13cymwt-control {
    border: 1px solid rgb(220, 38, 38, .5) !important;

    :focus {
      --tw-border-opacity: 1 !important;
      border: solid 2px rgb(240 82 82/var(--tw-border-opacity)) !important;
      box-shadow: unset !important;
    }
  }
}

.react-select {
  width: 100%;
  border-color: rgba(132, 116, 106, 0.2) !important;

  &.error {
    .react-select__control {
      border-color: red !important;
      box-shadow: none !important;

      &:hover {
        border-color: red !important;
      }

      &:focus-within {
        border-width: 2px;
        border-color: red !important;
      }
    }
  }

  &__control {
    border-width: 1px;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: #F9FAFB;
  }

  &__value-container {
    height: inherit;
    padding: 0.625rem;
    border: none;
    color: #2B5873;
    align-content: center;

    &:focus-within .react-select__placeholder {
      display: none;
    }

    &--is-multi {
      display: flex;
      flex-wrap: nowrap;
    }

    .react-select__input-container {
      .react-select__input {
        &:focus {
          box-shadow: none;
        }
      }
    }

  }

  &__single-value {
    color: #2B5873;
  }

  &__indicator-separator {
    display: none;
  }

  &__indicator {
    display: flex;
    color: #2B5873;

    svg {
      cursor: pointer;

      &:hover {
        border-radius: 5px;
        background-color: #e5e7eb
      }
    }
  }

  &__placeholder {
    color: #2B5873;
  }

  &__menu {
    border-width: none;
    border-radius: 0.5rem;
    z-index: 10;

    &-list {
      border-width: none;
      border-radius: 0.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      background-color: #F9FAFB;
      color: #2B5873;
    }
  }

  &__option {
    color: #2B5873;
  }

  &__multi-value__label {
    color: #2B5873;
  }

}

.react-select-custom {
  width: 100%;
  border-color: rgba(132, 116, 106, 0.2) !important;

  &.error {
    .react-select-custom__control {
      border-color: red !important;
      box-shadow: none;

      &:hover {
        border-color: red !important;
      }

      &:focus-within {
        border-width: 2px;
        border-color: red !important;
      }
    }
  }

  &__control {
    border-width: 1px;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: #F9FAFB;
  }

  &__value-container {
    height: inherit;
    padding: 0.625rem;
    border: none;
    color: #2B5873;
    align-content: center;

    &:focus-within .react-select-custom__placeholder {
      display: none;
    }

    &--is-multi {
      display: flex;
      flex-wrap: nowrap;
    }

    .react-select-custom__input-container {
      .react-select-custom__input {
        &:focus {
          box-shadow: none;
        }
      }
    }

  }

  &__single-value {
    color: #2B5873;
  }

  &__indicator-separator {
    display: none;
  }

  &__indicator {
    display: flex;
    color: #2B5873;

    svg {
      cursor: pointer;

      &:hover {
        border-radius: 5px;
        background-color: #e5e7eb
      }
    }
  }

  &__placeholder {
    color: #2B5873;
  }

  &__menu {
    border-width: none;
    border-radius: 0.5rem;

    &-list {
      border-width: none;
      border-radius: 0.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      background-color: #F9FAFB;
      color: #2B5873;
    }
  }

  &__option {
    color: #2B5873;
  }

  &__multi-value__label {
    color: #2B5873;
  }

}

input {
  &:disabled {
    opacity: 50% !important;
  }

  &:focus,
  &:focus-visible {
    border-color: #84746A !important;
    box-shadow: unset !important;
  }

  color: rgb(48, 20, 0) !important;
}

textarea {
  border-radius: 2px !important;
  border-color: rgba(132, 116, 106, 0.2) !important;
  resize: none;
  font-size: 0.875rem;

  &:focus {
    border-color: #84746A !important;
    box-shadow: unset !important;
  }
}


td {
  .copy-icon {
    visibility: hidden;
  }

  &:hover {
    .copy-icon {
      visibility: visible;
    }
  }
}

.status-active {
  background-color: $active;
}

.status-active-text {
  color: $active;
}

.status-inactive {
  background-color: $inactive;
}

.status-inactive-text {
  color: $inactive;
}

.w-12rem {
  width: 12rem;
}

.w-26per {
  width: 26%;
}

.w-16per {
  width: 16%;
}

.w-10per {
  width: 10%;
}

.w-5per {
  width: 5%;
}

.w-32per {
  width: 32%;
}

.w-10per {
  width: 10%;
}

.w-15per {
  width: 15%;
}

.w-22per {
  width: 22%;
}

.w-25per {
  width: 25%;
}

.w-19per {
  width: 19%;
}

.w-9per {
  width: 9%;
}

.w-8rem {
  width: 8rem;
}

.w-46 {
  width: 180px;
  max-width: 180px;
}

.h-46 {
  height: 180px;
  max-height: 180px;
}

.max-36 {
  height: 160px;
}

.max-h-36 {
  max-height: 160px !important;
}


.max-60 {
  max-height: 220px;
}


.action-export {
  background-color: $third_button;
  text-align: center;
}

.text-search {
  color: $text_placholder;

  ::-webkit-input-placeholder {
    color: $text_placholder;
    opacity: 1;
  }
}

.button-save {
  background-color: $second_button;
  color: white;
  border: unset !important;
  border-radius: 2px !important;
  box-shadow: unset !important;

  &:hover {
    background-color: $primary_button !important;
  }
}

.button-cancel {
  border: solid 1px $border_button !important;
  border-radius: 2px !important;
  color: $border_button;
  box-shadow: unset !important;

  &:hover {
    background-color: $white_hover !important;
  }
}

.badge-invitation {
  background-color: rgba(0, 103, 131, 1);
  height: 18px;

  padding: 5px;

  span {
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
  }
}

.action-add {
  button {
    background-color: $second_button !important;

    &:hover {
      background-color: $primary_button !important;
    }

    box-shadow: unset !important;
  }
}

.cancel-button {
  svg {
    color: rgba(132, 116, 106, 1)
  }

  &:hover {
    background-color: unset !important;
  }
}

.date-style {
  >div>div {
    >div {
      right: 10px !important;
      left: unset !important;

      svg {
        color: rgb(132, 116, 106);
      }
    }

    input {
      padding-left: 0.625rem;
      color: rgb(48, 20, 0);
      outline: none
    }
  }

  &.error {
    >div>div {
      input {
        background-color: rgb(253 242 242 / var(--tw-bg-opacity));
        border-color: red !important;
        box-shadow: none;

        &:hover {
          border-color: red !important;
        }

        &:focus-within {
          border-width: 2px;
          border-color: red !important;
        }
      }
    }
  }
}

.invitation-sent {
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 5px 4px;
  line-height: 10px;
  color: white;
  display: flex;
  align-items: center;
  width: 97px;
}

.icon-sort {
  visibility: hidden;
}

th {
  &:hover {
    .icon-sort {
      visibility: visible;
    }
  }
}