.SortableItem {
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
    padding: 18px 20px;
    background-color: #fff;
    border-radius: calc(4px / var(--scale-x, 1));
    box-sizing: border-box;
    list-style: none;
    color: #333;
    font-weight: 400;
    font-size: 1rem;
    font-family: sans-serif;
}

.DragHandle {
    padding: 15px;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    touch-action: none;
    cursor: var(--cursor, pointer);
    border-radius: 5px;
    outline: none;
    appearance: none;
    // background-color: transparent;
    -webkit-tap-highlight-color: transparent;
}

.DragHandle:focus-visible {
    box-shadow: 0 0px 0px 2px #4c9ffe;
}

.DragHandle svg {
    flex: 0 0 auto;
    margin: auto;
    height: 100%;
    overflow: visible;
}