.textarea-text {
  input {
    height: 48px;
  }

  .holder {
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    color: #84746A;
    left: 5px;
    top: 24px;
    transform: translateY(-50%);
    transition: all 0.25s ease;
    pointer-events: none;
    background: none;
    padding: 0 5px;
  }

  .label {
    background: white;
    border-radius: 6px;
    left: 10px;
    top: 0;
    font-size: 12px;
  }
}