.org-detail {
    .org-top {
        height: 200px;
    }
    .action-table {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: -75px;
        z-index: 10;
    }
    .avatar:hover .icon {
    opacity: 1;
    }
}