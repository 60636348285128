.more-action {
    cursor: pointer;

    .icon[aria-expanded="true"] {
        background-color: rgba(215, 195, 183, 1) !important;
    }

    svg {
        color: rgba(48, 20, 0, 1);
    }
}

.icon:hover {
    background-color: rgba(132, 116, 106, 0.10)
}